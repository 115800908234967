import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";

const ProductImageUpload = ({ onFileUploaded, userId, product_id }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const apiUrl = process.env.REACT_APP_BACKEND_URL;

  const getPresignedUrl = async (fileName) => {
    try {
      const response = await fetch(
        `${apiUrl}/get_presigned_url?file_name=${encodeURIComponent(fileName)}`
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error getting presigned URL:', error);
      return null;
    }
  };

  const uploadFile = async (file) => {
    return new Promise(async (resolve, reject) => {
      const fileName = file.name;
      const fileType = file.type;
      const fileInfo = await getPresignedUrl(fileName);

      if (!fileInfo || !fileInfo.presigned_url) {
        alert('Could not get the presigned URL');
        reject('Could not get the presigned URL');
        return;
      }

      try {
        const uploadResponse = await fetch(fileInfo.presigned_url, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': fileType,
          },
        });

        if (uploadResponse.ok) {
          console.log('File uploaded, Access URL:', fileInfo.access_url);
          
          const image = new Image();
          image.src = URL.createObjectURL(file);
          image.onload = async () => {
            const width = image.width;
            const height = image.height;
            URL.revokeObjectURL(image.src);

            const additionalInfo = {
              image_url: fileInfo.access_url,
              width: width,
              height: height,
              user_id: userId,
              product_id: product_id,
            };

            const infoResponse = await fetch(`${apiUrl}/add_product_photos`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(additionalInfo),
            });

            if (infoResponse.ok) {
              console.log('Additional info was successfully sent.');
              resolve(fileInfo.access_url);
            } else {
              console.error(
                'Failed to send additional info. Status:',
                infoResponse.status,
                'Status Text:',
                infoResponse.statusText
              );
              reject('Failed to send additional info');
            }
          };
        } else {
          console.error(
            'Failed to upload file. Status:',
            uploadResponse.status,
            'Status Text:',
            uploadResponse.statusText
          );
          alert('Failed to upload file.');
          reject('Failed to upload file');
        }
      } catch (error) {
        console.error('Upload failed:', error);
        reject(error);
      }
    });
  };

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
    const uploadPromises = files.map(file => uploadFile(file));

    Promise.all(uploadPromises)
      .then(urls => {
        console.log('All files uploaded:', urls);
        onFileUploaded(urls); // Activates once all files have been uploaded
      })
      .catch(error => {
        console.error('An upload failed:', error);
      });
  };

  return (
    <div>
      <input
        type="file"
        multiple
        accept=".png, .jpg, .jpeg"
        onChange={handleFileSelect}
        style={{ display: 'none' }}  // Hide the file input element
        id="fileInput"  // Provide an ID for the label to associate with
      />
      <label htmlFor="fileInput" style={{
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        padding: '10px',
        borderRadius: '5px',
        color: 'white',    
        cursor: 'pointer',
        borderRadius: '50%',
      }}>
        <FontAwesomeIcon icon={faCamera} color="white" style={{fontSize: '32px', width: '30px'}} />
      </label>
    </div>
  );
};

export default ProductImageUpload;