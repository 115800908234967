import React, { useState, useEffect } from 'react';
import styles from './ExternalProfile.module.css'; // Import styles from a CSS module
import { useNavigate, useParams } from 'react-router-dom'; // Routing for web
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Font Awesome for React
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'; // Import necessary icons

function ExternalProfile() { // Adjusted to take profileId as a prop
    const { profile_id } = useParams();
  const apiUrl = process.env.REACT_APP_BACKEND_URL; // Config for API URL
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Use navigate from react-router-dom
  const [activeTab, setActiveTab] = useState('Profile');

  const handleBackClick = () => {
    navigate(-1); // This takes the user back to the previous page
  };

  useEffect(() => {
    async function fetchProfile() {
      if (!profile_id) {
        return;
      }
      setLoading(true);
      try {
        const response = await fetch(`${apiUrl}/get_external_profile?external_id=${profile_id}`);
        const data = await response.json();
        setUserInfo(data.external_info[0]);
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
      setLoading(false);
    }
    fetchProfile();
  }, [profile_id]); // Dependency on profileId

  const renderTabContent = () => {
    switch (activeTab) {
      case 'Profile':
        return (
            <div className={styles.cardHeader}>
                <div className={styles.infoHeaderLeft}>
                  <img src={userInfo?.image_url} alt="User" className={styles.userImage} />
                  <p className={styles.text}>
                    {userInfo?.user_type} External Account
                  </p>
                </div>
              <div className={styles.bio}>
                <p className={styles.bioText}>
                  {userInfo?.description}.<br /><br />
                  This is an unofficial profile and not owned or maintained by {userInfo?.name}.<br /><br />
                  Check them out at <a href={userInfo?.link} className={styles.link}>{userInfo?.link}</a>
                </p>
              </div>
            </div>
        );

      default:
        return null;
    }
  };

  if (loading) {
    return <div>Loading...</div>; // Use a simple loading text or a spinner component
  }

  if (!userInfo) {
    return <div>User information is currently unavailable.</div>;
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.page_title}>
        {userInfo.name}
      </h1>

      <div className={styles.backIconContainer} onClick={handleBackClick}>
        <FontAwesomeIcon icon={faArrowLeft}  className={styles.backArrow} />
      </div>
      
      <div>{renderTabContent()}</div>
    </div>
  );
}

export default ExternalProfile;