import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "./DirectMessage.module.css"; // Adjust path as necessary
import { formatDistanceToNow, format } from "date-fns";
import { UserContext } from "../components/UserContext";
import {
  faEye,
  faEyeSlash,
  faArrowLeft,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DirectMessage = () => {
  const { user2Id } = useParams();
  const { userId } = useContext(UserContext);
  const apiUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [username, setUsername] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [image, setImage] = useState(null);
  const messagesEndRef = useRef(null);
  const [firstMessage, setFirstMessage] = useState(false);

  const formatDate = (date) => {
    return format(date, "do MMMM HH:mm");
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [firstMessage,messages]);

  const handleWebLink = (url) => {
    // Base URL check for 'rated.fun'
    if (url.includes("rated.fun")) {
      const urlParts = url.split("/");

      // Check if the URL is for profiles and possibly ratings
      if (url.includes("rated.fun/profiles")) {
        const profileIndex = urlParts.findIndex((part) => part === "profiles");
        const profileId = urlParts[profileIndex + 1];

        // Further check if it includes rating
        if (url.includes("/rating/")) {
          const ratingIndex = urlParts.findIndex((part) => part === "rating");
          const ratingId = urlParts[ratingIndex + 1];
          navigate("/profiles/" + profileId + "/rating/" + ratingId);
        } else {
          // No rating, navigate with only profileId
          navigate("/profiles/" + profileId);
        }
      }
      // Check if the URL is for products
      else if (url.includes("rated.fun/products")) {
        const productIndex = urlParts.findIndex((part) => part === "products");
        const productId = urlParts[productIndex + 1];
        navigate("/products/" + productId);
      }
    }
  };

  // Function to find URLs in a string
  const extractURLs = (text) => {
    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    const urls = text.match(urlRegex);
    return urls ? urls[0] : null; // Return the first URL found or null if no URL
  };

  // Define a function to handle press events
  const handlePress = (item) => {
    console.log("Press:", item.message);
    const url = extractURLs(item.message);
    if (url && url.includes("rated.fun")) {
      handleWebLink(url);
    }
  };

  useEffect(() => {
    const fetchMessages = async () => {
      if (!userId || !user2Id) return;
      try {
        const response = await fetch(
          `${apiUrl}/get_convo?user_id=${userId}&user2_id=${user2Id}`
        );
        const data = await response.json();
        const sortedMessages = data.messages.sort(
          (b, a) => new Date(a.timestamp) - new Date(b.timestamp)
        );
        setMessages(sortedMessages);
        setUsername(data.other_user.username);
        setProfilePic(data.other_user.photo_url);
        if (data.messages.length > 0 && !firstMessage) {
          setFirstMessage(true);
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    const intervalId = setInterval(fetchMessages, 10000);
    fetchMessages();

    return () => {
      clearInterval(intervalId);
    };
  }, [userId, user2Id]);

  const sendMessage = async () => {
    if (newMessage.trim()) {
      const payload = {
        user_id: userId,
        user2_id: user2Id,
        message: newMessage,
      };

      await fetch(`${apiUrl}/send_message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      setNewMessage("");
      setMessages((prevMessages) => [
        
        {
          message_id: prevMessages.length + 1,
          message: newMessage,
          message_status: "Sent",
          timestamp: new Date(),
          read: false,
        },
        ...prevMessages,
      ]);
    }
  };

  const goBack = () => {
    navigate(-1); // Navigate back
  };

  const shouldShowTimestamp = (currentIndex) => {
    if (currentIndex === messages.length - 1) return true;
    const currentMsg = messages[currentIndex];
    const nextMsg = messages[currentIndex + 1];
    return (
      new Date(nextMsg.timestamp) - new Date(currentMsg.timestamp) < -600000
    ); // 10 mins in milliseconds
  };

  const renderMessage = ({ item, index }) => {
    return (
      <div className={styles.messageContainer}>
        {shouldShowTimestamp(index) && (
          <div className={styles.timestamp}>{formatDate(item.timestamp)}</div>
        )}
        <div
          className={
            item.message_status === "Sent"
              ? styles.currentUserMessage
              : styles.otherUserMessage
          }
        >
          {!item.image_url ? (
            <div className={styles.messageText}>{item.message}</div>
          ) : (
            <div className={styles.imageContainer}>
              <div className={styles.messageText}>{item.message}</div>
              <img
                onClick={() => handlePress(item)}
                src={item.image_url}
                className={styles.messageImage}
                alt="message"
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.backIconContainer} onClick={goBack}>
        <FontAwesomeIcon icon={faArrowLeft} className={styles.backArrow} />
      </div>
      <header className={styles.header}>
        <Link to={`/profiles/${user2Id}`} >
          <div className={styles.headerInfo}>
            {profilePic && (
              <img
                src={profilePic}
                alt={username}
                className={styles.profilePic}
              />
            )}
            <span className={styles.username}>@{username}</span>
          </div>
        </Link>
      </header>
      <div className={styles.messagesList}>
        {messages
          .slice()
          .reverse()
          .map((item, index) => (
            <div key={item.message_id}>{renderMessage({ item, index })}</div>
          ))}
        <div ref={messagesEndRef} />
      </div>
      <div className={styles.inputContainer}>
        <input
          type="text"
          className={styles.messageInput}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type a message..."
        />
        <FontAwesomeIcon
          icon={faPaperPlane}
          className={styles.sendIcon}
          onClick={sendMessage}
        />
      </div>
    </div>
  );
};

export default DirectMessage;
