import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import styles from './Tutorial.module.css'; // Adjust the path if needed
import { UserContext } from "../components/UserContext";

const Tutorial = ({ onClose, screen = 'Home' }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();
  const { userId, userType } = useContext(UserContext);
  let pages = [];
  switch (screen) {
    case 'Home':
      pages = [
        'Start by rating 30 products to calibrate your taste. \n\n Then we can show you who is the most similar to you!',
        'As you rate you will see a predicted rating for each product. \n\n This will get more accurate as you rate more products.',
        'You can adjust the categories shown to you by tapping the top right icon.',
        'Later, once you follow users, products they rate and their ratings will be shown here.',
      ];
      if (userType === 'Temp') {
        pages.unshift('A temporary account has been created so you can jump straight into rating. Later you can add your details if you wish.');
      }
      break;
    case 'Users':
      pages = [
        'Similarity scores are calculated by comparing your ratings to all other users. \n\n Users need at least 10 ratings in common to get a similarity score.',
        'The more you rate your more accurate your similarity scores and predicted ratings will become.',
        'You can use the search to find your friends and follow them.\n\n If they are not on the app yet, invite them to join by tapping the top right icon.',
      ];
      break;
    case 'Profile':
      pages = [
        'Congratulations on giving your first 30 ratings. Welcome to your profile! \n\n Here you can see your ratings, network, stats and more.',
        'You can come back here later and update your profile picture, username and other details',
        'Now lets see who has the most similar taste to you!',
      ];
      break;
    default:
      pages = ['This is a default tutorial page.'];
      break;
  }

  const nextPage = () => {
    if (currentPage < pages.length - 1) {
      setCurrentPage(currentPage + 1);
    } else {
      if (screen === 'Profile') {
        navigate('/ExploreUsers', { state: { tutorial: true } }); // Navigate to the Users screen
      }
      onClose();
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className={styles.overlay} onClick={nextPage}>
      <div className={styles.tutorialContent}>
        {/* Uncomment and adjust if you want to add a close button */}
        {/* <div className={styles.closeButton} onClick={onClose}>
          <FontAwesomeIcon icon={faXmarkCircle} size="24" color="#fff" />
        </div> */}
        <p className={styles.tutorialText}>{pages[currentPage]}</p>
        {pages.length > 1 && (
          <div className={styles.pageIndicatorContainer}>
            {pages.map((_, index) => (
              <div
                key={index}
                className={`${styles.pageIndicator} ${currentPage === index ? styles.activePageIndicator : ''}`}
              />
            ))}
          </div>
        )}
        <div className={styles.buttonContainer}>
          {/* Uncomment and adjust if you want to add a previous button */}
          {/* {currentPage > 0 && (
            <button className={styles.navButton} onClick={prevPage}>
              Previous
            </button>
          )} */}
          <button className={styles.navButton} onClick={nextPage}>
            {currentPage < pages.length - 1 ? 'Next' : 'Proceed'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Tutorial;