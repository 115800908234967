import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import styles from "./Dropdown.module.css";

const DropdownTags = ({
  categories,
  selectedCategories,
  onCategoryChange,
  name,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [tempSelectedCategories, setTempSelectedCategories] =
      useState(selectedCategories);
  
    useEffect(() => {
      setTempSelectedCategories(selectedCategories);
    }, [selectedCategories]);
  
    const toggleDropdown = () => setIsOpen(!isOpen);
  
    const handleCheckbox = (category, isChecked) => {
      let newTempSelectedCategories;
      if (isChecked) {
        newTempSelectedCategories = [...tempSelectedCategories, category];
      } else {
        newTempSelectedCategories = tempSelectedCategories.filter(
          id => id !== category,
        );
      }
      setTempSelectedCategories(newTempSelectedCategories);
    };
  
    const handleSelectAll = () => {
      if (tempSelectedCategories.length === categories.length) {
        setTempSelectedCategories([]);
      } else {
        setTempSelectedCategories([...categories]);
      }
    };
  
    const handleCloseDropdown = () => {
      onCategoryChange(tempSelectedCategories);
      setIsOpen(false);
    };
  
    const [displayedText, setDisplayedText] = useState('');
  
  
  
    useEffect(() => {
      let fullText =
        tempSelectedCategories.length > 0
          ? tempSelectedCategories.join(', ')
          : 'All User Tags';
      setDisplayedText(fullText);
    }, [tempSelectedCategories]);
  
    const maxWidth = 140; // Set your desired max width here
    
    const onTextLayout = e => {
      const {width} = e.nativeEvent.layout;
      if (width > maxWidth) {
        let truncatedText =
          tempSelectedCategories.join(', ').slice(0, 20) + '...'; // Adjust slicing as needed
        setDisplayedText(truncatedText);
      }
    };
  
    const submitButtonTop = Math.min( (categories.length * 65), 620);

  return (
    <div className={styles.container}>
      <button onClick={toggleDropdown} className={styles.dropdownButton}>
        {displayedText!= 'All User Tags' ? (
          <span className={styles.buttonText}>
            {displayedText}
          </span>
        ) : (
          <span className={styles.buttonText2}>
        {displayedText}
          </span>
        )}
        <FontAwesomeIcon
          icon={faCaretDown}
          color="white"
          className={styles.arrow}
        />
      </button>

      {isOpen && (
        <div>
          <div className={styles.dropdown}>
            <button onClick={handleSelectAll} className={styles.allButton2}>
              <span className={styles.text}>Select All</span>
            </button>
            {categories.map((category) => (
              <button
                key={category}
                onClick={() =>
                  handleCheckbox(
                    category,
                    !tempSelectedCategories.includes(category)
                  )
                }
                className={styles.dropdownItem}
              >
                <span className={styles.itemText}>{category}</span>
                <input
                  type="checkbox"
                  checked={tempSelectedCategories.includes(
                    category
                  )}
                  onChange={(e) =>
                    handleCheckbox(category, e.target.checked)
                  }
                  className={styles.checkbox}
                />
              </button>
            ))}
          </div>
          <button onClick={handleCloseDropdown} className={styles.submitButton} style={{ top: submitButtonTop }}>

            <span className={styles.text}>Submit</span>
          </button>

          <div className={styles.hidden}>
            <button onClick={handleCloseDropdown} className={styles.hidden} />
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownTags;
