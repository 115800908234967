import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./SubmitProduct.css";
import { UserContext } from "../components/UserContext";
import styles from "./SubmitProduct.module.css";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageUpload from "../components/ImageUpload";
import Autocomplete from "react-google-autocomplete";

function SubmitProduct() {
  const [title, setTitle] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [currentKeyword, setCurrentKeyword] = useState("");
  const [empty, setEmpty] = useState("true");
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("None");
  const [name, setName] = useState("");
  const [category_id, setCategory_id] = useState("");
  const [image_url, setImage_url] = useState("");
  const [timestamp, setTimestamp] = useState(new Date().toISOString());
  const [secondary_info, setSecondary_info] = useState("");
  const [description, setDescription] = useState("");
  const [other_id, setOther_id] = useState(""); // Assuming you will collect this in your form somewhere.
  const { userId } = useContext(UserContext);
  const [inputUrl, setInputUrl] = useState("");
  const navigate = useNavigate();
  const [year, setYear] = useState("");
  const [location, setLocation] = useState("");
  const [minYear, setMinYear] = useState(null);
  const [useLocation, setUseLocation] = useState(false);
  const [inputPhoto, setInputPhoto] = useState("");
  const [manualPhotoUrl, setManualPhotoUrl] = useState("");
  const [city, setCity] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [creator, setCreator] = useState("");
  const [childCategories, setChildCategories] = useState([]);
  const [childCategory, setChildCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("Quick");
  const [locationDetails, setLocationDetails] = useState(null);
  const [locationData, setLocationData] = useState(null);
  const [hotelUrl, setHotelUrl] = useState("");
  const [parentCategory, setParentCategory] = useState(null);
  const [parentSecondaryInfo, setParentSecondaryInfo] = useState(null);
  const [parentId, setParentId] = useState(null);
  const [parentName, setParentName] = useState(null);

  const data = [
    {
      id: "movies_tv",
      title: "TMDB (Movies, TV, Anime, Documentaries)",
      content:
        "TV Shows, Movies, Anime, Documentaries are gathered from tmdb. Just put a tmdb URL and it will be automatically allocated to the appropriate category. For tv shows, it will take longer as all episodes and seaosons will also be added.",
      imageUrl:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/Tmdb.new.logo.svg/1280px-Tmdb.new.logo.svg.png",
      link: "https://www.themoviedb.org/",
    },
    {
      id: "restaurants_hotels",
      title: "Tripadvisor (Places)",
      content:
        "All places are gathered from tripadvisor. Just put a tripadvisor URL and it will be automatically allocated to the appropriate category. Current categories supported are; Restaurants, Hotels, Museums, Spas/Massage, Escape Rooms. Anything else will be allocated to Other.",
      imageUrl:
        "https://1000logos.net/wp-content/uploads/2019/08/TripAdvisor-logo-1.jpg",
      link: "https://www.tripadvisor.co.uk/",
    },
    {
      id: "music",
      title: "Spotify (Music, Podcasts)",
      content:
        "Music and Podcasts are gathered from Spotify. Just put a Spotify URL and it will be automatically allocated to the appropriate category. For music you will need to add an individual track, not an album or artist.",
      imageUrl:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Spotify_icon.svg/991px-Spotify_icon.svg.png",
      link: "https://open.spotify.com/",
    },
    {
      id: "boardgames",
      title: "Board Game Geek (Board Games)",
      content:
        "Board Games are gathered from Board Game Geek. Tags for creators, genres and mechanics are gathered.",
      imageUrl:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bb/BoardGameGeek_Logo.svg/1200px-BoardGameGeek_Logo.svg.png",
      link: "https://boardgamegeek.com/",
    },
    {
      id: "videogames",
      title: "RAWG (Video Games)",
      content:
        "Video Games are gathered from RAWG. Tags for consoles and genres are gathered.",
      imageUrl:
        "https://assets.entrepreneur.com/content/3x2/2000/20180703161930-ScreenShot2018-07-03at121825PM.png?format=pjeg&auto=webp&crop=16:9",
      link: "https://rawg.io/",
    },
    {
      id: "youtube",
      title: "Youtube (Youtube Channels)",
      content:
        "Youtube channel information is gathered from Youtube. Just put a Youtube URL of a channel, but please add tags to the channel once its added",
      imageUrl:
        "https://variety.com/wp-content/uploads/2014/07/youtube-logo-full_color.jpg",
      link: "https://www.youtube.com/",
    },

    // Add more items as needed
  ];

  const apiUrl = process.env.REACT_APP_BACKEND_URL;
  const google_api_key = process.env.google_key;
  const [expanded, setExpanded] = useState({});

  // Function to toggle the expanded state of each section
  const toggleExpand = (id) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [id]: !prevExpanded[id],
    }));
  };

  const addLocationProduct = async (data) => {
    const payload = {
      place_id: data.place_id,
      timestamp: new Date().toISOString(),
      user_id: userId,

    };

    console.log("Payload:", payload);

    try {
      const response = await fetch(`${apiUrl}/add_location_product`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const responseData = await response.json();

      if (responseData.status === "success") {
        window.alert("New Product Successfully Added!");
        navigate(`/products/${responseData.product_id}`);
      } else {
        window.alert(responseData.message);
      }
    } catch (error) {
      window.alert(error)
      console.error("Error adding location product:", error);
    }
  };

  const LocationSearchScreen = () => {

    return (
      <div style={{ padding: "20px" }}>
        <Autocomplete
          apiKey={process.env.REACT_APP_GOOGLE_KEY}
          style={{ width: "90%" }}
          onPlaceSelected={(place) => {
            console.log(place);
            addLocationProduct(place);
          }}
          fields={[
            "geometry.location",
            "name",
            "formatted_address",
            "photos",
            "types",
            "rating",
            "user_ratings_total",
            "url",
          ]}
          options={{
            types: ["establishment"], // This includes restaurants, museums, etc.
          }}
        />
      </div>
    );
  };

  const addUrl = async () => {
    if (loading || !inputUrl) {
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/product_url_add?url=${inputUrl}`);
      const responseData = await response.json();
      if (responseData.product_id > 0) {
        // Assuming 'navigation' and 'Alert' are defined and available in your context
        window.alert("Success", "Product added successfully");
        navigate(`/products/${responseData.product_id}`);
      } else {
        window.alert("Error", "No product could be added with the given URL");
        setInputUrl("");
      }
    } catch (error) {
      console.error("Error adding URL:", error);
      window.alert("Error", "An unexpected error occurred");
    }
    setLoading(false);
  };

  useEffect(() => {
    async function fetchTags() {
      try {
        const response = await fetch(
          `${apiUrl}/get_all_categories?empty=${empty}`
        );
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    }

    fetchTags();
  }, []); // Empty dependency array, only run once on mount.

  useEffect(() => {
    async function fetchChildCategories() {
      // Use the destructured parentId and parentCategory directly
      if (parentCategory === null) {
        setChildCategories([]);
        return;
      }
      try {
        const response = await fetch(
          `${apiUrl}/get_child_categories?category_id=${parentCategory}`
        );
        const data = await response.json();
        setActiveTab("Manual");
        setChildCategories(data);
        setCategory_id(parentCategory);
        setSecondary_info(parentSecondaryInfo);
        console.log(data);
      } catch (error) {
        console.error("Error fetching child categories:", error);
      }
    }

    fetchChildCategories();
  }, []); // Dependency array should include actual dependencies used inside the effect.

  const addKeywords = () => {
    const newKeywords = currentKeyword
      .split(",")
      .map((kw) => kw.replace(/[^a-zA-Z0-9]/g, "").toLowerCase()) // Updated line
      .filter((kw) => kw);
    setKeywords([...keywords, ...newKeywords]);
    setCurrentKeyword("");
  };

  const removeKeyword = (index) => {
    const newKeywords = [...keywords];
    newKeywords.splice(index, 1);
    setKeywords(newKeywords);
  };

  const addManualUrl = () => {
    setInputPhoto(manualPhotoUrl);
    setImage_url(manualPhotoUrl);
  };

  useEffect(() => {
    setSecondary_info(year);
  }, [year]);

  useEffect(() => {
    setSecondary_info(city);
  }, [city]);

  const handleSubmit = async () => {
    // Define an array of required fields and their values
    const requiredFields = [
      { name: "name", value: name },
      { name: "category_id", value: category_id },
      { name: "image_url (must press add if manual)", value: image_url },
    ];

    if (useLocation) {
      requiredFields.push({ name: "Location", value: location });
    }

    // Filter out the fields that are missing (i.e., their value is falsy)
    const missingFields = requiredFields
      .filter((field) => !field.value)
      .map((field) => field.name);

    if (missingFields.length > 0) {
      // Log missing fields to the console
      console.log("Missing fields:", missingFields.join(", "));

      // Alert the user about the missing fields, listing them
      window.alert(
        `Please fill out all the fields! Missing: ${missingFields.join(", ")}`
      );
      return;
    }

    const payload = {
      name,
      category_id,
      image_url,
      timestamp,
      secondary_info,
      description,
      other_id,
      user_id: userId,
      keywords,
      latitude,
      longitude,
      creator,
      parentId,
      childCategory,
      parentName,
      year,
      city,
    };

    try {
      const response = await fetch(`${apiUrl}/add_product`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (data.status === "success") {
        window.alert("New Product Successfully Added!");
        // Navigate to the newly created product's page
        navigate(`/products/${data.product_id}`);
      } else {
        window.alert(data.message); // Assuming the backend sends a message with the error.
      }
    } catch (error) {
      console.error("Error adding the product:", error);
    }
  };

  const handleCategoryChange = useCallback(
    (itemValue) => {
      setCategory(itemValue);
      setCategory_id(itemValue);
      const category = categories.find(
        (category) => category.category_id == itemValue
      );
      if (category) {
        // setCategory_id(category.category_id);
        setUseLocation(category.location);
        setMinYear(category.min_year);
        console.log("minYear", category.min_year);
      }
    },
    [categories]
  );

  const handleChildCategoryChange = (category) => {
    setCategory(category);
    setChildCategory(category);
    setSecondary_info(category);
  };

  const onFileUploaded = (url) => {
    setInputPhoto(url);
    setImage_url(url);
  };

  const handleBackClick = () => {
    navigate(-1); // This takes the user back to the previous page
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.page_title}>Add New Product</h1>

      <div className={styles.backIconContainer} onClick={handleBackClick}>
        <FontAwesomeIcon icon={faArrowLeft} className={styles.backArrow} />
      </div>

      <div className={styles.tabs}>
        <button
          className={`${styles.tabButton} ${
            activeTab === "Quick" ? styles.active : ""
          }`}
          onClick={() => setActiveTab("Quick")}
        >
          Quick
        </button>

        <button
          className={`${styles.tabButton} ${
            activeTab === "Manual" ? styles.active : ""
          }`}
          onClick={() => setActiveTab("Manual")}
        >
          Manual
        </button>
      </div>

      {loading && <div className={styles.purpleSpinner}></div>}

      {activeTab === "Manual" && (
        <div>
          {/* <p className={styles.verified}>Only verified users can manually add products. To get verified please reach out via email, discord or social media.</p> */}
          <div className={styles.submitProductFields}>
            <label
              title="Product name should be unique and descriptive."
              className={styles.fieldLabel}
            >
              Name:
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Eg. The Graduate"
                className={styles.textInput}
              />
            </label>

            <label
              title="Select a relevant category for the product."
              className={styles.fieldLabel}
            >
              Category: (if not listed, please contact us to propose an
              additional category)
              <select
                value={category}
                onChange={(e) => {
                  handleCategoryChange(e.target.value);
                }}
                className={styles.selectInput}
              >
                <option value="">Select a Category</option>
                {categories.map((category) => (
                  <option
                    key={category.category_id}
                    value={category.category_id}
                  >
                    {category.name}
                  </option>
                ))}
              </select>
            </label>

            {minYear > 0 && (
              <label
                title="Link to an external website for more information."
                className={styles.fieldLabel}
              >
                Year:
                <input
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  placeholder="Year the product was released or created"
                  className={styles.textInput}
                />
              </label>
            )}

            {useLocation && (
              <label
                title="Link to an external website for more information."
                className={styles.fieldLabel}
              >
                City:
                <input
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  placeholder="City the product was released or created"
                  className={styles.textInput}
                />
              </label>
            )}

            <label
              title="Any additional information related to the product."
              className={styles.fieldLabel}
            >
              Secondary Info:
              <input
                value={secondary_info}
                onChange={(e) => setSecondary_info(e.target.value)}
                placeholder="Most useful piece of identifying information, Year for Media, City for a Location"
                className={styles.textInput}
              />
            </label>

            <label
              title="Link to the product image."
              className={styles.fieldLabel}
            >
              Image Url:
              <input
                value={image_url}
                onChange={(e) => setImage_url(e.target.value)}
                placeholder="Provide a URL to direct to an image of the product or upload below"
                className={styles.textInput}
              />
            </label>

            {image_url && image_url.length > 0 && (
              <img
                src={image_url}
                alt="Product"
                className={styles.imagePreview}
              />
            )}

            <div className={styles.image_upload}>
              <ImageUpload
                onFileUploaded={onFileUploaded}
                linkName1={"Upload Photo"}
                linkName2={"Edit Photo"}
              />
            </div>

            <label
              title="Link to an external website for more information."
              className={styles.fieldLabel}
            >
              External Url:
              <input
                value={other_id}
                onChange={(e) => setOther_id(e.target.value)}
                placeholder="Provide a URL to direct to another website for more information about the product"
                className={styles.textInput}
              />
            </label>

            <label
              title="Brief description about the product."
              className={styles.fieldLabel}
            >
              Description:
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className={styles.textareaInput}
                placeholder="Write a brief description about the product (Optional)"
              />
            </label>

            <label
              title="Add relevant keywords for better searchability."
              className={styles.fieldLabel}
            >
              Tags:
              <input
                value={currentKeyword}
                onChange={(e) => setCurrentKeyword(e.target.value)}
                className={styles.textInput}
                placeholder="Add as comma separated words, eg 'thriller', 'french','samsung'"
              />
              <button
                type="button"
                onClick={addKeywords}
                className={styles.addButton}
              >
                Add
              </button>
            </label>

            <div className={styles.keywordsContainer}>
              {keywords.map((keyword, index) => (
                <div key={index} className={styles.keywordBubble}>
                  #{keyword}
                  <span
                    className={styles.removeKeyword}
                    onClick={() => removeKeyword(index)}
                  >
                    X
                  </span>
                </div>
              ))}
            </div>
          </div>

          <div className={styles.submitProductButtons}>
            <button onClick={handleSubmit} className={styles.submitButton}>
              Submit Product
            </button>
          </div>
        </div>
      )}

      {activeTab === "Quick" && (
        <div className={styles.quickAdd}>
          <p className={styles.quickAddtext2}>
            Please ensure the product does not already exist on Rated before
            adding.
          </p>
          <input
            className={styles.input}
            onChange={(e) => setInputUrl(e.target.value)}
            value={inputUrl}
            placeholder="Insert a URL from an Approved Site"
          />
          <button className={styles.submitButton} onClick={addUrl}>
            Submit URL
          </button>

          <p className={styles.quickAddtext}>
            Or Google search below for a place to add a location product.
          </p>
          <div className={styles.googleContainer}>
            <LocationSearchScreen />
          </div>

          <div className={styles.thanksText}>
            Thanks very much to the following sites for their APIs. For more
            detailed information about each product, the title will hyperlink to
            the product page on the respective site.
            <br />
            <br />
            By adding in a URL from any of the sites the corresponding product
            will be automatically added. In most cases, tags will be
            automatically generated, but please adjust them as needed.
            <div style={{ overflowY: "auto", marginTop: "20px" }}>
              {data.map((item) => (
                <div key={item.id} className={styles.box}>
                  <button
                    onClick={() => toggleExpand(item.id)}
                    className={styles.boxTitle}
                  >
                    {item.title}
                  </button>
                  {expanded[item.id] && (
                    <div className={styles.contentContainer}>
                      <p className={styles.contentText}>{item.content}</p>
                      <button
                        onClick={() => window.open(item.link, "_blank")}
                        className={styles.image}
                      >
                        <img
                          src={item.imageUrl}
                          alt={item.title}
                          style={{ width: "100%", height: "auto" }}
                        />
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SubmitProduct;
