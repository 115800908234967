import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import styles from "./MyProfile.module.css"; // Ensure the styles are set correctly
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCaretRight,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom"; // Import useLocation
import { UserContext } from "../components/UserContext";
import CategoryDropdown from "../components/CategoryDropdown";
import { useNavigate } from "react-router-dom";
import RatingsGallery from "../components/RatingsGallery";
import DropdownTags from "../components/DropdownTags";
import SimpleDropdown from "../components/SimpleDropdown";
import ReactSlider from "react-slider";

const YourRatings = () => {
  const location = useLocation(); // Use useLocation to get the current location object
  const { userId, username, photoUrl } = useContext(UserContext);
  const apiUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCategory2, setSelectedCategory2] = useState(null);
  const [showWishlist, setShowWishlist] = useState(false);
  const [activeRating, setActiveRating] = useState(null);
  const [profile_id, setProfile_id] = useState(null);
  const [profileName, setProfileName] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);

  const [sortType, setSortType] = useState("Date");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [showSearch, setShowSearch] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [displayFilter, setDisplayFilter] = useState(false);
  const [emptyFilter, setEmptyFilter] = useState(true);
  const [ratingRange, setRatingRange] = useState([1, 10]);
  const [sliderValues, setSliderValues] = useState([1, 10]);

  const [showBlocked, setShowBlocked] = useState(false);
  const [showBlocked2, setShowBlocked2] = useState(false);
  const [hasReview, setHasReview] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ratings, setRatings] = useState([]);
  const [updateFilters, setUpdateFilters] = useState(0);
  const [updateCount, setUpdateCount] = useState(0);
  const [ratingsRerender, setRatingsRerender] = useState(false);
  const [sortOptions, setSortOptions] = useState(["Date", "Rating", "Clicks"]);
  const [productShow, setProductShow] = useState(20);
  const [foundRatings, setFoundRatings] = useState(0);

  const [unratedOnly, setUnratedOnly] = useState(false);
  const [ratedOnly, setRatedOnly] = useState(false);
  const [reviewOnly, setReviewOnly] = useState(false);

  // Function to toggle boolean state
  const handleBlockChange = () => {
    setShowBlocked(!showBlocked);
    setReviewOnly(false);
    setRatedOnly(false);
    setUnratedOnly(false);
  };

  const handleSelect = (selectedItem) => {
    setSortType(selectedItem);
  };

  const scrollViewRef = useRef(null);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const [showFilter, setShowFilter] = useState(false);

  const handleRatedChange = (event) => {
    setRatedOnly(event.target.checked);
  };

  const handleUnratedChange = (event) => {
    setUnratedOnly(event.target.checked);
  };

  const handleReviewedChange = (event) => {
    setReviewOnly(event.target.checked);
  };

  const [ratingRangeDisplay, setRatingRangeDisplay] = useState([1, 10]);
  const [hasMore, setHasMore] = useState(true);
  const unknownImg =
    "https://s3.eu-west-2.amazonaws.com/rated.images/unknown.png";



  useEffect(() => {
    if (profile_id === -1) {
      return;
    }
    console.log("Fetching ratings");
    setLoading(true);
    setRatings([]);

    fetch(
      `${apiUrl}/get_user_ratings?user_id=${
        profile_id || location.state?.profile_id || userId
      }&self_id=${userId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setRatings(data.ratings); // Correct slicing here before setting state
        setLoading(false); // Move setLoading(false) here inside the async operation
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false); // Also handle setting loading false in case of error
      });
  }, [userId]); // Ensure dependencies are correct

    // Effect to update states when location.state changes
    useEffect(() => {
      setCategories(location.state?.categories || []);
      setWishlist(location.state?.wishlist || []);
      setSelectedCategories(location.state?.selectedCategories || []);
      setShowWishlist(location.state?.showWishlist || false);
      setActiveRating(location.state?.activeRating || null);
      setProfile_id(location.state?.profile_id || null);
      setProfileName(location.state?.profileName || username);
      setProfilePhoto(location.state?.profilePhoto || photoUrl);
  
      if (location.state?.selectedCategory != null) {
        setSelectedCategory2(location.state.selectedCategory);
      }
  
      if (location.state?.profile_id) {
        setSortOptions(["Date", "Rating", "Clicks", "Delta"]);
      } else {
        setSortOptions(["Date", "Rating", "Clicks"]);
      }
  
      if (location.state?.showWishlist) {
        setSortOptions(["Date"]);
      }
      scrollToTop();
    }, [location.state]);

  const processedRatings = useMemo(() => {
    if (ratings.length === 0) {
      return [];
    }
    let filteredRatings = [...ratings];

    console.log(activeRating, "Active Rating");

    const prioritizeRatingId =
      activeRating && Number(activeRating) > 0 ? Number(activeRating) : null;

    let priorityRating = null;

    if (prioritizeRatingId !== null) {
      const priorityIndex = filteredRatings.findIndex(
        (rating) => Number(rating.rating_id) === prioritizeRatingId
      );

      if (priorityIndex !== -1) {
        // Remove the priority rating from the array and store it
        [priorityRating] = filteredRatings.splice(priorityIndex, 1);
      }
    }

    // Filter by category
    if (selectedCategory2 !== null) {
      filteredRatings = filteredRatings.filter(
        (rating) => selectedCategory2 == rating.category_id
      );
    }

    // Sort based on sortType and sortDirection
    filteredRatings.sort((a, b) => {
      let compareVal = 0;
      switch (sortType) {
        case 'Rating':
          compareVal = a.rating - b.rating;
          break;
        case 'Date':
          compareVal = new Date(a.timestamp) - new Date(b.timestamp);
          break;
        case 'Clicks':
          compareVal = a.interactions - b.interactions;
          break;
        case 'Delta':
          // Calculate deltas
          const deltaA = Math.abs(a.rating - a.user_rating);
          const deltaB = Math.abs(b.rating - b.user_rating);

          // Check if both ratings are positive
          const positiveA = a.rating > 0 && a.user_rating > 0;
          const positiveB = b.rating > 0 && b.user_rating > 0;

          if (positiveA && !positiveB) {
            compareVal = -1; // a should come before b
          } else if (!positiveA && positiveB) {
            compareVal = 1; // b should come before a
          } else if (!positiveA && !positiveB) {
            compareVal = 0; // Both are non-positive, maintain their relative order
          } else {
            // Both are positive, sort by absolute delta
            compareVal = deltaA - deltaB;
          }
          break;
        default:
          break;
      }
      return sortDirection === 'ASC' ? compareVal : -compareVal;
    });

    // Move items with negative ratings to the end
    filteredRatings.sort((a, b) => {
      const negativeA = a.rating <= 0 || a.user_rating <= 0;
      const negativeB = b.rating <= 0 || b.user_rating <= 0;

      if (negativeA && !negativeB) {
        return 1; // a should come after b
      } else if (!negativeA && negativeB) {
        return -1; // a should come before b
      } else {
        return 0; // Maintain relative order among negatives or positives
      }
    });

    if (reviewOnly) {
      filteredRatings = filteredRatings.filter(
        (rating) => rating.review && rating.review.trim() !== ""
      );
    }

    if (ratedOnly) {
      filteredRatings = filteredRatings.filter(
        (rating) => rating.user_rating > 0
      );
    } else if (unratedOnly) {
      filteredRatings = filteredRatings.filter(
        (rating) => rating.user_rating == -1 || rating.user_rating == null
      );
    }

    if (showBlocked) {
      filteredRatings = filteredRatings.filter((rating) => rating.rating <= -2);
    } else if (showWishlist) {
      filteredRatings = filteredRatings.filter((rating) => rating.rating == -1);
    } else {
      filteredRatings = filteredRatings.filter(
        (rating) =>
          rating.rating >= ratingRange[0] && rating.rating <= ratingRange[1]
      );
    }

    if (searchInput != "") {
      filteredRatings = filteredRatings.filter((rating) =>
        rating.name.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    // Prepend the prioritized rating if it exists
    if (priorityRating !== null) {
      filteredRatings.unshift(priorityRating);
      if (activeRating) {
        setActiveRating(null);
      }
    }

    setFoundRatings(filteredRatings.length);
    if (ratingsRerender) {
      setUpdateCount(updateCount + 1);
    } else {
      setRatingsRerender(true);
    }

    // Restrict the number of products (e.g., to show only the first 20)
    //return filteredRatings.slice(0, 200);
    //return filteredRatings.slice(0, productShow);
    setHasMore(filteredRatings.length > productShow);
    return filteredRatings;
  }, [
    updateFilters,
    productShow,
    sortType,
    sortDirection,
    searchInput,
    ratings,
    selectedCategory2,
    showWishlist,
    wishlist,
    ratedOnly,
    unratedOnly,
    reviewOnly,
    ratingRange,
    showBlocked,
  ]); //

  const loadMoreRows = () => {
    setProductShow(productShow + 20);
    if (productShow >= foundRatings) {
      setHasMore(false);
    }
    console.log("Loading more rows");
  };

  const toggleWishlist = () => {
    setWishlist(!wishlist);
    setShowWishlist(!wishlist);
    setUpdateFilters(updateFilters + 1);
  };

  const handleRatingChange = (productId, userId, newRating, categoryId) => {
    setRatingsRerender(false);
    if (profile_id) {
      const updatedRatings = ratings.map((rating) => {
        if (rating.product_id === productId) {
          return {
            ...rating,
            user_rating: newRating,
          };
        }
        return rating; // Leave all other products unchanged.
      });
      setRatings(updatedRatings);
    } else {
      const updatedRatings = ratings.map((rating) => {
        if (rating.product_id === productId) {
          return {
            ...rating,
            rating: newRating, // Update the user_rating.
            user_rating: newRating,
          };
        }
        return rating; // Leave all other products unchanged.
      });
      setRatings(updatedRatings);
    }

    // Update the state with the modified products array.
  };

  const handleLike = (userId, item) => {
    if (!userId) {
      return;
    }
    setRatingsRerender(false);
    const ratingId = item.rating_id;
    const like = item.user_liked ? true : false;
    const payload = {
      user_id: userId,
      rating_id: ratingId,
      like: !like,
    };

    fetch(`${apiUrl}/like_rating`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    // Update the state with the modified products array.
    const updatedRatings = ratings.map((rating) => {
      if (rating.rating_id === ratingId) {
        return {
          ...rating,
          user_liked: !like,
          likes: !like ? rating.likes + 1 : rating.likes - 1,
        };
      }
      return rating;
    });

    setRatings(updatedRatings);
  };

  const handleBackClick = () => {
    navigate(-1); // This takes the user back to the previous page
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.page_title} ref={scrollViewRef}>
        {profile_id ? `@${profileName}'s` : "Your"}{" "}
        {showWishlist ? "Wishlist" : "Ratings"}
      </h1>

      <div className={styles.backIconContainer} onClick={handleBackClick}>
        <FontAwesomeIcon icon={faArrowLeft} className={styles.backArrow} />
      </div>

      <div
        className={styles.filterContainer}
        onClick={() => {
          scrollToTop();
          setShowFilter(!showFilter);
        }}
      >
        <FontAwesomeIcon icon={faFilter} className={styles.filterButton} />
      </div>

      <div
        className={styles.switchContainer}
        onClick={() => {
          scrollToTop();
          setShowWishlist(!showWishlist);
          if (showWishlist) {
            setSortOptions(["Date", "Rating", "Clicks"]);
          } else {
            setSortOptions(["Date"]);
          }
        }}
      >
        {showWishlist ? "Ratings" : "Wishlist"}{" "}
        <FontAwesomeIcon icon={faCaretRight} className={styles.switchButton} />
      </div>

      <div className={styles.dropdownFilters}>
        <CategoryDropdown
          categories={categories}
          selectedCategory={selectedCategory2}
          onCategoryChange={setSelectedCategory2}
          icon={true}
          name="Category"
        />
        <SimpleDropdown
          options={sortOptions}
          selectedOption={sortType}
          onSelect={handleSelect}
          name="Sort Type"
        />
        <button
          className={styles.sortButton}
          onClick={() =>
            setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC")
          }
        >
          {sortDirection === "ASC" ? "↑" : "↓"}
        </button>

        <input
          type="text"
          className={styles.searchInput}
          placeholder="Search Ratings"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
      </div>

      {showFilter && (
        <div className={styles.ratingFilters}>
          {!showWishlist && (
            <div className={styles.checkboxFilters}>
              {profile_id && (
                <div className={styles.checkboxFilters}>
                  <div className={styles.checkboxContainer}>
                    <input
                      type="checkbox"
                      checked={ratedOnly}
                      onChange={handleRatedChange}
                    />
                    Rated
                  </div>
                  <div className={styles.checkboxContainer}>
                    <input
                      type="checkbox"
                      checked={unratedOnly}
                      onChange={handleUnratedChange}
                    />
                    Unrated
                  </div>
                </div>
              )}
              <div className={styles.checkboxContainer}>
                <input
                  type="checkbox"
                  checked={reviewOnly}
                  onChange={handleReviewedChange}
                />
                Reviewed
              </div>
              <div className={styles.checkboxContainer}>
                <input
                  type="checkbox"
                  checked={showBlocked}
                  onChange={handleBlockChange}
                />
                Skipped
              </div>
            </div>
          )}

          {!showWishlist && !showBlocked && (
            <ReactSlider
              className={styles.ratingSlider}
              thumbClassName={styles.thumb}
              trackClassName={styles.track}
              defaultValue={[ratingRangeDisplay[0], ratingRangeDisplay[1]]}
              min={1}
              max={10}
              step={1}
              pearling
              onAfterChange={(values) => {
                setRatingRange(values);
              }}
              onChange={(values) => {
                setRatingRangeDisplay(values);
              }}
            />
          )}
          {!showWishlist && !showBlocked && (
            <div className={styles.ratingRangeLabel}>
              Rating Range: {ratingRangeDisplay[0]} - {ratingRangeDisplay[1]}
            </div>
          )}
        </div>
      )}

      <div className={styles.ratingGallery}>
        <RatingsGallery
          ratings={processedRatings.slice(0, productShow)}
          loading={loading}
          userId={userId}
          handleRatingChange={handleRatingChange}
          loadMoreRows={loadMoreRows}
          unknownImg={unknownImg}
          showTitle={false}
          handleLike={handleLike}
          hasMore={hasMore}
          profile_id={profile_id}
          setRatings={setRatings}
          profilePhoto={profilePhoto || null}
          profileName={profileName || null}
          screen={profile_id ? "UserProfile" : "YourRatings"}
        />
      </div>
    </div>
  );
};

export default YourRatings;
