import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faFilter } from "@fortawesome/free-solid-svg-icons";
import styles from "./Dropdown.module.css";

const CategoryDropdownSearch = ({
  categories,
  selectedCategory,
  onCategoryChange,
  icon,
  name = "Category",
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (
      !categories.some((category) => category.category_id === selectedCategory)
    ) {
      onCategoryChange(null);
    }
  }, [categories, selectedCategory, onCategoryChange]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleCategorySelect = (category_id) => {
    onCategoryChange(category_id);
    setIsOpen(false);
  };
  // Find the selected category object
  const selectedCategoryObj = categories.find(
    (category) => category.category_id === selectedCategory
  );

  const handleSelectAll = () => {
    onCategoryChange(null);
    setIsOpen(false);
  };

  const handleCloseDropdown = () => {
    setIsOpen(false);
  };

  return (
    <div className={styles.container}>
      <button onClick={toggleDropdown} className={styles.dropdownButtonSearch}>
        <span className={styles.buttonText} style={{ fontSize: 16 }}>
          {selectedCategoryObj ? "" : null}
        </span>
        {selectedCategoryObj ? (
          <div className={styles.filterContainer}>
            <img
              src={selectedCategoryObj.icon_url}
              className={styles.icon2}
              style={name.includes("Sort") ? { right: "60px" } : {}}
            />
          </div>
        ) : (
          <div className={styles.filterContainer}>
            <FontAwesomeIcon
              icon={faFilter}
              color="white"
              className={styles.filterSearch}
              style={name.includes("Sort") ? { right: "60px" } : {}}
            />
          </div>
        )}
      </button>

      {isOpen && (
        <div>
          <div className={styles.dropdownSearch}>
            <button onClick={handleSelectAll} className={styles.allButton2}>
              <span className={styles.text}>Select All</span>
            </button>
            {categories.map((category) => (
              <button
                key={category.category_id}
                onClick={() => handleCategorySelect(category.category_id)}
                className={styles.dropdownItem}
              >
                {icon && (
                  <img
                    src={category.icon_url}
                    alt=""
                    className={styles.categoryIcon}
                  />
                )}
                <span className={styles.itemText}>{category.name}</span>
              </button>
            ))}
          </div>

          <div className={styles.hidden}>
            <button onClick={handleCloseDropdown} className={styles.hidden} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryDropdownSearch;
