import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import styles from "./Dropdown.module.css";

const SimpleDropdownS = ({ options, selectedOption, onSelect, name }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [option, setSelectedOption] = useState(selectedOption || "");
  const buttonBorderWidth = name.includes("Sort") ? 0 : 1;

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    onSelect(option); // callback with the selected option
    setIsOpen(false); // close the dropdown
  };

  const handleCloseDropdown = () => {
    setIsOpen(false);
  };

  return (
    <div className={styles.containerS}>
      <button onClick={toggleDropdown} className={styles.dropdownButtonS}>
        <span className={styles.buttonText} style={{ fontSize: 16 }}>
          {selectedOption || `Select ${name}`}
        </span>
        <FontAwesomeIcon
          icon={faCaretDown}
          color="white"
          className={styles.arrowS}
          style={name.includes("Sort") ? { right: "60px" } : {}}
        />
      </button>

      {isOpen && (
        <div>
          <div className={styles.dropdownS}>
            {options.map((option) => (
              <button
                key={option}
                onClick={() => handleSelectOption(option)}
                className={styles.dropdownItemS}
              >
                <span className={styles.itemText}>{option}</span>
              </button>
            ))}
          </div>

          <div className={styles.hidden}>
            <button onClick={handleCloseDropdown} className={styles.hidden} />
          </div>
        </div>
      )}
    </div>
  );
};

export default SimpleDropdownS;
