// src/components/BlogList.js
import React from "react";
import { Link } from "react-router-dom";
import blogData from "../data/blogData";
import styles from "./Blog.module.css";

const Blog = () => {
  return (
    <div className={styles.container}>
      <div className={styles.blogList}>
        <h1>Rated Blog</h1>

        <p> </p>
        {blogData.map((post) => (
          <div key={post.id} className={styles.blogSummary}>
            <h2>{post.title}</h2>
            <p>{post.date}</p>
            {/* <p>{post.summary}</p> */}
            <Link to={`/BlogPost/${post.id}`}>Read more</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;
