import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import styles from "./Dropdown.module.css";

const SimpleDropdownSearch = ({ options, selectedOption, onSelect, name }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [option, setSelectedOption] = useState(selectedOption || "");
  const [searchText, setSearchText] = useState("");
  const buttonBorderWidth = name.includes("Sort") ? 0 : 1;

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    onSelect(option); // Callback with the selected option
    setIsOpen(false); // Close the dropdown
    setSearchText(''); // Clear search text on selection
  };

  const handleCloseDropdown = () => {
    setIsOpen(false);
    setSearchText(''); // Clear search text on closing
  };

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div className={styles.container}>
      <button onClick={toggleDropdown} className={styles.dropdownButton}>
        <span className={styles.buttonText} style={{ fontSize: 16 }}>
          {selectedOption || `Select ${name}`}
        </span>
        <FontAwesomeIcon
          icon={faCaretDown}
          color="white"
          className={styles.arrow}
          style={name.includes("Sort") ? { right: "60px" } : {}}
        />
      </button>

      {isOpen && (
        <div className={styles.dropdown}>
          <input
            type="text"
            className={styles.searchInput}
            placeholder="Search..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          {filteredOptions.map((option) => (
            <button
              key={option}
              onClick={() => handleSelectOption(option)}
              className={styles.dropdownItem}
            >
              <span className={styles.itemText}>{option}</span>
            </button>
          ))}
        </div>
      )}

      {isOpen && (
        <div className={styles.hidden}>
          <button onClick={handleCloseDropdown} className={styles.hidden} />
        </div>
      )}
    </div>
  );
};

export default SimpleDropdownSearch;