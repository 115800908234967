// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";  // Import this for authentication
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAaYX8dVrUTubrI8IPMbksR-AUwGNsNwH0",
  authDomain: "rated-58db3.firebaseapp.com",
  projectId: "rated-58db3",
  storageBucket: "rated-58db3.appspot.com",
  messagingSenderId: "601504243893",
  appId: "1:601504243893:web:a42563167e78dbc4d59efc",
  measurementId: "G-2JX44NDCVW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app); // Initialize auth

export { auth, analytics }; // Export the required instances