import React, { useState, useEffect, useMemo, useContext } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-annotation";
import RatingDropdown from "../components/RatingDropdown";
import { UserContext } from "../components/UserContext";
import styles from "./MyProfile.module.css";
import ReactSlider from "react-slider";
import ReviewModal from "../components/ReviewModal";
import { FaCheck, FaTimes, FaPen, FaLink } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCog, faShare, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../firebaseConfig"; // Make sure to import auth correctly
import CategoryDropdown from "../components/CategoryDropdown";
import Tutorial from "../components/Tutorial";
import Modal from "react-modal";
import ImageGallery from "react-image-gallery";
import { formatDistanceToNow } from "date-fns";

import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  Title,
  Tooltip,
  Legend
);

function MyProfile() {
  const {
    userId,
    setUserId,
    username,
    setUsername,
    photoUrl,
    setPhotoUrl,
    userType,
  } = useContext(UserContext);
  const navigate = useNavigate();
  const { rating_id } = useParams();
  const [userInfo, setUserInfo] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [userWantlist, setUserWantlist] = useState([]);
  const [relationships, setRelationships] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [friendCount, setFriendCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);
  const [followerCount, setFollowerCount] = useState(0);
  const [activeTab, setActiveTab] = useState("Profile");
  const [expandedSections, setExpandedSections] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [sortType, setSortType] = useState("timestamp");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [productShow, setProductShow] = useState(200);
  const [ratingRange, setRatingRange] = useState([1, 10]);
  const [ratingRangeDisplay, setRatingRangeDisplay] = useState([1, 10]);
  const [foundRatings, setFoundRatings] = useState(0);
  const [showOnlyReviews, setShowOnlyReviews] = useState(false);
  const [averageRating, setAverageRating] = useState(0);
  const [totalRatings, setTotalRatings] = useState(0);
  const [showTotalRatings, setShowTotalRatings] = useState(true);
  const [showAverageRating, setShowAverageRating] = useState(true);
  const [ratingDistribution, setRatingDistribution] = useState([]);
  const [weeklyRatings, setWeeklyRatings] = useState([]);
  const [weeklyAverage, setWeeklyAverage] = useState([]);
  const [selectedGraphCategory, setSelectedGraphCategory] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [allRelations, setAllRelations] = useState([]);
  const [groupedRelations, setGroupedRelations] = useState({});
  const [friendRequests, setFriendRequests] = useState([]);
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [userSocialMedia, setUserSocialMedia] = useState([]);
  const [dob, setDob] = useState([]);
  const [totalWishlist, setTotalWishlist] = useState(0);
  const [blocked, setBlocked] = useState([]);
  const [categoryHistograms, setCategoryHistograms] = useState([]);
  const [overallHistogram, setOverallHistogram] = useState([]);
  const [categoryAverage, setCategoryAverage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const [showWordCloud, setShowWordCloud] = useState(false);
  const [shareLoading, setShareLoading] = useState(false);
  const [wordClouds, setWordClouds] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [wordcloudLoading, setWordcloudLoading] = useState(false);
  const [showTutorial, setShowTutorial] = useState(
    location.state?.tutorial || false
  );
  const apiUrl = process.env.REACT_APP_BACKEND_URL;

  const handleBackClick = () => {
    navigate(-1); // This takes the user back to the previous page
  };

  const handleLogout = async () => {
    await signOut(auth);
    setUserId(null); // Reset user context
    setUsername("");
    setPhotoUrl("");
    // window.alert("User signed out");
    navigate("/"); // Redirect to the home page
  };

  const handleReviewClick = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const LoadingSpinner = () => <div className={styles.purpleSpinner}></div>;

  const handleWordcloud = (type) => {

    if (wordcloudLoading) {
      return;
    }
    
    return async () => {
      setWordcloudLoading(true);
      try {
        const response = await fetch(
          `${apiUrl}/generate_wordcloud?&user_id=${userId}&wordcloud_type=${type}&category_id=${selectedCategory}&username=${
            userInfo[0].username
          }&user_type=${userType}&device=${"web"}`
        );

        const data = await response.json();
        if (response.ok) {
          setShowWordCloud(true);
          window.open(data.url, `_blank_${Date.now()}`);

          // Append the new wordcloud to the beginning of the array
          setWordClouds((prevWordClouds) => [
            {
              id: data.id,
              category: selectedCategory,
              photo_url: data.url,
              timestamp: Date.now(),
            },
            ...prevWordClouds,
          ]);
        }
      } catch (error) {
        console.error("Error generating wordcloud:", error);
      }
      setWordcloudLoading(false);
    };
  };

  const copyToClipboard = () => {
    const profileUrl = `https://www.rated.fun/profiles/${userId}`;
    navigator.clipboard
      .writeText(profileUrl)
      .then(() => {
        setShowCopyMessage(true);
        setTimeout(() => setShowCopyMessage(false), 2000); // Message disappears after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleModalSubmit = (productId, userId, reviewText) => {
    const payload = {
      product_id: productId,
      user_id: userId,
      review: reviewText,
    };

    fetch(`${process.env.REACT_APP_BACKEND_URL}/submit_review`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        // Handle success case
        // Find the updated product and change its user_rating.
        const updatedRatings = ratings.map((rating) => {
          if (rating.product_id === productId) {
            return {
              ...rating,
              review: reviewText, // Update the user_rating.
            };
          }
          return rating; // Leave all other products unchanged.
        });

        // Update the state with the modified products array.
        setRatings(updatedRatings);
      } else {
        // Handle error case
      }
    });
  };

  const categoryInfoMap = categories.reduce((acc, info) => {
    acc[info.category_id] = info;
    return acc;
  }, {});

  const handleRatingChange = (productId, userId, newRating, categoryId) => {
    const payload = {
      product_id: productId,
      user_id: userId,
      rating: newRating,
      timestamp: new Date().toISOString(),
      category_id: categoryId,
    };

    fetch(`${process.env.REACT_APP_BACKEND_URL}/input_rating`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const updatedRatings = ratings.map((rating) => {
      if (rating.product_id === productId) {
        return {
          ...rating,
          rating: newRating, // Update the user_rating.
        };
      }
      return rating; // Leave all other products unchanged.
    });

    // Update the state with the modified products array.
    setRatings(updatedRatings);
  };

  useEffect(() => {
    async function fetchProfile() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/get_my_profile?&user_id=${userId}`
        );

        const data = await response.json();
        setRelationships(data.relationships);
        setFollowers(data.followers);
        setBlocked(data.blocked_users);
        setCategories(data.categories);
        setFriendCount(data.friend_count);
        setFollowingCount(data.following_count);
        setFollowerCount(data.follower_count);
        setFriendRequests(data.friend_requests);
        setUserSocialMedia(data.social_media);
        setUserInfo(data.info);
        setDob(data.info[0].dob);
        setTotalWishlist(data.total_wishlist);
        setAverageRating(data.average_rating);
        setTotalRatings(data.total_ratings);
        setCategoryHistograms(data.category_histograms);
        const overallHistogram = data.overall_histogram;
        setOverallHistogram(overallHistogram);
        setWordClouds(data.wordclouds);

        // Prepare distribution array based on overall histogram data
        const distribution = Array(10).fill(0);
        Object.entries(overallHistogram).forEach(([rating, count]) => {
          const index = parseInt(rating, 10);
          if (index >= 1 && index <= 10) {
            distribution[index - 1] = count;
          }
        });
        setRatingDistribution(distribution);

        if (rating_id) {
          navigate("/YourRatings", {
            state: {
              profile_id: null, // Example state value
              profileName: null, // Example state value
              categories: data.categories, // Your categories array
              showWishlist: false,
              ratings: true,
              activeRating: rating_id,
            },
          });
        }
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    }
    console.log("Test");
    fetchProfile();
  }, []); // Empty dependency array, only run once on mount.

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const processedRatings = useMemo(() => {
    let filteredRatings = [...ratings];

    // Filter by category
    if (selectedCategory !== "All") {
      filteredRatings = filteredRatings.filter(
        (rating) => rating.category_id == selectedCategory
      );
    }

    // const distribution = Array(10).fill(0);
    // filteredRatings.forEach((rating) => {
    //   if (rating.rating >= 1 && rating.rating <= 10) {
    //     distribution[rating.rating - 1]++;
    //   }
    // });
    // setRatingDistribution(distribution);

    // Sort based on sortType and sortDirection
    filteredRatings.sort((a, b) => {
      let compareVal = 0;
      switch (sortType) {
        case "rating":
          compareVal = a.rating - b.rating;
          break;
        case "timestamp":
          compareVal = new Date(a.timestamp) - new Date(b.timestamp);
          break;
        default:
          break;
      }
      return sortDirection === "ASC" ? compareVal : -compareVal;
    });

    if (activeTab == "Ratings") {
      filteredRatings = filteredRatings.filter(
        (rating) =>
          rating.rating >= ratingRange[0] && rating.rating <= ratingRange[1]
      );
    }

    if (showOnlyReviews) {
      filteredRatings = filteredRatings.filter(
        (rating) => rating.review && rating.review.trim() !== ""
      );
    }

    if (activeTab == "Wanted") {
      filteredRatings = filteredRatings.filter((rating) => rating.rating == -2);
    }

    setFoundRatings(filteredRatings.length);

    // Restrict the number of products (e.g., to show only the first 20)
    //return filteredRatings.slice(0, 200);
    return filteredRatings.slice(0, productShow);
  }, [
    ratings,
    selectedCategory,
    sortType,
    sortDirection,
    productShow,
    ratingRange,
    showOnlyReviews,
    activeTab,
  ]); //

  useEffect(() => {
    if (!relationships.length & !followers.length) return;
    const newAllRelations = [
      ...relationships,
      ...followers.map((follower) => ({
        ...follower,
        relationship: "Followers",
      })),
    ];

    setAllRelations(newAllRelations);

    const newGroupedRelations = newAllRelations.reduce((acc, relation) => {
      if (!acc[relation.relationship]) {
        acc[relation.relationship] = [];
      }
      acc[relation.relationship].push(relation);
      return acc;
    }, {});

    // Sort users within each relationship type by relation.similarity
    for (let relationshipType in newGroupedRelations) {
      newGroupedRelations[relationshipType].sort(
        (a, b) => b.similarity - a.similarity
      );
    }

    console.log(newGroupedRelations);
    setGroupedRelations(newGroupedRelations);
    console.log("Groups Updated");
  }, [relationships, followers]);

  const graphRatings = useMemo(() => {
    // Start with a fresh distribution array
    let distribution = Array(10).fill(0);
    setCategoryAverage(null);

    if (selectedCategory === "All") {
      // Calculate distribution for 'All' categories
      Object.entries(overallHistogram).forEach(([rating, count]) => {
        const index = parseInt(rating, 10) - 1;
        if (index >= 0 && index < 10) {
          distribution[index] = count;
        }
      });
      // Optionally calculate an average if needed
    } else {
      // Calculate distribution for selected category
      const categoryData = categoryHistograms.find(
        (cat) => cat.category_id === parseInt(selectedCategory, 10)
      );
      if (categoryData) {
        Object.entries(categoryData.ratings).forEach(([rating, count]) => {
          const index = parseInt(rating, 10) - 1; // Convert the rating key to an index
          if (index >= 0 && index < 10) {
            distribution[index] = count;
          }
        });
        //Look up category average from categories
        const categoryInfo = categoryInfoMap[selectedCategory];
        if (categoryInfo) {
          setCategoryAverage(categoryInfo.avg_rating);
        }
      }
    }

    // Set the distribution state
    setRatingDistribution(distribution);

    // Placeholder return, adjust as needed
    return [];
  }, [selectedCategory, categoryHistograms, overallHistogram]);

  const acceptFriendRequest = (request_id) => {
    const payload = {
      user_id: userId,
      request_id: request_id,
    };

    fetch(`${process.env.REACT_APP_BACKEND_URL}/accept_friend_request`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        // Handle success case
        console.log("Friend request accepted");
        const updatedFriendRequests = friendRequests.filter(
          (request) => request.user_id !== request_id
        );
        setFriendRequests(updatedFriendRequests);
      } else {
        // Handle error case
        console.error("Failed to accept request.");
      }
    });
  };

  const rejectFriendRequest = (request_id) => {
    const payload = {
      user_id: userId,
      request_id: request_id,
    };

    fetch(`${process.env.REACT_APP_BACKEND_URL}/reject_friend_request`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        // Handle success case
        console.log("Friend request rejected");
        const updatedFriendRequests = friendRequests.filter(
          (request) => request.user_id !== request_id
        );
        setFriendRequests(updatedFriendRequests);
      } else {
        // Handle error case
        console.error("Failed to reject request.");
      }
    });
  };

  // Event handler to remove the user and make a backend call.
  const removeUserFromGroup = (relationshipType, userId, relationId) => {
    // 1. Remove the user locally from the groupedRelations.
    const updatedRelationships = relationships.filter(
      (relation) =>
        !(
          relation.user_id === relationId &&
          relation.relationship === relationshipType
        )
    );
    console.log(`Original relationships count: ${relationships.length}`);
    console.log(`Updated relationships count: ${updatedRelationships.length}`);
    setRelationships(updatedRelationships);

    if (relationshipType === "Friends") {
      setFriendCount(friendCount - 1);
    }
    if (relationshipType === "Following") {
      setFollowingCount(followingCount - 1);
    }

    // 2. Make a POST request to your backend with the necessary information.
    const payload = {
      relationship: relationshipType,
      user1Id: userId,
      user2Id: relationId,
    };

    fetch(`${process.env.REACT_APP_BACKEND_URL}/remove_group`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        // Handle success case
        console.log("User removed successfully from the backend.");
      } else {
        // Handle error case
        console.error("Failed to remove user from the backend.");
      }
    });
  };

  // Event handler to remove the user and make a backend call.
  const removeTag = (tag, userId) => {
    // Confirm with the user before deletion
    const userConfirmed = window.confirm(
      `Are you sure you want to delete the group: ${tag}? This is irreversible.`
    );

    // If user did not confirm, return early to exit the function
    if (!userConfirmed) return;

    // 1. Remove the user locally from the groupedRelations.
    const updatedRelationships = relationships.filter(
      (relation) => !(relation.relationship === tag)
    );
    setRelationships(updatedRelationships);

    // 2. Make a POST request to your backend with the necessary information.
    const payload = {
      relationship: tag,
      userId: userId,
    };

    fetch(`${process.env.REACT_APP_BACKEND_URL}/remove_tag`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        // Handle success case
        console.log("User removed successfully from the backend.");
      } else {
        // Handle error case
        console.error("Failed to remove user from the backend.");
      }
    });
  };

  const settingsModal = () => {
    return (
      <div className={styles.modal} onClick={handleModalClose}>
        <div className={styles.modalContent}>
          <div className={styles.modalBody}>
            <div className={styles.optionText}>
              <Link to="/EditProfile" className={styles.optionText}>
                <h3 className={styles.optionText}>Edit Profile</h3>
              </Link>
            </div>
            <div className={styles.optionText}>
              <h3 className={styles.optionText} onClick={copyToClipboard}>
                Share Profile
              </h3>
            </div>
            <div className={styles.optionText}>
              <h3 className={styles.optionText} onClick={handleLogout}>
                Logout
              </h3>
            </div>
            <div className={styles.optionText}>
              <Link to="/ImportRatings" className={styles.optionText}>
                <h3 className={styles.optionText}>Import Ratings</h3>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (!userInfo.length) {
    return (
      <div className={styles.container}>
        {/* <h1 className={styles.page_title}>Your Profile</h1> */}
      </div>
    ); // Or any other loading state you'd like to display
  }

  return (
    <div className={styles.container}>
      {/* <h1 className={styles.page_title}>Your Profile</h1>

      <div className={styles.backIconContainer} onClick={handleBackClick}>
        <FontAwesomeIcon icon={faArrowLeft} className={styles.backArrow} />
      </div> */}

      {/* 3. Content Rendering */}

      {activeTab === "Profile" && (
        <div>
          {showTutorial && (
            <Tutorial screen="Profile" onClose={() => setShowTutorial(false)} />
          )}
          <div className={styles.profileContainer}>
            <div className={styles.flags} title={userInfo[0].country}>
              {userInfo[0].country_url ? (
                <img
                  src={userInfo[0].country_url}
                  title={userInfo[0].country}
                />
              ) : null}
              {userInfo[0].country2_url ? (
                <img
                  src={userInfo[0].country2_url}
                  title={userInfo[0].country2}
                />
              ) : null}
            </div>
            <div
              className={styles.settingsIconContainer}
              onClick={() => setShowModal(!showModal)}
            >
              <FontAwesomeIcon icon={faCog} className={styles.settingsIcon} />
            </div>
            {showModal && settingsModal()}
            {showCopyMessage && (
              <div className={styles.copy_message}>Link Copied!</div>
            )}
            <h2 className={styles.username}> @{userInfo[0].username} </h2>
            <img
              src={userInfo[0].photo_url}
              alt="User Profile"
              className={styles.profilePic}
            />
            <h4 className={styles.userType}> {userInfo[0].user_type} User </h4>
            <p className={styles.bioText}>{userInfo[0].bio}</p>
            {/* <Link to="/">
                <p className={styles.logout} onClick={handleLogout}>
                  [Logout]
                </p>
              </Link> */}
            {/* <h3>
                {userInfo[0].first_name} {userInfo[0].last_name}
              </h3> */}

            <div className={styles.ratingCounts}>
              <div className={styles.ratingStat}>
                <Link
                  to={"/YourRatings"}
                  state={{
                    profile_id: null, // Ensure userId is defined
                    profileName: null, // Should pass 'Test'
                    categories: categories,
                    showWishlist: false,
                    ratings: true,
                  }}
                  className={styles.ratingStat}
                >
                  <h4 className={styles.ratingText}>{totalRatings} </h4>
                  <h4 className={styles.ratingLabel}>Ratings</h4>
                </Link>
              </div>

              <div className={styles.ratingStat}>
                <Link
                  to={"/YourRatings"}
                  state={{
                    profile_id: null, // Ensure userId is defined
                    profileName: null, // Should pass 'Test'
                    categories: categories,
                    showWishlist: true,
                    ratings: false,
                  }}
                  className={styles.ratingStat}
                >
                  <h4 className={styles.ratingText}>{totalWishlist} </h4>
                  <h4 className={styles.ratingLabel}>Wishlist</h4>
                </Link>
              </div>

              <div className={styles.ratingStat}>
                <Link
                  to={"/YourNetwork"}
                  state={{
                    groupedRelations: groupedRelations, // Ensure this is not undefined
                    profile_id: null, // Ensure userId is defined
                    profileName: null, // Should pass 'Test'
                    self: false,
                  }}
                  className={styles.ratingStat}
                >
                  <h4 className={styles.ratingText}>{followerCount} </h4>
                  <h4 className={styles.ratingLabel}>Followers</h4>
                </Link>
              </div>

              <div className={styles.ratingStat}>
                <Link
                  to={"/YourNetwork"}
                  state={{
                    groupedRelations: groupedRelations, // Ensure this is not undefined
                    profile_id: null, // Ensure userId is defined
                    profileName: null, // Should pass 'Test'
                    self: false,
                  }}
                  className={styles.ratingStat}
                >
                  <h4 className={styles.ratingText}>{followingCount} </h4>
                  <h4 className={styles.ratingLabel}>Following</h4>
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.category_container}>
            <h2 className={styles.categories_title}>
              {" "}
              {categories.length} Rated Categories
            </h2>
            <div className={styles.categoryInfo}>
              {categories.map((category) => (
                <div
                  className={`${styles.categoryContainer} ${
                    selectedCategory === category.category_id
                      ? styles.selectedCategory
                      : ""
                  }`}
                >
                  <div className={styles.category_info_container}>
                    <h3 className={styles.categoryName}>{category.name}</h3>
                    <Link
                      to={"/YourRatings"}
                      state={{
                        profile_id: null, // Ensure userId is defined
                        profileName: null, // Should pass 'Test'
                        categories: categories,
                        showWishlist: false,
                        ratings: true,
                        selectedCategory: category.category_id,
                      }}
                    >
                      <img
                        className={styles.category_icon}
                        src={category.icon_url}
                        alt={category.name}
                        title={category.name}
                      />
                    </Link>
                    <h4 className={styles.categoryCount}>
                      {category.rating_count} Ratings
                    </h4>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className={styles.charts}>
            <div className={styles.chartTitles}>
              <h2>Average Rating: {categoryAverage || averageRating}</h2>

              <CategoryDropdown
                categories={[
                  { category_id: "All", name: "All Categories" },
                  ...categories,
                ]}
                selectedCategory={selectedCategory}
                onCategoryChange={setSelectedCategory}
                icon={true}
                name="Category"
              />
            </div>

            <Bar
              className={styles.bar_chart}
              data={{
                labels: Array.from({ length: 10 }, (_, i) => i + 1),
                datasets: [
                  {
                    label: "Number of Ratings",
                    data: ratingDistribution,
                    backgroundColor: "rgba(86, 0, 107, 1)",
                    borderColor: "rgba(100, 100, 100, 1)",
                    borderWidth: 1,
                    barPercentage: 1.2,
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    displayColors: false,
                    callbacks: {
                      label: function (context) {
                        return context.parsed.y; // Only return the y-value of the data point (i.e., the frequency)
                      },
                      title: function () {
                        return ""; // Return an empty string to suppress the title in the tooltip
                      },
                    },
                  },
                },
              }}
            />
          </div>
          {wordcloudLoading && (
            <div className={styles.loadingContainer}>
              <LoadingSpinner />
            </div>
          )}

          {selectedCategory !== "All" && categories.length > 0 && (
            <div className={styles.wordCloudContainer}>
              <button
                className={styles.wordCloudButton}
                onClick={handleWordcloud("keywords")}
              >
                <span className={styles.wordCloudButtonText}>
                  Generate Visual Analysis (Keywords)
                </span>
              </button>

              {[0, 2, 5, 8, 20, 21, 22].includes(
                categoryInfoMap[selectedCategory].category_id
              ) && (
                <button
                  className={styles.wordCloudButton}
                  onClick={handleWordcloud("creator")}
                >
                  <span className={styles.wordCloudButtonText}>
                    Generate Visual Analysis (Creator)
                  </span>
                </button>
                
              )}
            </div>
          )}

          {/* {wordClouds.length > 0 && (
            <div
              onClick={() => setShowWordCloud(true)}
              className={styles.showWordCloudText}
            >
              View Past Analysis ({wordClouds.length})
            </div>
          )} */}

        </div>
      )}
    </div>
  );
}

export default MyProfile;
