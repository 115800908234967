import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../components/UserContext";
import styles from "./EditProduct.module.css";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageUpload from "../components/ImageUpload";

function EditProduct() {
  const { product_id } = useParams();
  const [keywords, setKeywords] = useState([]);
  const [currentKeyword, setCurrentKeyword] = useState("");
  const [empty, setEmpty] = useState("true");
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("None");
  const [name, setName] = useState("");
  const [category_id, setCategory_id] = useState("");
  const [image_url, setImage_url] = useState("");
  const [timestamp, setTimestamp] = useState(new Date().toISOString());
  const [secondary_info, setSecondary_info] = useState("");
  const [description, setDescription] = useState("");
  const [other_id, setOther_id] = useState(""); // Assuming you will collect this in your form somewhere.
  const { userId } = useContext(UserContext);
  const [inputUrl, setInputUrl] = useState("");
  const navigate = useNavigate();
  const [year, setYear] = useState("");
  const [location, setLocation] = useState("");
  const [minYear, setMinYear] = useState(null);
  const [useLocation, setUseLocation] = useState(false);
  const [inputPhoto, setInputPhoto] = useState("");
  const [manualPhotoUrl, setManualPhotoUrl] = useState("");
  const [city, setCity] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [creator, setCreator] = useState("");
  const [childCategories, setChildCategories] = useState([]);
  const [childCategory, setChildCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("Quick");
  const [locationDetails, setLocationDetails] = useState(null);
  const [locationData, setLocationData] = useState(null);
  const [hotelUrl, setHotelUrl] = useState("");
  const [parentCategory, setParentCategory] = useState(null);
  const [parentSecondaryInfo, setParentSecondaryInfo] = useState(null);
  const [parentId, setParentId] = useState(null);
  const [parentName, setParentName] = useState(null);

  const apiUrl = process.env.REACT_APP_BACKEND_URL;
  const google_api_key = process.env.google_key;

  const handleDeleteProduct = () => {
    // Confirmation dialog
    const isConfirmed = window.confirm("Are you sure you want to delete this product?");
    if (isConfirmed) {
      deleteProduct();
    } else {
      console.log("Product deletion cancelled.");
    }
  };

  const deleteProduct = () => {
    const payload = {
      product_id: product_id,
    };

    fetch(`${apiUrl}/delete_product`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.ok) {
          // Display success message
          window.alert("Product deleted successfully!");
          // Navigate to another screen if needed
          navigate("/");
        } else {
          // Handle error case
          window.alert("Error deleting product.");
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during fetch
        window.alert("Error:", error.message);
      });
  };

  useEffect(() => {
    async function fetchProduct() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/get_product_info?product_id=${product_id}&user_id=${userId}`,
          {
            headers: {
              "Cache-Control": "no-store",
            },
          }
        );
        const responseData = await response.json();
        const productData = responseData.product_info[0];
        setName(productData.name);
        setCategory_id(productData.category_id);
        setCategory(productData.category_id);
        setImage_url(productData.product_photo);
        setSecondary_info(productData.secondary_info);
        setDescription(productData.description);
        setOther_id(productData.other_id);
        const keywords = responseData.keywords.map(
          (keywordObj) => keywordObj.keyword
        );
        setKeywords(keywords);
        setLatitude(productData.latitude);
        setLongitude(productData.longitude);
        setCreator(productData.creator);
        setParentId(productData.parent_id);
        setYear(productData.year);
        setCity(productData.secondary_info);
        setParentName(productData.parent_name);
        handleCategoryChange(productData.category_id);
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    }

    fetchProduct();
  }, [product_id]);

  useEffect(() => {
    async function fetchTags() {
      try {
        const response = await fetch(
          `${apiUrl}/get_all_categories?empty=${empty}`
        );
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    }

    fetchTags();
  }, []); // Empty dependency array, only run once on mount.

  const addKeywords = async () => {
    const newKeywords = currentKeyword
      .split(",")
      .map((kw) => kw.replace(/\s+/g, "").toLowerCase())
      .filter((kw) => kw);

    // Assuming the product_id is available in the state or via props
    const payload = {
      product_id: product_id,
      keywords: newKeywords,
      category_id: category_id,
    };

    try {
      const response = await fetch(`${apiUrl}/add_keywords`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data.message); // Should log "Keywords added successfully!"
      } else {
        console.error("Failed to add keywords:", await response.text());
      }
    } catch (err) {
      console.error("Error when calling backend:", err);
    }

    setKeywords((keywords) => [...new Set([...newKeywords, ...keywords])]);
    setCurrentKeyword("");
  };

  const removeKeyword = async (index) => {
    console.log("Removing keyword:", keywords[index]);
    const payload = {
      product_id: product_id,
      keyword: keywords[index],
    };

    try {
      const response = await fetch(`${apiUrl}/remove_keyword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data.message); // Assuming the backend sends a message on success, adjust according to actual backend response
        // Now update the state to reflect the removed keyword
        const newKeywords = [...keywords];
        newKeywords.splice(index, 1);
        setKeywords(newKeywords);
      } else {
        console.error("Failed to remove keyword:", await response.text());
      }
    } catch (err) {
      console.error("Error when calling backend:", err);
    }
  };

  useEffect(() => {
    setSecondary_info(year);
  }, [year]);

  useEffect(() => {
    setSecondary_info(city);
  }, [city]);

  const handleSubmit = async () => {
    // Define an array of required fields and their values
    // const requiredFields = [
    //   { name: "name", value: name },
    //   { name: "category_id", value: category_id },
    //   { name: "image_url", value: image_url },
    // ];

    // if (useLocation) {
    //   requiredFields.push({ name: "Location", value: location });
    // }

    // // Check for values that are specifically `null` or `undefined`
    // const missingFields = requiredFields
    //   .filter((field) => field.value === null || field.value === undefined || field.value === "")
    //   .map((field) => field.name);

    // if (missingFields.length > 0) {
    //   // Log missing fields to the console
    //   console.log("Missing fields:", missingFields.join(", "));

    //   // Alert the user about the missing fields, listing them
    //   window.alert(
    //     `Please fill out all the fields! Missing: ${missingFields.join(", ")}`
    //   );
    //   return;
    // }

    const payload = {
      name,
      category_id,
      image_url,
      timestamp,
      secondary_info,
      description,
      other_id,
      user_id: userId,
      keywords,
      latitude,
      longitude,
      creator,
      parentId,
      childCategory,
      parentName,
      year,
      city,
      product_id: product_id,
    };

    try {
      const response = await fetch(`${apiUrl}/edit_product`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (data.status === "success") {
        window.alert("Product Edited!");
        // Navigate to the newly created product's page
        navigate(`/products/${product_id}`);
      } else {
        window.alert(data.message);
      }
    } catch (error) {
      console.error("Error adding the product:", error);
    }
  };

  const handleCategoryChange = useCallback(
    (itemValue) => {
      setCategory(itemValue);
      setCategory_id(itemValue);
      const category = categories.find(
        (category) => category.category_id == itemValue
      );
      if (category) {
        // setCategory_id(category.category_id);
        setUseLocation(category.location);
        setMinYear(category.min_year);
        console.log("minYear", category.min_year);
      }
    },
    [categories]
  );

  const onFileUploaded = (url) => {
    setInputPhoto(url);
    setImage_url(url);
  };

  const handleBackClick = () => {
    navigate(-1); // This takes the user back to the previous page
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.page_title}>Edit Product</h1>

      <div className={styles.backIconContainer} onClick={handleBackClick}>
        <FontAwesomeIcon icon={faArrowLeft} className={styles.backArrow} />
      </div>

      <p className={styles.verified}>
        Only verified users can edit products. To get verified please reach out
        via email, discord or social media.
      </p>

      <div>
        <div className={styles.submitProductFields}>
          <label
            title="Product name should be unique and descriptive."
            className={styles.fieldLabel}
          >
            Name:
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Eg. The Graduate"
              className={styles.textInput}
            />
          </label>

          <label
            title="Select a relevant category for the product."
            className={styles.fieldLabel}
          >
            Category: (if not listed, please contact us to propose an additional
            category)
            <select
              value={category}
              onChange={(e) => {
                handleCategoryChange(e.target.value);
              }}
              className={styles.selectInput}
            >
              <option value="">Select a Category</option>
              {categories.map((category) => (
                <option key={category.category_id} value={category.category_id}>
                  {category.name}
                </option>
              ))}
            </select>
          </label>

          {minYear > 0 && (
            <label
              title="Link to an external website for more information."
              className={styles.fieldLabel}
            >
              Year:
              <input
                value={year}
                onChange={(e) => setYear(e.target.value)}
                placeholder="Year the product was released or created"
                className={styles.textInput}
              />
            </label>
          )}

          {minYear > 0 && (
            <label title="Creator of the product" className={styles.fieldLabel}>
              Creator:
              <input
                value={creator}
                onChange={(e) => setCreator(e.target.value)}
                placeholder="Creator(s) of the product"
                className={styles.textInput}
              />
            </label>
          )}

          {useLocation && (
            <label
              title="Link to an external website for more information."
              className={styles.fieldLabel}
            >
              City:
              <input
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder="City the product was released or created"
                className={styles.textInput}
              />
            </label>
          )}

          <label
            title="Any additional information related to the product."
            className={styles.fieldLabel}
          >
            Secondary Info:
            <input
              value={secondary_info}
              onChange={(e) => setSecondary_info(e.target.value)}
              placeholder="Most useful piece of identifying information, Year for Media, City for a Location"
              className={styles.textInput}
            />
          </label>

          <label
            title="Link to the product image."
            className={styles.fieldLabel}
          >
            Image Url:
            <input
              value={image_url}
              onChange={(e) => setImage_url(e.target.value)}
              placeholder="Provide a URL to direct to an image of the product or upload below"
              className={styles.textInput}
            />
          </label>

          {image_url && image_url.length > 0 && (
            <img
              src={image_url}
              alt="Product"
              className={styles.imagePreview}
            />
          )}

          <div className={styles.image_upload}>
            <ImageUpload
              onFileUploaded={onFileUploaded}
              linkName1={"Upload Photo"}
              linkName2={"Edit Photo"}
            />
          </div>

          <label
            title="Link to an external website for more information."
            className={styles.fieldLabel}
          >
            External Url:
            <input
              value={other_id}
              onChange={(e) => setOther_id(e.target.value)}
              placeholder="Provide a URL to direct to another website for more information about the product"
              className={styles.textInput}
            />
          </label>

          <label
            title="Brief description about the product."
            className={styles.fieldLabel}
          >
            Description:
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className={styles.textareaInput}
              placeholder="Write a brief description about the product (Optional)"
            />
          </label>

          <label
            title="Add relevant keywords for better searchability."
            className={styles.fieldLabel}
          >
            Tags:
            <input
              value={currentKeyword}
              onChange={(e) => setCurrentKeyword(e.target.value)}
              className={styles.textInput}
              placeholder="Add as comma separated words, eg 'thriller', 'french','samsung'"
            />
            <button
              type="button"
              onClick={addKeywords}
              className={styles.addButton}
            >
              Add
            </button>
          </label>

          <div className={styles.keywordsContainer}>
            {keywords.map((keyword, index) => (
              <div key={index} className={styles.keywordBubble}>
                #{keyword}
                <span
                  className={styles.removeKeyword}
                  onClick={() => removeKeyword(index)}
                >
                  X
                </span>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.submitProductButtons}>
          <button onClick={handleSubmit} className={styles.submitButton}>
            Submit Edits
          </button>
        </div>

        {userId == 500 && (
          <div className="product-delete">
            <button onClick={handleDeleteProduct}>Delete Product</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default EditProduct;
