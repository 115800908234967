
import styles from "./PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <h1>Privacy Policy</h1>
        <div>
        <p>Last updated: 27/03/2024</p>
      
      <p>Welcome to Rated, a social media platform dedicated to sharing ratings of products, places, and media. Your privacy is critically important to us.</p>
      
      <h2>Information We Collect</h2>
      <p>We only collect information that is necessary for the effective operation of our platform. This includes:</p>
      <ul>
        <li>Basic account information: Username, password, and email address.</li>
        <li>Ratings and reviews shared on the platform.</li>
        <li>Interactions with other users on the platform.</li>
      </ul>
      
      <h2>How We Use Your Information</h2>
      <p>The information we collect is used to:</p>
      <ul>
        <li>Provide, operate, and maintain our platform.</li>
        <li>Improve, personalize, and expand our platform.</li>
        <li>Understand and analyze how you use our platform.</li>
        <li>Develop new products, services, features, and functionality.</li>
      </ul>
      
      <h2>Sharing Your Information</h2>
      <p>We do not share your personal information with third parties, except as required by law or to protect our rights.</p>
      
      <h2>Data Protection</h2>
      <p>We take the protection of your data seriously and implement a variety of security measures to maintain the safety of your personal information.</p>
      <p>Direct messages are currently not encrypted, but this will be later introduced.</p>

      
      <h2>Changes to This Policy</h2>
      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
      
      <h2>Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us via email at ratedapplimited@gmail.com</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
