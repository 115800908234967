import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import styles from "./Dropdown.module.css";

const Dropdown = ({
  categories,
  selectedCategories,
  onCategoryChange,
  icon,
  name,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tempSelectedCategories, setTempSelectedCategories] =
    useState(selectedCategories);

  useEffect(() => {
    setTempSelectedCategories(selectedCategories);
  }, [selectedCategories]);

  const toggleDropdown = () => {
    if (isOpen) {
      onCategoryChange(tempSelectedCategories);
    }
    setIsOpen(!isOpen); // Toggle the dropdown open/closed state
  };

  const handleCheckbox = (category_id, isChecked) => {
    let newTempSelectedCategories;
    if (isChecked) {
      newTempSelectedCategories = [...tempSelectedCategories, category_id];
    } else {
      newTempSelectedCategories = tempSelectedCategories.filter(
        (id) => id !== category_id
      );
    }
    setTempSelectedCategories(newTempSelectedCategories);
  };

  const handleSelectAll = () => {
    if (tempSelectedCategories.length === categories.length) {
      setTempSelectedCategories([]);
    } else {
      const allCategoryIds = categories.map((category) => category.category_id);
      setTempSelectedCategories(allCategoryIds);
    }
  };

  const handleCloseDropdown = () => {
    onCategoryChange(tempSelectedCategories);
    setIsOpen(false);
  };

  const getCategoryNamesByIds = (categoryIds) => {
    return categories
      .filter((category) => categoryIds.includes(category.category_id))
      .map((category) => category.name)
      .join(", ");
  };

  const [displayedText, setDisplayedText] = useState("");
  const maxWidth = 140; // Set your desired max width here

  useEffect(() => {
    const fullText = getCategoryNamesByIds(tempSelectedCategories);
    setDisplayedText(fullText);
  }, [tempSelectedCategories]);

  const onTextLayout = (e) => {
    const { width } = e.nativeEvent.layout;
    if (width > maxWidth) {
      let truncatedText =
        getCategoryNamesByIds(tempSelectedCategories).slice(0, 20) + "..."; // Adjust slicing as needed
      setDisplayedText(truncatedText);
    }
  };

  const submitButtonTop = Math.min( (categories.length * 130), 620);

  return (
    <div className={styles.container}>
      <button onClick={toggleDropdown} className={styles.dropdownButton}>
        {displayedText ? (
          <span className={styles.buttonText}>
            {displayedText}
          </span>
        ) : (
          <span className={styles.buttonText2}>
            {"All Categories"}
          </span>
        )}
        <FontAwesomeIcon
          icon={faCaretDown}
          color="white"
          className={styles.arrow}
        />
      </button>

      {isOpen && (
        <div>
          <div className={styles.dropdown}>
            <button onClick={handleSelectAll} className={styles.allButton2}>
              <span className={styles.text}>Select All</span>
            </button>
            {categories.map((category) => (
              <button
                key={category.category_id}
                onClick={() =>
                  handleCheckbox(
                    category.category_id,
                    !tempSelectedCategories.includes(category.category_id)
                  )
                }
                className={styles.dropdownItem}
              >
                {icon && (
                  <img
                    src={category.icon_url}
                    alt=""
                    className={styles.categoryIcon}
                  />
                )}
                <span className={styles.itemText}>{category.name}</span>
                <input
                  type="checkbox"
                  checked={tempSelectedCategories.includes(
                    category.category_id
                  )}
                  onChange={(e) =>
                    handleCheckbox(category.category_id, e.target.checked)
                  }
                  className={styles.checkbox}
                />
              </button>
            ))}
          </div>
          <button onClick={handleCloseDropdown} className={styles.submitButton} style={{ top: submitButtonTop }}>
            <span className={styles.text}>Submit</span>
          </button>

          <div className={styles.hidden}>
            <button onClick={handleCloseDropdown} className={styles.hidden} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
