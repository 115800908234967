import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./MyProfile.module.css"; // Ensure the styles are set correctly
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom"; // Import useLocation
import { UserContext } from "../components/UserContext";
import { useNavigate } from "react-router-dom";

const YourNetwork = () => {
  const location = useLocation(); // Use useLocation to get the current location object
  const { userId } = useContext(UserContext);
  const apiUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();

  // Use an empty object and null as initial states
  const [groupedRelations, setGroupedRelations] = useState({});
  const [expandedSections, setExpandedSections] = useState({});
  const [profileId, setProfileId] = useState(null);
  const [profileName, setProfileName] = useState(null);

  // Effect to update states when location.state changes
  useEffect(() => {
    console.log(location.state); // Log the location state to the console
    setGroupedRelations(location.state?.groupedRelations || {});
    setProfileId(location.state?.profile_id || null);
    setProfileName(location.state?.profileName || null);
  }, [location.state]);

  const handleBackClick = () => {
    navigate(-1); // This takes the user back to the previous page
  };

  const RelationItem = ({ relation, relationshipType, userId }) => (
    <div className={styles.relationshipInfo}>
      {relationshipType !== "Followers" && profileId==null && (
        <button
          onClick={() =>
            removeUserFromGroup(relationshipType, userId, relation.user_id)
          }
          className={styles.removeButton}
        >
          <span className={styles.removeText}>X</span>
        </button>
      )}
      <Link to={`/profiles/${relation.user_id}`}>
        <img
          src={relation.photo_url}
          alt={relation.username}
          className={styles.networkImage}
        />
      </Link>
      <div className={styles.userInfoContainer}>
        <span className={styles.text}>@{relation.username}</span>
        {relation.similarity > 0 && (
          <span className={styles.text}> {relation.similarity}% </span>
        )}
      </div>
    </div>
  );

  const RelationList = ({ relations, relationshipType, userId }) => (
    <div className={styles.relationList}>
      {relations.map((relation) => (
        <RelationItem
          key={relation.user_id}
          relation={relation}
          relationshipType={relationshipType}
          userId={userId}
        />
      ))}
    </div>
  );

  const renderRelationshipGroup = ({ relationshipType }) => (
    <div className={styles.relationship_group}>
      <div
        className={styles.relationshipHeader}
        onClick={() => toggleSection(relationshipType)}
      >
        <span className={styles.relationshipText}>
          {relationshipType} ({groupedRelations[relationshipType].length})
        </span>
        {!profileName && (
          <div className={styles.relationshipButtons}>
            {["Friends", "Following", "Followers", "Blocked"].includes(
              relationshipType
            ) ? null : (
              <button
                className={styles.deleteButton}
                onClick={() => removeTag(relationshipType, userId)}
              >
                X
              </button>
            )}
          </div>
        )}
      </div>
      {expandedSections[relationshipType] && (
        <RelationList
          relations={groupedRelations[relationshipType]}
          relationshipType={relationshipType}
          userId={userId}
        />
      )}
    </div>
  );

  const toggleSection = (relationshipType) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [relationshipType]: !prevState[relationshipType],
    }));
  };

  const removeUserFromGroup = (relationshipType, userId, relationId) => {
    const newRelations = groupedRelations[relationshipType].filter(
      (relation) => relation.user_id !== relationId
    );
    setGroupedRelations({
      ...groupedRelations,
      [relationshipType]: newRelations,
    });

    const payload = {
      relationship: relationshipType,
      user1Id: userId,
      user2Id: relationId,
    };

    fetch(`${apiUrl}/remove_group`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        console.log("User removed successfully from the backend.");
      } else {
        console.error("Failed to remove user from the backend.");
      }
    });
  };

  const removeTag = (tag, userId) => {
    if (
      window.confirm(
        `Are you sure you want to delete the tag: ${tag}? This is irreversible.`
      )
    ) {
      setGroupedRelations((prevState) => ({
        ...prevState,
        [tag]: [],
      }));

      const payload = {
        relationship: tag,
        userId: userId,
      };

      fetch(`${apiUrl}/remove_tag`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }).then((response) => {
        if (response.ok) {
          console.log("Tag removed successfully from the backend.");
        } else {
          console.error("Failed to remove tag from the backend.");
        }
      });
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.page_title}>
        {profileName ? `@${profileName}'s` : "Your"} Network{" "}
      </h1>

      <div className={styles.backIconContainer} onClick={handleBackClick}>
        <FontAwesomeIcon icon={faArrowLeft}  className={styles.backArrow} />
      </div>

      {groupedRelations && (
        <div className={styles.networkContainer}>
          {Object.keys(groupedRelations).map((relationshipType) =>
            renderRelationshipGroup({ relationshipType })
          )}
        </div>
      )}
    </div>
  );
};

export default YourNetwork;
