import React, { useState, useEffect, useMemo, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-annotation";
import { UserContext } from "../components/UserContext";
import styles from "./About.module.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCaretRight,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";

const About = () => {
  const [activeTab, setActiveTab] = useState("About");
  const [feedback, setFeedback] = useState({});
  const [newFeedbackText, setNewFeedbackText] = useState({});
  const apiUrl = process.env.REACT_APP_BACKEND_URL;
  const { userId, username, photoUrl } = useContext(UserContext);
  const navigate = useNavigate();
  const [feedbackText, setFeedbackText] = useState("");

  const submitFeedback = () => {
    setFeedbackText(newFeedbackText);
    const payload = {
      user_id: userId,
      feedback: newFeedbackText,
      timestamp: new Date().toISOString(),
    };

    fetch(`${apiUrl}/submit_feedback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    window.alert("Feedback submitted", "Thank you for your feedback!");
    setNewFeedbackText("");
  };

  const faqs = [
    {
      question: "What is the purpose of rated?",
      answer: `It is our ambition to become the most reliable and trustworthy rating platform in the world. We want to help you make better decisions on what to watch, read, play, eat, buy and more.
      \nRight now there are several different rating platforms for different things. We want to bring all of these together in one place and greatly improve on them.`,
    },
    {
      question: "How is similarity calculated?",
      answer: `Similarity is a score calculated between two users to show how similar their ratings are. 
  \nFor each rating in common, the difference of the squares is calculated, the average difference of squares is calculated and subtracted from 100.
  \nThis gives a maximum score of 100 and a minimum score of 1. This calculation method puts more importance on larger ratings rather than smaller ones.
  \n For example, the similarity penalty for a 1/10 compared to a 3/10 is much lower than the penalty for a 8/10 compared to a 10/10.
  \n The former would give a similarity of 92% and the latter 76%. This helps you to find people who agree on the best content.
  \n An overall similarity score required 10 ratings in common to be calculated. For category-specific scores there is no requirement.`,
    },
    {
      question: "How are predicted ratings calculated?",
      answer: `Predicted ratings are calculated personally for you using all other user's ratings and your similarity with them for that category.
      \nCurrently, there is a cut-off similarity of 65%, meaning that only users with a similarity of 65% or higher are used in the calculation.
      \nThe predicted rating is calculated by taking the average of all other user's ratings for that item, weighted by their similarity to you.
      \nThe more accurate ratings you and others give, the better we can predict what you will like. As rated grows, this will become more powerful.
      \nAs we get more users and ratings, we will experiment with other algorithms. We will always be transparent about how we calculate predictions.`,
    },
    {
      question: "Why and when will you require ID verification?",
      answer: `Rating integrity is extremely important to us, it is one of the biggest drawbacks of all existing rating and social platforms.
      \nBots and fake ratings can ruin make it harder to trust the ratings you see. We want to be the most reliable rating platform in the world. ID verification is the best way to achieve this.
      \nYou will still be able to use the platform as normal without ID verification, but your ratings will not count towards global averages and will be invisilbe to those who are not following or friends with you.
      \nWe will require ID verification when we become big enough that restaurants and other businesses would be tempted to make fake reviews.
      \nBeta users will be exempt from this requirement.`,
    },
    {
      question: "Will user data be shared?",
      answer: `Your personal information will never be shared with anyone. Your ratings will be used to generate predictions, similarities and product averages. Anonymised will  be used to generate data insights about our users and products.
      \nPersonal rating data will not be shared or sold to third parties.`,
    },
    {
      question: "Can I add a new category?",
      answer: `We are working on a system to allow users to add new categories. This will be available in the future. For now, please message us with any new categories you would like to see and we will add them for you.
      \nUntil they are added, use the Other category and later transfer the ratings to the new category.`,
    },
    {
      question: "What are points and how do I get them?",
      answer: `We are in the process of introducing a points system, to reward users for contributing to Rated. Points will be awarded for giving ratings, adding new products, editing existing products and referring other users.
      \nPoints will be used to unlock more account personalisation and in the future unlock paid features. Additionally, if the platform becomes profitable, points may be redeemable for cash. This is not guaranteed and points hold no financial value.
      \nThey can be deducted for suspicious activity or breaking the rules at any time.`,
    },

    // Add more questions and answers here
  ];

  const handleBackClick = () => {
    navigate(-1); // This takes the user back to the previous page
  };

  const FAQItem = ({ item }) => {
    const [expanded, setExpanded] = useState(false);

    return (
      <div>
        <div
          className={styles.questionContainer}
          onClick={() => setExpanded(!expanded)}
        >
          <p className={styles.questionText}>{item.question}</p>
        </div>
        {expanded && <p className={styles.answerText}>{item.answer}</p>}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.page_title}>{activeTab}</h1>

      {/* <div className={styles.backIconContainer} onClick={handleBackClick}>
        <FontAwesomeIcon icon={faArrowLeft} className={styles.backArrow} />
      </div> */}
      <div className={styles.tabs}>
        <button
          className={`${styles.tabButton} ${
            activeTab === "About" ? styles.active : ""
          }`}
          onClick={() => setActiveTab("About")}
        >
          About
        </button>

        <button
          className={`${styles.tabButton} ${
            activeTab === "Rules" ? styles.active : ""
          }`}
          onClick={() => setActiveTab("Rules")}
        >
          Rules
        </button>

        <button
          className={`${styles.tabButton} ${
            activeTab === "Guidelines" ? styles.active : ""
          }`}
          onClick={() => setActiveTab("Guidelines")}
        >
          Guidelines
        </button>

        <button
          className={`${styles.tabButton} ${
            activeTab === "FAQ" ? styles.active : ""
          }`}
          onClick={() => setActiveTab("FAQ")}
        >
          FAQ
        </button>

        <button
          className={`${styles.tabButton} ${
            activeTab === "Terms" ? styles.active : ""
          }`}
          onClick={() => setActiveTab("Terms")}
        >
          Terms
        </button>
      </div>
      {activeTab === "About" && (
        <div>
          <p>
            Rated is a social platform for sharing ratings on all media,
            products, places and more.
            <br />
            <br />
            It is very hard to predict how much you will enjoy something. The
            best indicator can often be the opinions of those you trust. Using
            rated its very easy to see your whole network's opinion on something
            before you commit to it.
            <br />
            <br />
            Whose taste actually aligns with yours? Rated gives you metrics to
            see who rates things similarly to you, signaling to you who you
            should or shouldn't trust!
            <br />
            <br />
            Please rate as many things as you can. The more ratings you give,
            the more you can find similar users to you and the more accurate
            your recommendations will be.
            <br />
            <br />
            Rated is currently starting its closed beta phase. Our long term
            aspiration is for Rated to become a household staple to use in
            deciding any sort of purchase or activity.
            <br />
            <br />
            At this stage feedback is very much appreciated. Please report as
            much feedback as possible.
            <br />
            <br />
            Our privacy policy can be found at :
            https://www.rated.fun/PrivacyPolicy
            <br />
            <br />
          </p>
        </div>
      )}

      {activeTab === "Rules" && (
        <div>
          <p>
            1. All ratings you submit must reflect your honest opinion and be
            based on your own experience. See guidelines for recommendations on
            acceptable usage time.
            <br />
            <br />
            2. Ratings of pornography or illegal content are not allowed.
            <br />
            <br />
            3. Any deliberately incorrect or offensive tags added to ratings
            will be removed and result in a up to a permanent ban.
            <br />
            <br />
            4. Only one account per person is allowed. All accounts must be
            personal, no business accounts are permitted.
            <br />
            <br />
            5. Any harrassment in direct messages may result in a permanent ban.
            <br />
            <br />
            6. ID verification will soon be required for a full account. This is
            an unfortunately necessary step to prevent duplicate accounts and
            fake ratings. With this in place we can be the most reliable rating
            platform in the world. Your personal information will be hidden and
            not shared with anyone.
          </p>
        </div>
      )}

      {activeTab === "Guidelines" && (
        <div>
          <p>
            Guidelines have been put in place to give some clarity on
            recommended usage of the platform. These cannot be enforced, but for
            a consistent rating system we request that you follow them:
          </p>
          <p>
            1. In order to have spent enough time with a product/activity to
            rate it, you should meet one of the following criteria:
            <br /> a) Have spent money on the product eg. paid for a museum
            ticket, bought a game. Even if you later refunded/returned that is
            fine.
            <br /> b) Have spent at least 1 hour with the product eg. watched 1
            hour of a movie or tv show.
            <br /> c) Completed the product in its entirety eg. finished a book
            or listened to a song.
            <br />
            <br />
            2. Ratings should use the full scale of 1 to 10. A rating of 5
            should be considered an average experience for you, not a bad one. A
            rating of 10/10 should be rare and represent your top 10% of
            experiences.
            <br />
            <br />
            Profiles with unbalanced or extreme ratings may be flagged and their
            weightings in overall rankings reduced.
            <br />
            <br />
            3. Do not feel bad for giving a bad rating, in fact it is essential
            for the platform to work. Also no review or justification is needed
            for a low rating.
          </p>
        </div>
      )}

      {activeTab === "Terms" && (
        <div>
          <p>
            Welcome to Rated. By accessing our website and using our services,
            you agree to be bound by these terms and conditions.
            <br />
            <br />
            Users must be at least 18 years old or have legal parental or
            guardian consent to use this site. Users agree not to use the site
            for any illegal or unauthorized purpose.
            <br />
            <br />
            All content on Rated, including text, graphics, logos, and images,
            is the property of Rated or its content suppliers and protected by
            UK and international copyright laws.
            <br />
            <br />
            Users may post content as long as it is not obscene, illegal,
            defamatory, or infringes on intellectual property rights. Rated
            reserves the right to remove any user content that violates these
            terms.
            <br />
            <br />
            Our Privacy Policy, which outlines how we collect and use your
            personal data, is an integral part of these terms.
            <br />
            <br />
            Rated is not liable for any indirect, incidental, or consequential
            damages arising from the use of our services.
            <br />
            <br />
            These terms are governed by the laws of the United Kingdom.
            <br />
            <br />
            Rated reserves the right to modify these terms at any time.
            Continued use of the site after changes indicates acceptance of the
            new terms.
            <br />
            <br />
            Rated uses cookies to enhance user experience. By using Rated, you
            agree to the use of cookies in accordance with this policy.
          </p>
        </div>
      )}

      {activeTab === "FAQ" && (
        <div>
          {faqs.map((item) => (
            <FAQItem item={item} />
          ))}
        </div>
      )}
    </div>
  );
};

export default About;
