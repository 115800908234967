// ScrollToTop.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        // Scroll to the top of the page when location changes
        window.scrollTo(0, 0);
    }, [location]);

    return children;
};

export default ScrollToTop;