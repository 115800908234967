import React, { useState, useEffect, useContext, useMemo } from "react";
import styles from "./ExploreCategories.module.css";
import { Link } from "react-router-dom";
import { UserContext } from "../components/UserContext";
import { useLocation } from "react-router-dom";

function CategoryPage() {
  const [categories, setCategories] = useState([]);
  const { userId } = useContext(UserContext);
  const [categorySearch, setCategorySearch] = useState("");
  const location = useLocation();

  useEffect(() => {
    // Scroll to the top of the page when location changes
    window.scrollTo(0, 0);
  }, [location]);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    async function fetchCategories() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/get_category_info?userId=${userId}`
        );
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    }

    fetchCategories();
  }, []); // Empty dependency array, only run once on mount.

  // useMemo to optimize performance
  const filteredCategories = useMemo(() => {
    const lowercasedFilter = categorySearch.toLowerCase();
    return categories.filter((category) =>
      category.name.toLowerCase().includes(lowercasedFilter)
    );
  }, [categories, categorySearch]); // Dependency on categories and categorySearch

  return (
    <div className={styles.container}>
      {/* <h1 className={styles.page_title}>Categories</h1> */}

      <div className={styles.search_container}>
        <input
          className={styles.category_search}
          placeholder="Search categories"
          value={categorySearch}
          onChange={(e) => setCategorySearch(e.target.value)}
        />
      </div>

      <div className={styles.gallery}>
        {filteredCategories.map((category) => (
          <Link
            key={category.category_id}
            to={`/categories/${category.category_id}`}
            className={styles.cardLink}
          >
            <div className={styles.card}>
              <div className={styles.cardTop}>
                <h3 className={styles.categoryName}>{category.name}</h3>
                <img
                  src={category.icon_url}
                  alt={category.name}
                  className={styles.categoryIcon}
                />
              </div>
              <img
                src={category.poster_url}
                alt={category.name}
                className={styles.card_image}
              />
              {/* <div className={styles.card_middle}>
                <h4 className={styles.categoryStat}>
                  {numberWithCommas(category.total_products)} Entries{" "}
                </h4>
                <h4 className={styles.categoryStat}>
                  {numberWithCommas(category.total_ratings)} Ratings{" "}
                </h4>
                {userId && (
                  <h4 className={styles.categoryStat}>
                    {numberWithCommas(category.total_ratings_by_user)} By You
                  </h4>
                )}
              </div> */}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default CategoryPage;
