import React, { useState, useContext } from "react";
import styles from "./ReportModal.module.css";
import { UserContext } from "../components/UserContext";

const ReportModal = ({
  isOpen,
  onClose,
  onSubmit,
  product_id = null,
  profile_id = null,
}) => {
  const [reason, setReason] = useState("");
  const { userId } = useContext(UserContext);
  const apiUrl = process.env.REACT_APP_BACKEND_URL;

  // Handle the submit action
  const handleSubmit = () => {
    onSubmit();
    if (reason.trim()) {
      const payload = {
        user_id: userId,
        profile_id: profile_id,
        product_id: product_id,
        timestamp: new Date(),
        comment: reason || "Reported user", // Use the entered reason or a default
      };

      // Handle sending the payload to your backend or state management here
      fetch(`${apiUrl}/submit_report`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
    } else {
      alert("Please provide a reason for reporting.");
    }
  };

  // Handle the cancel action
  const handleCancel = () => {
    setReason(""); // Reset input
    onClose(); // Close the modal
  };

  // Only render the modal when it is open
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay} onClick={handleCancel}>
      <div className={styles.modalContainer} onClick={(e) => e.stopPropagation()}>
        <h3>Please give a reason for your report</h3>
        <textarea
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          rows="4"
          placeholder="Describe the issue..."
          className={styles.reportInput}
        />
        <div className={styles.modalButtons}>
          <button onClick={handleCancel} className={styles.cancel}>
            Cancel
          </button>
          <button onClick={handleSubmit} className={styles.submit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportModal;
