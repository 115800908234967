import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "./Notifications.module.css"; // Adjust path as necessary
import { formatDistanceToNow } from "date-fns";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../components/UserContext";

const Notifications = () => {
  const [search, setSearch] = useState("");
  const [messageList, setMessageList] = useState([]);
  const { userId, setNotificationCount, setMessageCount } =
    useContext(UserContext);
  const apiUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const [filteredMessages, setFilteredMessages] = useState([]);

  useEffect(() => {
    async function fetchMessages() {
      try {
        const response = await fetch(
          `${apiUrl}/get_all_notifications?user_id=${userId}`
        );
        const data = await response.json();
        data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        setMessageList(data);
        setFilteredMessages(data);
        setNotificationCount(0);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    }
    fetchMessages();
  }, [userId, apiUrl]);

  useEffect(() => {
    const filtered = messageList.filter((message) =>
      message.title.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredMessages(filtered);
  }, [search, messageList]);

  const handleWebLink = (deepLinkUrl) => {
    // Convert URL to path by removing the scheme
    const route = deepLinkUrl.replace(/.*?:\/\//g, "");
    const routeParts = route.split("/");
    const path = routeParts[0];
    console.log("Route:", route, "Path:", path, "Parts:", routeParts);

    // Profiles section
    if (path === "profiles" && routeParts.length > 2) {
      // Extract profile_id
      const profile_id = parseInt(routeParts[2], 10);
      // Extract rating_id if present
      const rating_id =
        routeParts.length > 3 ? parseInt(routeParts[4], 10) : null;

      console.log("Profile ID:", profile_id, "Rating ID:", rating_id);

      // Ensure that profile_id is an integer and rating_id is either null or an integer
      if (
        Number.isInteger(profile_id) &&
        (rating_id === null || Number.isInteger(rating_id))
      ) {

        // Navigate based on profile ownership and rating presence
        if (profile_id == userId) {
          // Current user's profile
          if (rating_id !== null) {
            navigate(`/MyProfile/${rating_id}`);
          } else {
            navigate("/MyProfile");
          }
        } else {
          // Other user's profile
          if (rating_id !== null) {
            navigate(`/profiles/${profile_id}/rating/${rating_id}`);
          } else {
            navigate(`/profiles/${profile_id}`);
          }
        }
      }
    }
    // Products section
    else if (path === "products" && routeParts.length > 1) {
      // Extract product_id
      const product_id = parseInt(routeParts[1], 10);

      // Navigate to product page if the product ID is valid
      if (Number.isInteger(product_id)) {
        navigate(`/products/${product_id}`);
      }
    }
    // Messages section
    else if (path === "messages" && routeParts.length > 2) {
      // Extract user_id
      const user_id = parseInt(routeParts[2], 10);

      // Navigate to messages page if the user ID is valid
      if (Number.isInteger(user_id)) {
        navigate(`/messages/${user_id}`);
      }
    } else {
      // If no specific path is matched, return null to remain in the current location
      console.log("No match");
      return null;
    }
  };

  const renderMessage = ({ item }) => (
    <div
      onClick={() => handleWebLink(item.deeplink)}
      className={styles.messageItem}
      key={item.notification_id}
    >
      <div className={styles.imageContainer}>
        <img src={item.image} alt="" className={styles.profilePhoto} />
        <p className={styles.timestamp}>
          {formatDistanceToNow(new Date(item.timestamp), {
            addSuffix: true,
          })
            .replace("about ", "")
            .replace("over 54 years ago", "")}
        </p>
      </div>
      <div className={styles.messageContent}>
        <span className={styles.name}>{item.title}</span>
      </div>
      <div className={styles.statusIndicator}>
        {item.read === 0 && <div className={styles.unreadDot}></div>}
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <div className={styles.searchHeader}>
        <input
          className={styles.searchBar}
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search Notifications"
        />
      </div>

      {filteredMessages.length === 0 && (
        <div className={styles.noMessages}>
          <p>No notifications received </p>
        </div>
      )}

      <div style={{ overflowY: "auto" }}>
        {filteredMessages.map((message) => renderMessage({ item: message }))}
      </div>
    </div>
  );
};

export default Notifications;
