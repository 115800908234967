import React, { useState, useRef } from 'react';
import styles from './ImageUpload.module.css';

const ImageUpload = ({ onFileUploaded, linkName1 = 'Add Photo', linkName2 = 'Photo Added' }) => {
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const fileInputRef = useRef(null);

  const onFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      await uploadFile(selectedFile);
    }
    // Reset the file input
    event.target.value = null;
  };

  const getPresignedUrl = async (fileName) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/get_presigned_url?file_name=${encodeURIComponent(fileName)}`
      );
      const data = await response.json();
      return { presignedUrl: data.presigned_url, accessUrl: data.access_url };
    } catch (error) {
      console.error("Error getting presigned URL:", error);
      return null;
    }
  };

  const uploadFile = async (file) => {
    const fileInfo = await getPresignedUrl(file.name);
    if (!fileInfo || !fileInfo.presignedUrl) {
      alert("Could not get the presigned URL");
      return;
    }

    const uploadResponse = await fetch(fileInfo.presignedUrl, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": file.type,
      },
    });

    if (uploadResponse.ok) {
      console.log("File uploaded, Access URL:", fileInfo.accessUrl);
      onFileUploaded(fileInfo.accessUrl);
      setIsFileUploaded(true); // Update the state here
    } else {
      console.error(
        "Failed to upload file. Status:",
        uploadResponse.status,
        "Status Text:",
        uploadResponse.statusText
      );
      alert("Failed to upload file.");
    }
  };

  const triggerFileInput = (event) => {
    event.stopPropagation();
    event.preventDefault();
    fileInputRef.current.click();
  };

  return (
    <form className={styles.image_form}>
      <input
        type="file"
        ref={fileInputRef}
        accept=".png, .jpg, .jpeg"
        onChange={onFileChange}
        className={styles.hidden_input}
      />
      <p onClick={(event) => triggerFileInput(event)} className={styles.upload_link}>
        {isFileUploaded ? linkName2 : linkName1}
      </p>
    </form>
  );
};

export default ImageUpload;