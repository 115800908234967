import React, { useState, useEffect, useContext, useRef } from "react";
import {
  InfiniteLoader,
  AutoSizer,
  Grid,
  List,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import ReactSlider from "react-slider";
import "./App.css";
import { Link, useNavigate } from "react-router-dom";
import RatingDropdown from "../components/RatingDropdown";
import { UserContext } from "../components/UserContext";
import ReviewModal from "../components/ReviewModal";
import RatingsGallery from "../components/RatingsGallery";
import Dropdown from "../components/Dropdown";
import DropdownTags from "../components/DropdownTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faUsers,
  faUserPlus,
  faArrowRight,
  faX,
  faChevronLeft,
  faChevronRight,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./RatingPage.module.css";
import Tutorial from "../components/Tutorial";

function RatingPage() {
  const [ratings, setRatings] = useState([]);
  const [category, setCategory] = useState("None");
  const [relationship, setRelationship] = useState("Following");
  const [ratingRange, setRatingRange] = useState([1, 10]);
  const [ratingRangeDisplay, setRatingRangeDisplay] = useState([1, 10]); // Adjusted for two-tailed slider
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const {
    userId,
    ratingCount,
    followingCount,
    username,
    productCount,
    setProductCount,
    productIds,
    setProductIds,
    setFollowingCount,
    setShowLoginPopup,
    users,
    setUsers,
  } = useContext(UserContext);
  const listRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userFilter, setUserFilter] = useState([]);
  const [modalContent, setModalContent] = useState("");
  const [modalProduct, setModalProduct] = useState(""); // New line added here
  const [modalTopPosition, setModalTopPosition] = useState(0);
  {
    /*const [selectedCategories, setSelectedCategories] = useState(["All"]);
  const [selectedTags, setSelectedTags] = useState(["All"]); */
  }
  const [isCategoryDropdownOpen, setCategoryDropdownOpen] = useState(false);
  const [isTagDropdownOpen, setTagDropdownOpen] = useState(false);
  const categoryDropdownRef = useRef(null);
  const tagDropdownRef = useRef(null);
  const categoryButtonRef = useRef(null);
  const tagButtonRef = useRef(null);
  const [modalProductName, setModalProductName] = useState("");
  const [modalSecondaryInfo, setModalSecondaryInfo] = useState("");
  const [modalUserPhoto, setModalUserPhoto] = useState("");
  const [modalUsername, setModalUsername] = useState("");
  const [modalProductId, setModalProductId] = useState("");
  const [modalReview, setModalReview] = useState("");
  const [modalRating, setModalRating] = useState("");
  const [modalIcon, setModalIcon] = useState("");
  const [ratedOnly, setRatedOnly] = useState(false);
  const [unratedOnly, setUnratedOnly] = useState(false);
  const [reviewOnly, setReviewOnly] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState([]);
  // const [showFilter, setShowFilter] = useState(true);
  const navigate = useNavigate();
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [homeProducts, setHomeProducts] = useState([]);
  const [homeUsers, setHomeUsers] = useState([]);
  const [startup, setStartup] = useState(true);
  const [everyone, setEveryone] = useState(false);
  const [empty, setEmpty] = useState(false);

  const handleShareToApps = () => {
    const profileUrl = `https://www.rated.fun/Welcome/${username}`;
    navigator.clipboard
      .writeText(profileUrl)
      .then(() => {
        setShowCopyMessage(true);
        setTimeout(() => setShowCopyMessage(false), 2000); // Message disappears after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const unknownImg =
    "https://s3.eu-west-2.amazonaws.com/rated.images/unknown.png";

  const [selectedCategories, setSelectedCategories] = useState(() => {
    const savedCategories = localStorage.getItem("selectedCategories");
    return savedCategories ? JSON.parse(savedCategories) : [];
  });

  const [showFilter, setShowFilter] = useState(() => {
    const savedFilter = localStorage.getItem("savedFilter");
    // Ensure the value is correctly parsed as a boolean
    return savedFilter ? JSON.parse(savedFilter) : false;
  });

  useEffect(() => {
    if (window.innerWidth < 1000) {
      navigate("/WelcomeM");
    }
  }, [navigate]);

  useEffect(() => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    console.log("User-Agent:", navigator.userAgent);
    
    if (isMobile) {
      navigate("/WelcomeM");
    }
  }, [navigate]);

  const [selectedTags, setSelectedTags] = useState(() => {
    const savedTags = localStorage.getItem("selectedTags");
    return savedTags ? JSON.parse(savedTags) : [];
  });

  const toggleSelection = (prevSelection, itemId) => {
    // If 'All' is selected, clear everything else or set to just 'All'
    if (itemId === "All") {
      return prevSelection.includes("All") ? [] : ["All"];
    }
    // If any other item is selected, remove 'All'
    else {
      const updatedSelection = prevSelection.includes(itemId)
        ? prevSelection.filter((id) => id !== itemId)
        : [...prevSelection, itemId];

      return updatedSelection.filter((id) => id !== "All");
    }
  };

  // Save preferences whenever selectedCategories or selectedTags change
  useEffect(() => {
    localStorage.setItem(
      "selectedCategories",
      JSON.stringify(selectedCategories)
    );
    localStorage.setItem("selectedTags", JSON.stringify(selectedTags));

    localStorage.setItem("savedFilter", showFilter);
  }, [selectedCategories, selectedTags, showFilter]);


  const handleLike = (userId, item) => {
    if (!userId) {
      return;
    }

    const ratingId = item.rating_id;
    const like = item.user_liked ? true : false;
    const payload = {
      user_id: userId,
      rating_id: ratingId,
      like: !like,
    };

    fetch(`${process.env.REACT_APP_BACKEND_URL}/like_rating`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    // Update the state with the modified products array.
    const updatedRatings = ratings.map((rating) => {
      if (rating.rating_id === ratingId) {
        return {
          ...rating,
          user_liked: !like,
          likes: !like ? rating.likes + 1 : rating.likes - 1,
        };
      }
      return rating;
    });

    setRatings(updatedRatings);
  };

  const handleRatingChange = (productId, newRating) => {
    // This will capture the ratingId of the updated rating.
    let updatedRatingId = null;

    // Handle success case
    // Find the updated product and change its user_rating.
    const updatedRatings = ratings.map((rating) => {
      if (rating.product_id === productId) {
        updatedRatingId = rating.rating_id; // Capture the ratingId
        return {
          ...rating,
          user_rating: newRating, // Update the user_rating.
        };
      }
      return rating; // Leave all other products unchanged.
    });

    // Update the state with the modified products array.
    setRatings(updatedRatings);

    // If we found a matching rating, record the interaction.
    if (updatedRatingId !== null) {
      addInteraction(updatedRatingId);
    }
  };

  const addInteraction = (ratingId) => {
    const payload = {
      rating_id: ratingId,
    };

    fetch(`${process.env.REACT_APP_BACKEND_URL}/add_interaction`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
  };

  const handleRefresh = () => {
    scrollToTop();
    setRefresh(!refresh);
  };

  const [refresh, setRefresh] = useState(false);
  const scrollViewRef = useRef(null);
  const scrollToTop = () => {
    //scrollViewRef.current?.scrollIntoView({ behavior: "smooth" });
    window.scrollTo(0, 650);
  };

  const toggleCategory = (categoryId) => {
    setSelectedCategories((prev) => toggleSelection(prev, categoryId));
  };

  const toggleTag = (tagId) => {
    setSelectedTags((prev) => toggleSelection(prev, tagId));
  };

  const handleReviewClick = (modalProduct) => {
    const scrollY = window.scrollY;
    setModalTopPosition(scrollY);
    setModalProduct(modalProduct);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setModalProduct("");
    setIsModalOpen(false);
  };

  const handleModalSubmit = () => {
    setModalProduct("");
    setIsModalOpen(false);
  };

  const cache = new CellMeasurerCache({
    defaultHeight: 700, // default height
    fixedWidth: true,
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        categoryDropdownRef.current &&
        !categoryDropdownRef.current.contains(event.target) &&
        categoryButtonRef.current &&
        !categoryButtonRef.current.contains(event.target)
      ) {
        setCategoryDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        tagDropdownRef.current &&
        !tagDropdownRef.current.contains(event.target) &&
        tagButtonRef.current &&
        !tagButtonRef.current.contains(event.target)
      ) {
        setTagDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchHomeUsers = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/home_users?userId=${userId}`
        );
        const data = await response.json();
        setHomeUsers(data);
        setUsers(data);
      } catch (error) {
        console.error("Error fetching home users:", error);
      }
    };

    fetchHomeUsers();
  }, [userId]);

  useEffect(() => {
    async function fetchTags() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/get_new_tags?userId=${userId}`
        );
        const response2 = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/get_all_categories`
        );
        const data = await response.json();
        const data2 = await response2.json();
        if (data.length === 0) {
          data.push("Following");
        }
        setTags(data);
        setCategories(data2);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    }

    fetchTags();
  }, []); // Empty dependency array, only run once on mount.

  useEffect(() => {
    async function fetchRatings() {
      const tagsArray = Array.isArray(selectedTags)
        ? selectedTags
        : selectedTags.split(",");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/get_ratings?category_ids=${selectedCategories}&relationships=${tagsArray}&min_rating=${ratingRange[0]}&max_rating=${ratingRange[1]}&page=${currentPage}&user_id=${userId}&isRated=${ratedOnly}&isUnrated=${unratedOnly}&hasReview=${reviewOnly}`,
          {
            headers: {
              "Cache-Control": "no-store",
            },
          }
        );

        const ratings = await response.json();
        console.log(ratings);
        setRatings(ratings);
        setError(null);
        setHasMore(ratings.length > 0);
      } catch (error) {
        console.error("Error fetching products:", error);
        setError("Failed to fetch products.");
      }
      if (listRef.current) {
        listRef.current.scrollToRow(0);
      }
    }
    setRatings([]);
    setCurrentPage(1);

    fetchRatings();
  }, [
    category,
    relationship,
    ratingRange,
    selectedCategories,
    selectedTags,
    ratedOnly,
    unratedOnly,
    reviewOnly,
  ]); // Dependency on category, relationship, and ratingRange

  const loadMoreRows = async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/get_ratings?category_ids=${selectedCategories}&relationships=${selectedTags}&min_rating=${
          ratingRange[0]
        }&max_rating=${ratingRange[1]}&page=${
          currentPage + 1
        }&user_id=${userId}&isRated=${ratedOnly}&isUnrated=${unratedOnly}&hasReview=${reviewOnly}`
      );

      if (!response.ok) throw new Error("Network response was not ok");

      const newRatings = await response.json();
      setRatings((prev) => [...prev, ...newRatings]);
      setCurrentPage((prev) => prev + 1);
      setHasMore(newRatings.length > 0);
    } catch (error) {
      console.error("Error fetching more products:", error);
      setError("Failed to fetch more products.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (productCount === -1) {
      return;
    }
    if (startup) {
      return;
    }
    console.log("Product Count:", productCount);
    console.log("Product Ids Length:", productIds.length);
    if ((productIds.length - productCount <= 3) & (productCount > 0)) {
      fetchProductIds();
      console.log("Fetching more products");
    }
  }, [productCount]);

  useEffect(() => {
    console.log("Resetting product ids");
    setProductIds([]);
    setProductCount(-1);
    fetchProductIds();
  }, [selectedCategories, userId]);

  const fetchProductIds = async () => {
    try {
      console.log("Fetching product ids");
      const productIdsString = productIds
        .map((product) => product.product_id)
        .join(",");
      const categoriesString =
        selectedCategories && selectedCategories.length > 0
          ? selectedCategories.join(",")
          : "";
      const response = await fetch(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/rapid_product_ids?user_id=${userId}&everyone=${everyone}&category_ids=${categoriesString}&excluded_product_ids=${productIdsString}&latitude=${null}&longitude=${null}&new=${1}&startup=${
          startup ? "1" : "0"
        }`
      );
      const data = await response.json();
      if (data.length === 0) {
        setEmpty(true);
        console.log("Empty Data!!!");
        if (productCount === -1) {
          setProductCount(0);
        }
        return;
      }
      setHomeProducts(data);
      setProductIds((prevIds) => {
        // Combine previous IDs and new IDs
        const combinedIds = [...prevIds, ...data];
        // Convert combined array to a Set to remove duplicates, then convert it back to an array
        const uniqueIds = Array.from(new Set(combinedIds));
        return uniqueIds;
      });

      if (productCount === -1) {
        setProductCount(0);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setStartup(false);
  };

  const handleUserSkip = async (profile_id) => {
    if (!userId) {
      setShowLoginPopup(true);
      return;
    }
    const updatedHomeUsers = homeUsers.filter(
      (user) => user.user_id !== profile_id
    );
    setHomeUsers(updatedHomeUsers);

    // Define the payload
    const payload = {
      user_id: userId,
      profile_id: profile_id,
    };

    // Send the POST request
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/skip_user`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      // Check if the POST request was successful
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error("Failed to remove user", error);
    }
  };

  const handleUserFollow = async (profile_id) => {
    if (!userId) {
      setShowLoginPopup(true);
      return;
    }
    // Update the state immediately for a responsive UI
    const updatedHomeUsers = homeUsers.map((user) => {
      if (user.user_id === profile_id) {
        return {
          ...user,
          following: true,
        };
      }
      return user;
    });
    setHomeUsers(updatedHomeUsers);

    // Define the payload
    const payload = {
      user_id: userId,
      profile_id: profile_id,
      relationship: "Following",
    };

    // Send the POST request
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/add_usertag`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      // Check if the POST request was successful
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // You may want to do something with the response here
      const data = await response.json();

      // Optionally, confirm the change happened on the backend
      // and update the state again if necessary
    } catch (error) {
      console.error("Failed to send follow", error);
      // Revert the state update if necessary
      const revertedHomeUsers = homeUsers.map((user) => {
        if (user.user_id === userId) {
          return {
            ...user,
            following: false,
          };
        }
        return user;
      });

      console.log("Following Count:", followingCount);
      setHomeUsers(revertedHomeUsers);
    }
    setFollowingCount(followingCount + 1);
  };

  useEffect(() => {
    if (startup) {
      return;
    }
    if (followingCount == 1) {
      setRefresh(!refresh);
    }
    if (followingCount % 5 == 0) {
      setRefresh(!refresh);
    }
  }, [followingCount]);

  const handleRatedChange = (event) => {
    setRatedOnly(event.target.checked);
  };

  const handleUnratedChange = (event) => {
    setUnratedOnly(event.target.checked);
  };

  const handleReviewedChange = (event) => {
    setReviewOnly(event.target.checked);
  };

  return (
    <div className={styles.container}>
      <div>
        <h2 className={styles.contentHeader}>
          {followingCount > 0 ? "POPULAR IN YOUR NETWORK" : "POPULAR ON RATED"}
        </h2>
        <div className={styles.productScroll}>
          {homeProducts.slice(0, 5).map((product, index) => (
            <div
              key={product.product_id}
              className={styles.productBox}
              style={{ height: userId ? 320 : 290 }}
            >
              <div>
                <div className={styles.productImages}>
                  <Link to={`/products/${product.product_id}`}>
                    <img
                      src={product.image_url || unknownImg}
                      alt={product.name}
                      className={styles.productImage}
                    />
                  </Link>
                  <img
                    src={product.icon_url}
                    alt={product.name}
                    className={styles.categoryIcon}
                  />
                </div>
                <p className={styles.productTitle}>{product.name}</p>
                {userId && product.predicted_rating && (
                  <div className={styles.predictedContainer}>
                    <p className={styles.productRatingText}>
                      Predicted: {product.predicted_rating}
                    </p>
                    <FontAwesomeIcon icon={faStar} size="24px" color="purple" />
                  </div>
                )}
                {userId && !product.predicted_rating && (
                  <div className={styles.predictedContainer2}>
                    <p className={styles.productRatingText2}>
                      Rate more to get personal predictions
                    </p>
                  </div>
                )}
              </div>
            </div>
          ))}
          <div className={styles.arrowContainer}>
            <FontAwesomeIcon
              className={styles.moreArrow}
              icon={faChevronRight}
              size="32px"
              color="white"
              onClick={() => {
                const lastProductIndex = homeProducts.length - 1;
                setProductCount(lastProductIndex);
                navigate(
                  `/products/${homeProducts[lastProductIndex].product_id}`
                );
              }}
            />
          </div>
        </div>
      </div>

      <div className={styles.contentContainer}>
        <h2 className={styles.contentHeader2}>SUGGESTED PEOPLE</h2>
        <div className={styles.productScroll}>
          {homeUsers.slice(0, 5).map((user) => (
            <div
              key={user.user_id}
              className={userId ? styles.box : styles.box2}
            >
              <div
                className={styles.userClose}
                onClick={() => handleUserSkip(user.user_id)}
              >
                <FontAwesomeIcon icon={faX} size="12px" color="white" />
              </div>
              <div>
                <Link to={`/profiles/${user.user_id}`}>
                  <img
                    src={user.photo_url || unknownImg}
                    alt={user.username}
                    className={styles.userImage}
                  />
                </Link>
              </div>
              <p className={styles.userTitle}>{user.username}</p>
              {user.mutual_friend_count > 0 ? (
                <p className={styles.similarityText}>
                  {user.mutual_friend_count === 1
                    ? "1 Mutual Friend"
                    : `${user.mutual_friend_count} Mutual Friends`}
                </p>
              ) : (
                <p className={styles.similarityText}>
                  {user.number_ratings} Ratings
                </p>
              )}

              {user.similarity > 0 ? (
                <p className={styles.similarityText}>
                  {user.similarity}% Similarity
                </p>
              ) : userId ? (
                <p className={styles.similarityText2}>
                  {user.similarity}% Similarity
                </p>
              ) : null}

              {user.following ? (
                <div className={styles.followButton}>
                  <p className={styles.followText}>Following</p>
                </div>
              ) : (
                <div
                  className={styles.followButton}
                  onClick={() => handleUserFollow(user.user_id)}
                >
                  <p className={styles.followText}>Follow</p>
                </div>
              )}
            </div>
          ))}
          <div className={styles.arrowContainer}>
            <FontAwesomeIcon
              className={styles.moreArrow}
              icon={faChevronRight}
              size="32px"
              color="white"
              onClick={() => {
                navigate(
                  `/ExploreUsers`
                );
              }}
            />
          </div>
        </div>
      </div>

      {userId && followingCount === 0 ? (
        <div className={styles.noRatingsContainer}>
          <p className={styles.noRatingsText}>
            Once you follow users, their ratings will appear in your feed.
            <br />
            <br />
            Head over to the Users Page to follow similar users or search for
            your friends.
            <br />
            <br />
            You can also invite your friends to join the app!
          </p>
          <div className={styles.friendOptions}>
            <div
              className={styles.usersOption}
              onClick={() => navigate("/ExploreUsers")}
            >
              <FontAwesomeIcon
                icon={faUsers}
                size={64}
                color={"white"}
                className={styles.inviteIcon}
              />
              <p className={styles.inviteText}>View Users</p>
            </div>
            <div className={styles.usersOption} onClick={handleShareToApps}>
              <FontAwesomeIcon
                icon={faUserPlus}
                size={64}
                color={"white"}
                className={styles.inviteIcon}
              />
              <p className={styles.inviteText}>Invite Friends</p>
            </div>
          </div>

          {showCopyMessage && (
            <div className={styles.copyMessage}>Link copied to clipboard!</div>
          )}
        </div>
      ) : (
        <div>
          {error && <div className="error">{error}</div>}{" "}
          {/* Displaying Error */}
          <h1 className={styles.page_title}></h1>
          {/* <div
            className={styles.filterContainer}
            onClick={() => {
              scrollToTop();
              setShowFilter(!showFilter);
            }}
          >
            <FontAwesomeIcon icon={faFilter} className={styles.filterButton} />
          </div> */}
          {showFilter && (
            <div className={styles.ratingFilters}>
              <div className={styles.dropdownFilters}>
                <Dropdown
                  categories={categories}
                  selectedCategories={selectedCategories}
                  onCategoryChange={setSelectedCategories}
                  icon={true}
                  name={"Categories"}
                />
                <DropdownTags
                  categories={tags}
                  selectedCategories={selectedTags}
                  onCategoryChange={setSelectedTags}
                  name={"User Tags"}
                />

                <div className={styles.checkboxFilters}>
                  <div className={styles.checkboxContainer}>
                    <input
                      type="checkbox"
                      checked={ratedOnly}
                      onChange={handleRatedChange}
                    />
                    Rated
                  </div>
                  <div className={styles.checkboxContainer}>
                    <input
                      type="checkbox"
                      checked={unratedOnly}
                      onChange={handleUnratedChange}
                    />
                    Unrated
                  </div>
                  <div className={styles.checkboxContainer}>
                    <input
                      type="checkbox"
                      checked={reviewOnly}
                      onChange={handleReviewedChange}
                    />
                    Reviewed
                  </div>
                </div>
              </div>

              <ReactSlider
                className={styles.ratingSlider}
                thumbClassName={styles.thumb}
                trackClassName={styles.track}
                defaultValue={[ratingRangeDisplay[0], ratingRangeDisplay[1]]}
                min={1}
                max={10}
                step={1}
                pearling
                onAfterChange={(values) => {
                  setRatingRange(values);
                }}
                onChange={(values) => {
                  setRatingRangeDisplay(values);
                }}
              />
              <div className={styles.ratingRangeLabel}>
                Rating Range: {ratingRangeDisplay[0]} - {ratingRangeDisplay[1]}
              </div>
            </div>
          )}
          <div className={styles.ratingGallery}>
            <div className={styles.feedHeader}>
              <h2 ref={scrollViewRef} className={styles.contentHeader3}>
                YOUR FEED
              </h2>
              <FontAwesomeIcon
                onClick={() => {
                  scrollToTop();
                  setShowFilter(!showFilter);
                }}
                icon={faFilter}
                className={styles.filterIconNew}
              />
            </div>

            <RatingsGallery
              ratings={ratings}
              loading={loading}
              userId={userId}
              handleRatingChange={handleRatingChange}
              loadMoreRows={loadMoreRows}
              unknownImg={unknownImg}
              showTitle={false}
              handleLike={handleLike}
              hasMore={hasMore}
              setRatings={setRatings}
              screen={"Home"}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default RatingPage;
