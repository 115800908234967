import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Search from "./Search";
import styles from "./Header.module.css";
import Login from "./Login";
import {
  FaInfoCircle,
  FaUserFriends,
  FaLayerGroup,
  FaStar,
  FaHome,
  FaEnvelope,
  FaSearch,
  FaCog,
  FaBell,
  FaScroll,
  FaUsers,
} from "react-icons/fa";

const Header = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.header}>
      <div className={styles.logo}>
        <Link to="/">
          <img
            src="https://s3.eu-west-2.amazonaws.com/rated.images/rated_logo_6bg.png"
            alt="Logo"
            className={styles.logoImage}
          />
        </Link>
      </div>
      <div className={styles.headerSearchContainer}>
        <Search
          className={styles.headerSearch}
          onProductClick={(product) => {
            navigate(`/products/${product.product_id}`);
          }}
        />
      </div>
      <div className={styles.headerProfile}>
        <Link to="/Messages" className={styles.navItem}>
          <FaEnvelope className={styles.navIcon} />
        </Link>

        <Link to="/Notifications" className={styles.navItem}>
          <FaBell className={styles.navIcon} />
        </Link>

        <div className={styles.loginContainer}>
        <Login header={true} />
        </div>
      </div>
    </div>
  );
};

export default Header;
