import React, { useState, useEffect } from "react";
import styles from "./ReviewModal.module.css";
import { formatDistanceToNow } from "date-fns";

const ReviewModal = ({
  style,
  isOpen,
  onClose,
  productName,
  secondaryInfo,
  userPhoto,
  username,
  onSubmit,
  userId,
  type,
  productId,
  review,
  rating,
  categoryIcon,
  timestamp = null,
}) => {
  const [reviewText, setReviewText] = useState(review);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && isOpen) {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const handleSubmit = () => {
    onSubmit(productId, userId, reviewText);
    onClose();
  };

  // This function will close the modal when clicking on the overlay
  const handleOverlayClick = () => {
    onClose();
  };

  // This function stops the click event from propagating up to the overlay
  const handleModalClick = (event) => {
    event.stopPropagation();
  };

  if (!isOpen) return null;

  return (
    <div className="modalWrapper" style={style}>
      <div className={styles.modal_overlay} onClick={handleOverlayClick}>
        <div className={styles.modal} onClick={handleModalClick}>
          <div className={styles.header}>
            <img
              src={categoryIcon}
              alt={productName}
              className={styles.categoryIcon}
            />

            <div className={styles.header_text}>
              <h2>
                {productName} ({secondaryInfo})
              </h2>
            </div>

            <h1>{rating}</h1>
          </div>
          <textarea
            value={type === "self" ? reviewText : review}
            onChange={(e) => setReviewText(e.target.value)}
            placeholder="Write your review here..."
            readOnly={type !== "self"}
          />
          <div className={styles.footer}>
            <div className={styles.user_info}>
              <img src={userPhoto} alt={username} />
              <div>
                <div className={styles.username}>@{username}</div>
                {timestamp && (
                <div className={styles.timestamp}>
                  {formatDistanceToNow(timestamp, {
                    addSuffix: true,
                  }).replace("about ", "")}
                </div>
                )}
              </div>
            </div>
            <div className={styles.footer_buttons}>
              {type === "self" && (
                <button onClick={handleSubmit}>Submit</button>
              )}
              <button onClick={onClose}>Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewModal;
