import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Register.module.css";
import { UserContext } from "../components/UserContext";
import ImageUpload from "../components/ImageUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faEye,
  faEyeSlash,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import SimpleDropdown from "../components/SimpleDropdown";
import SimpleDropdownSearch from "../components/SimpleDropdownSearch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Register() {
  const [username, setUsername2] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [countries, setCountries] = useState([]);
  const [country1, setCountry1] = useState("");
  const [country2, setCountry2] = useState("");
  const [photoUrl2, setPhotoUrl2] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [usernames, setUsernames] = useState([]); // New state for usernames
  const [bio, setBio] = useState("");
  const [email, setEmail] = useState("");
  const [privacy, setPrivacy] = useState("Public");
  const navigate = useNavigate();
  const [timestamp, setTimestamp] = useState(new Date().toISOString());
  const { setUserId, setUsername, setPhotoUrl } = useContext(UserContext);
  const [referrer, setReferrer] = useState("");
  const [usernameValidationMessage, setUsernameValidationMessage] =
    useState("");

  const [year, setYear] = useState(null);
  const [datePicked, setDatePicked] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);

  const [genderOptions, setGenderOptions] = useState([
    "Other/Prefer Not to Say",
    "Female",
    "Male",
  ]);
  const [privacyOptions, setPrivacyOptions] = useState([
    "Public",
    "Friends Only",
    "Private",
    "Hidden",
  ]);

  const [customGender, setCustomGender] = useState("");

  const handleGenderChange = (newTag) => {
    setGender(newTag);
  };

  const handlePrivacyChange = (newTag) => {
    setPrivacy(newTag);
  };

  const handleDateSelect = (date) => {
    setDateOfBirth(date);
    setDatePicked(true);
  };

  const handleDateChange = (date) => {
    setDateOfBirth(date);
    setDatePicked(true);
  };

  const handleBackClick = () => {
    navigate(-1); // This takes the user back to the previous page
  };

  useEffect(() => {
    if (dateOfBirth) {
      const newYear = new Date(dateOfBirth).getFullYear();
      setYear(newYear);
    }
  }, [dateOfBirth]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/get_countries`
        );
        const response2 = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/get_usernames`
        );
        const data = await response.json();
        const data2 = await response2.json();
        setCountries(data);
        setUsernames(data2);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    }

    fetchData();
  }, []);

  const handleUsernameBlur = () => {
    if (usernames.includes(username)) {
      setUsernameValidationMessage("This username is already taken!");
    } else if (username) {
      // Check if username is not empty before setting accepted message
      setUsernameValidationMessage("");
    } else {
      setUsernameValidationMessage("");
    }
  };

  const onFileUploaded = (url) => {
    setPhotoUrl2(url);
  };

  const handleSubmit = async () => {
    // You can add more checks for other fields too.

    if (password !== passwordConfirm) {
      alert("Passwords do not match!");
      return;
    }

    if (year < 1900 || year > 2023) {
      alert("Please give a sensible year of birth!");
      return;
    }

    if (!username || !password || !email) {
      alert("Please fill out all the required fields!");
      return;
    }

    const payload = {
      username,
      email,
      timestamp,
      password,
      firstName,
      lastName,
      gender,
      dateOfBirth,
      country1,
      country2,
      photoUrl: photoUrl2,
      privacy,
      bio,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/add_user`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();
      if (data.status === "success") {
        setUserId(data.user_id); // Assuming you have access to setUserId from UserContext
        setUsername(data.username || username);
        setPhotoUrl(data.photo_url || photoUrl2);
        navigate("/RapidRate", {
          state: {
            tutorial: true, // Example state value
          },
        });
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.error("Error registering the user:", error);
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.page_title}>Register</h1>

      <div className={styles.backIconContainer} onClick={handleBackClick}>
        <FontAwesomeIcon icon={faArrowLeft} className={styles.backArrow} />
      </div>

      <div className={styles.registerForm}>
        <div className={styles.inputGroup}>
          <label className={styles.text}>Username*</label>
          <input
            className={styles.input}
            value={username}
            onChange={(e) => setUsername2(e.target.value)}
            onBlur={handleUsernameBlur}
            placeholder="Usernames must be unique. Use your real name or a nickname"
          />
          {usernameValidationMessage && (
            <div className={styles.usernameError}>
              {usernameValidationMessage}
            </div>
          )}
        </div>

        <div className={styles.inputGroup}>
          <label className={styles.text}>Password*</label>
          <input
            className={styles.input}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Must contain a capital letter and number"
          />
        </div>

        <div className={styles.inputGroup}>
          <label className={styles.text}>Confirm Password*</label>
          <input
            className={styles.input}
            type="password"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
            placeholder="Confirm Password"
          />
        </div>

        <div className={styles.inputGroup}>
          <label className={styles.text}>Email*</label>
          <input
            className={styles.input}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
        </div>

        <div className={styles.inputGroup}>
          <label className={styles.text}>First Name</label>
          <input
            className={styles.input}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="First Name"
          />
        </div>

        <div className={styles.inputGroup}>
          <label className={styles.text}>Last Name</label>
          <input
            className={styles.input}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Last Name"
          />
        </div>

        <div className={styles.inputGroup}>
          <label className={styles.text}>Date of Birth*</label>
          <DatePicker
            selected={dateOfBirth}
            onSelect={handleDateSelect} //when day is clicked
            onChange={handleDateChange} //only when value has changed
            showYearDropdown
            dateFormat="dd/MM/yyyy"
            scrollableYearDropdown
            yearDropdownItemNumber={100}
          />
        </div>

        <div className={`${styles.genderPrivacy} ${styles.width200}`}>
          <div className={`${styles.inputGroup} ${styles.zIndex1200}`}>
            <label className={styles.text}>Gender</label>
            <SimpleDropdown
              options={genderOptions}
              selectedOption={gender}
              onSelect={handleGenderChange}
              name="Gender"
            />
          </div>

          <div className={`${styles.inputGroup} ${styles.zIndex1000}`}>
            <label className={styles.text}>Account Privacy</label>
            <SimpleDropdown
              options={privacyOptions}
              selectedOption={privacy}
              onSelect={handlePrivacyChange}
              name="Privacy"
            />
          </div>
        </div>

        {gender === "Other/Prefer Not to Say" && (
          <div className={styles.inputGroup}>
            <input
              className={styles.input}
              placeholder="Enter your gender (optional)"
              value={customGender}
              onChange={(e) => setCustomGender(e.target.value)}
            />
          </div>
        )}

        <div className={styles.countries}>
          <div className={`${styles.inputGroup} ${styles.zIndex1000}`}>
            <label className={styles.text}>Country</label>
            <SimpleDropdownSearch
              options={countries}
              selectedOption={country1}
              onSelect={(item) => setCountry1(item)}
              name="Country 1"
            />
          </div>
          <div className={`${styles.inputGroup2} ${styles.zIndex1000}`}>
            <label className={styles.text}>Secondary Country</label>
            <SimpleDropdownSearch
              options={countries}
              selectedOption={country2}
              onSelect={(item) => setCountry2(item)}
              name="Country 2"
            />
          </div>
        </div>

        <div className={styles.inputGroup}>
          <label className={styles.text}>Bio</label>
          <textarea
            className={`${styles.input} ${styles.height100}`}
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            placeholder="Write a short bio about yourself..."
          />
        </div>

        <div className={styles.inputGroup}>
          <label className={styles.text}>Referrer</label>
          <input
            className={styles.input}
            value={referrer}
            onChange={(e) => setReferrer(e.target.value)}
            placeholder="From who or where did you hear about us?"
          />
        </div>

        {photoUrl2 && (
          <div>
            <img
              src={photoUrl2}
              alt="user_photo"
              title="User Photo"
              className={styles.photo}
            ></img>
          </div>
        )}
        <div className={styles.image_upload}>
          <ImageUpload
            onFileUploaded={onFileUploaded}
            linkName1={"Add Photo"}
            linkName2={"Edit Photo"}
          />
        </div>

        <p className={styles.termsText} onClick={() => setShowTermsModal(true)}>
          By clicking Create Account, you agree to our Terms, Data Policy and
          Cookies Policy.
        </p>

        {/* <p className={styles.mandatoryText}>Fields marked * are mandatory</p> */}

        <div className={styles.registerButtons}>
          <button onClick={handleSubmit} className={styles.updateButton}>
            Create Account
          </button>
        </div>
      </div>

      {showTermsModal && (
        <div
          className={styles.modalOverlay}
          onClick={() => setShowTermsModal(false)}
        >
          <div
            className={styles.modalContainer}
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className={styles.termsTitle}>Terms and Conditions</h3>
            <FontAwesomeIcon
              icon={faXmarkCircle}
              className={styles.closeIcon}
              onClick={() => setShowTermsModal(false)}
            />
            <p className={styles.termsModalText}>
              Welcome to Rated. By accessing our website and using our services,
              you agree to be bound by these terms and conditions.
              <br />
              <br />
              Users must be at least 18 years old or have legal parental or
              guardian consent to use this site. Users agree not to use the site
              for any illegal or unauthorized purpose.
              <br />
              <br />
              All content on Rated, including text, graphics, logos, and images,
              is the property of Rated or its content suppliers and protected by
              UK and international copyright laws.
              <br />
              <br />
              Users may post content as long as it is not obscene, illegal,
              defamatory, or infringes on intellectual property rights. Rated
              reserves the right to remove any user content that violates these
              terms.
              <br />
              <br />
              Our Privacy Policy, which outlines how we collect and use your
              personal data, is an integral part of these terms.
              <br />
              <br />
              Rated is not liable for any indirect, incidental, or consequential
              damages arising from the use of our services.
              <br />
              <br />
              These terms are governed by the laws of the United Kingdom.
              <br />
              <br />
              Rated reserves the right to modify these terms at any time.
              Continued use of the site after changes indicates acceptance of
              the new terms.
              <br />
              <br />
              Rated uses cookies to enhance user experience. By using Rated, you
              agree to the use of cookies in accordance with this policy.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Register;
