import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./EditProfile.module.css";
import { UserContext } from "../components/UserContext";
import ImageUpload from "../components/ImageUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCaretLeft,
  faUserMinus,
} from "@fortawesome/free-solid-svg-icons";
import SimpleDropdown from "../components/SimpleDropdown";
import SimpleDropdownSearch from "../components/SimpleDropdownSearch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { signOut } from "firebase/auth";
import { auth } from "../firebaseConfig"; // Make sure to import auth correctly

function Register() {
  const [username, setUsername2] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [countries, setCountries] = useState([]);
  const [country1, setCountry1] = useState("");
  const [country2, setCountry2] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");
  const [usernames, setUsernames] = useState([]); // New state for usernames
  const [bio, setBio] = useState("");
  const [email, setEmail] = useState("");
  const [privacy, setPrivacy] = useState("public");
  const navigate = useNavigate();
  const [timestamp, setTimestamp] = useState(new Date().toISOString());
  const { setUserId, setUsername } = useContext(UserContext);
  const [activeTab, setActiveTab] = useState("Details");
  const [usernameValidationMessage, setUsernameValidationMessage] =
    useState("");
  const [userDetails, setUserDetails] = useState([]); // New state for usernames
  const { userId } = useContext(UserContext);
  const [usernameInput, setUsernameInput] = useState("");
  const [customGender, setCustomGender] = useState("");
  const [open, setOpen] = useState(false);
  const [datePicked, setDatePicked] = useState(false);

  const [year, setYear] = useState(null);
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [youtube, setYoutube] = useState("");
  const [discord, setDiscord] = useState("");
  const [twitch, setTwitch] = useState("");
  const [namePrivacy, setNamePrivacy] = useState("0");
  const [genderPrivacy, setGenderPrivacy] = useState("0");
  const [dobPrivacy, setDobPrivacy] = useState("0");
  const [countryPrivacy, setCountryPrivacy] = useState("0");

  const [facebookPrivacy, setFacebookPrivacy] = useState("0");
  const [instagramPrivacy, setInstagramPrivacy] = useState("0");
  const [twitterPrivacy, setTwitterPrivacy] = useState("0");
  const [linkedinPrivacy, setLinkedinPrivacy] = useState("0");
  const [tiktokPrivacy, setTiktokPrivacy] = useState("0");
  const [youtubePrivacy, setYoutubePrivacy] = useState("0");
  const [discordPrivacy, setDiscordPrivacy] = useState("0");
  const [twitchPrivacy, setTwitchPrivacy] = useState("0");

  const [genderOptions, setGenderOptions] = useState([
    "Other/Prefer Not to Say",
    "Female",
    "Male",
  ]);
  const [privacyOptions, setPrivacyOptions] = useState([
    "Public",
    "Friends Only",
    "Private",
    "Hidden",
  ]);

  const handleGenderChange = (newTag) => {
    setGender(newTag);
  };

  const handlePrivacyChange = (newTag) => {
    setPrivacy(newTag);
  };

  useEffect(() => {
    if (dateOfBirth) {
      const newYear = new Date(dateOfBirth).getFullYear();
      setYear(newYear);
    }
  }, [dateOfBirth]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    // JavaScript months are 0-based, so add 1
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/get_countries`
        );
        const response2 = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/get_usernames`
        );
        const data = await response.json();
        const data2 = await response2.json();
        setCountries(data);
        setUsernames(data2);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/get_profile_edit?user_id=${userId}`
        );
        const data = await response.json();
        console.log(data);
        setUserDetails(data[0]);
        setUsername2(data[0].username);
        setUsernameInput(data[0].username);
        setEmail(data[0].email);
        setFirstName(data[0].first_name);
        setLastName(data[0].last_name);
        setGender(data[0].gender);
        setDateOfBirth(formatDate(data[0].dob));
        setCountry1(data[0].country1);
        setCountry2(data[0].country2);
        setPrivacy(data[0].privacy);
        setBio(data[0].bio);
        setPhotoUrl(data[0].photo_url);
        setFacebook(data[0].facebook);
        setInstagram(data[0].instagram);
        setTwitter(data[0].twitter);
        setLinkedin(data[0].linkedin);
        setTiktok(data[0].tiktok);
        setYoutube(data[0].youtube);
        setDiscord(data[0].discord);
        setTwitch(data[0].twitch);
        setNamePrivacy(data[0].namePrivacy);
        setGenderPrivacy(data[0].genderPrivacy);
        setDobPrivacy(data[0].dobPrivacy);
        setCountryPrivacy(data[0].countryPrivacy);
        setFacebookPrivacy(data[0].facebookPrivacy);
        setInstagramPrivacy(data[0].instagramPrivacy);
        setTwitterPrivacy(data[0].twitterPrivacy);
        setLinkedinPrivacy(data[0].linkedinPrivacy);
        setTiktokPrivacy(data[0].tiktokPrivacy);
        setYoutubePrivacy(data[0].youtubePrivacy);
        setDiscordPrivacy(data[0].discordPrivacy);
        setTwitchPrivacy(data[0].twitchPrivacy);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    }

    fetchUserData();
  }, []);

  const handleLogout = async () => {
    await signOut(auth);
    setUserId(null); // Reset user context
    setUsername("");
    setPhotoUrl("");
    // window.alert("User signed out");
    navigate("/"); // Redirect to the home page
  };

  const handleUsernameBlur = () => {
    if (usernames.includes(username) && username !== userDetails.username) {
      setUsernameValidationMessage("This username is already taken!");
    } else if (username) {
      // Check if username is not empty before setting accepted message
      setUsernameValidationMessage("");
    } else {
      setUsernameValidationMessage("");
    }
  };

  const handleBackClick = () => {
    navigate(-1); // This takes the user back to the previous page
  };

  const onFileUploaded = (url) => {
    setPhotoUrl(url);
    console.log(url);
  };

  const handleSubmit = async () => {
    // You can add more checks for other fields too.

    // if (year < 1900 || year > 2023) {
    //   alert("Please give a sensible year of birth!");
    //   return;
    // }

    // if (!username || !firstName || !lastName) {
    //   alert("Please fill out all the required fields!");
    //   return;
    // }

    const payload = {
      username: usernameInput,
      email,
      firstName,
      lastName,
      gender,
      dateOfBirth,
      country1,
      country2,
      photoUrl,
      privacy,
      bio,
      namePrivacy,
      genderPrivacy,
      dobPrivacy,
      countryPrivacy,
      userId,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/update_user`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();
      if (data.status === "success") {
        setUserId(data.user_id); // Assuming you have access to setUserId from UserContext
        setUsername(username);
        navigate(`/profiles/${userId}`);
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.error("Error registering the user:", error);
    }
  };

  const handlePasswordUpdate = async () => {
    // You can add more checks for other fields too.
    if (password !== passwordConfirm) {
      alert("New passwords do not match!");
      return;
    }

    const payload = {
      userId,
      oldPassword,
      password,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/update_password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();
      if (data.status === "success") {
        alert("Password updated successfully!");
        navigate("/MyProfile"); // Adjust as needed to navigate to the profile page
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.error("Error updating password", error);
    }
  };

  const handleSocialUpdate = async () => {
    // You can add more checks for other fields too.

    const payload = {
      userId,
      facebook,
      instagram,
      twitter,
      linkedin,
      tiktok,
      youtube,
      discord,
      twitch,
      facebookPrivacy,
      instagramPrivacy,
      twitterPrivacy,
      linkedinPrivacy,
      tiktokPrivacy,
      youtubePrivacy,
      discordPrivacy,
      twitchPrivacy,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/update_social`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();
      if (data.status === "success") {
        alert("Social media updated successfully!");
        navigate("/MyProfile"); // Adjust as needed to navigate to the profile page
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.error("Error updating social media", error);
    }
  };

  const handleDateSelect = (date) => {
    setDateOfBirth(date);
    setDatePicked(true);
  };

  const handleDateChange = (date) => {
    setDateOfBirth(date);
    setDatePicked(true);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.page_title}>Profile Settings</h1>

      <div className={styles.backIconContainer} onClick={handleBackClick}>
        <FontAwesomeIcon icon={faArrowLeft} className={styles.backArrow} />
      </div>
      <div className={styles.tabs}>
        <button
          className={`${styles.tabButton} ${
            activeTab === "Details" ? styles.active : ""
          }`}
          onClick={() => setActiveTab("Details")}
        >
          Details
        </button>

        <button
          className={`${styles.tabButton} ${
            activeTab === "Password" ? styles.active : ""
          }`}
          onClick={() => setActiveTab("Password")}
        >
          Password
        </button>

        <button
          className={`${styles.tabButton} ${
            activeTab === "Social Media" ? styles.active : ""
          }`}
          onClick={() => setActiveTab("Social Media")}
        >
          Social Media
        </button>

        <button
          className={`${styles.tabButton} ${
            activeTab === "Account" ? styles.active : ""
          }`}
          onClick={() => setActiveTab("Account")}
        >
          Account
        </button>
      </div>

      {activeTab === "Details" && (
        <div className={styles.registerForm}>
          <div className={styles.inputGroup}>
            <label className={styles.text}>Username:</label>
            <input
              className={styles.input}
              value={usernameInput}
              onChange={(e) => setUsernameInput(e.target.value)}
              onBlur={() => {
                // Handle username validation
              }}
              placeholder="Max 14 characters"
            />
            {usernameValidationMessage && (
              <span className={styles.usernameError}>
                {usernameValidationMessage}
              </span>
            )}
          </div>

          <div className={styles.inputGroup}>
            <label className={styles.text}>First Name:</label>
            <input
              className={styles.input}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First Name"
            />
          </div>

          <div className={styles.inputGroup}>
            <label className={styles.text}>Last Name:</label>
            <input
              className={styles.input}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Last Name"
            />
          </div>

          <div className={styles.inputGroup}>
            <label className={styles.text}>Email:</label>
            <input
              className={styles.input}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
          </div>

          <div className={styles.inputGroup}>
            <label className={styles.text}>Date of Birth:</label>
            <DatePicker
              selected={dateOfBirth}
              onSelect={handleDateSelect} //when day is clicked
              onChange={handleDateChange} //only when value has changed
            />
          </div>

          <div className={`${styles.genderPrivacy} ${styles.width200}`}>
            <div className={`${styles.inputGroup} ${styles.zIndex1200}`}>
              <label className={styles.text}>Gender:</label>
              <SimpleDropdown
                options={genderOptions}
                selectedOption={gender}
                onSelect={handleGenderChange}
                name="Gender"
              />
            </div>

            <div className={`${styles.inputGroup} ${styles.zIndex1000}`}>
              <label className={styles.text}>Account Privacy:</label>
              <SimpleDropdown
                options={privacyOptions}
                selectedOption={privacy}
                onSelect={handlePrivacyChange}
                name="Privacy"
              />
            </div>
          </div>

          {gender === "Other/Prefer Not to Say" && (
            <div className={styles.inputGroup}>
              <input
                className={styles.input}
                placeholder="Enter your gender (optional)"
                value={customGender}
                onChange={(e) => setCustomGender(e.target.value)}
              />
            </div>
          )}

          <div className={styles.countries}>
            <div className={`${styles.inputGroup} ${styles.zIndex1000}`}>
              <label className={styles.text}>Country:</label>
              <SimpleDropdownSearch
                options={countries}
                selectedOption={country1}
                onSelect={(item) => setCountry1(item)}
                name="Country 1"
              />
            </div>
            <div className={`${styles.inputGroup2} ${styles.zIndex1000}`}>
              <label className={styles.text}>Secondary Country:</label>
              <SimpleDropdownSearch
                options={countries}
                selectedOption={country2}
                onSelect={(item) => setCountry2(item)}
                name="Country 2"
              />
            </div>
          </div>

          <div className={styles.inputGroup}>
            <label className={styles.text}>Bio:</label>
            <textarea
              className={`${styles.input} ${styles.height100}`}
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              placeholder="Write a short bio about yourself..."
            />
          </div>

          <div>
            <div>
              <img
                src={photoUrl}
                alt="user_photo"
                title="User Photo"
                className={styles.photo}
              ></img>
            </div>
            <div className={styles.image_upload}>
              <ImageUpload
                onFileUploaded={onFileUploaded}
                linkName1={"Edit Photo"}
                linkName2={"Edit Photo"}
              />
            </div>
          </div>

          <div className={styles.registerButtons}>
            <button onClick={handleSubmit} className={styles.updateButton}>
              Submit Updates
            </button>
          </div>
        </div>
      )}

      {activeTab === "Social Media" && (
        <div className={styles.registerForm}>
          <div className={styles.inputGroup}>
            <label className={styles.text}>Facebook:</label>
            <input
              className={styles.input}
              value={facebook}
              onChange={(e) => setFacebook(e.target.value)}
              placeholder="Facebook Account URL"
            />
          </div>
          <div className={styles.inputGroup}>
            <label className={styles.text}>Twitter:</label>
            <input
              className={styles.input}
              value={twitter}
              onChange={(e) => setTwitter(e.target.value)}
              placeholder="Twitter Account URL"
            />
          </div>
          <div className={styles.inputGroup}>
            <label className={styles.text}>Instagram:</label>
            <input
              className={styles.input}
              value={instagram}
              onChange={(e) => setInstagram(e.target.value)}
              placeholder="Instagram Account URL"
            />
          </div>
          <div className={styles.inputGroup}>
            <label className={styles.text}>LinkedIn:</label>
            <input
              className={styles.input}
              value={linkedin}
              onChange={(e) => setLinkedin(e.target.value)}
              placeholder="LinkedIn Account URL"
            />
          </div>
          <div className={styles.inputGroup}>
            <label className={styles.text}>Youtube:</label>
            <input
              className={styles.input}
              value={youtube}
              onChange={(e) => setYoutube(e.target.value)}
              placeholder="Youtube Account URL"
            />
          </div>
          <div className={styles.inputGroup}>
            <label className={styles.text}>Tiktok:</label>
            <input
              className={styles.input}
              value={tiktok}
              onChange={(e) => setTiktok(e.target.value)}
              placeholder="Tiktok Account URL"
            />
          </div>
          <div className={styles.inputGroup}>
            <label className={styles.text}>Twitch:</label>
            <input
              className={styles.input}
              value={twitch}
              onChange={(e) => setTwitch(e.target.value)}
              placeholder="Twitch Account URL"
            />
          </div>
          <div className={styles.inputGroup}>
            <label className={styles.text}>Discord:</label>
            <input
              className={styles.input}
              value={discord}
              onChange={(e) => setDiscord(e.target.value)}
              placeholder="Discord Account URL"
            />
          </div>

          <div className={styles.registerButtons2}>
            <button onClick={handleSocialUpdate}>Update</button>
          </div>
        </div>
      )}

      {activeTab === "Password" && (
        <div className={styles.registerForm}>
          <label>Old Password: </label>
          <input
            className={styles.input}
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            placeholder="Enter your old password"
            type="password"
          />

          <label>New Password:</label>
          <input
            className={styles.input}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your new password"
            type="password"
          />

          <label> Confirm Password: </label>
          <input
            className={styles.input}
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
            placeholder="Confirm your new password"
            type="password"
          />

          <div className={styles.registerButtons2}>
            <button onClick={handlePasswordUpdate}>Update</button>
          </div>
        </div>
      )}

      {activeTab === "Account" && (
        <div className={styles.accountContainer}>
          <FontAwesomeIcon
            icon={faUserMinus}
            className={styles.logoutIcon}
            onClick={handleLogout}
          />
          <p className={styles.logoutText}>Logout</p>
        </div>
      )}
    </div>
  );
}

export default Register;
