import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "./Messages.module.css"; // Adjust path as necessary
import { formatDistanceToNow } from "date-fns";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../components/UserContext";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Messages = () => {
  const [search, setSearch] = useState("");
  const [messageList, setMessageList] = useState([]);
  const { userId, setNotificationCount, setMessageCount } = useContext(UserContext);
  const apiUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const [filteredMessages, setFilteredMessages] = useState([]);

  useEffect(() => {
    async function fetchMessages() {
      try {
        const response = await fetch(
          `${apiUrl}/get_all_messages?user_id=${userId}`
        );
        const data = await response.json();
        // Convert object to array and sort by timestamp
        const messagesArray = Object.keys(data.messages)
          .map((key) => ({
            ...data.messages[key],
            id: key,
          }))
          .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        setMessageList(messagesArray);
        setFilteredMessages(messagesArray);
        setMessageCount(0);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    }
    fetchMessages();
  }, [userId, apiUrl]);

  useEffect(() => {
    if (messageList.length > 0) {
      const filtered = messageList.filter((message) =>
        message.other_username.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredMessages(filtered);
    }
  }, [search, messageList]);



  const renderMessage = ({ item }) => (
    <Link
      to={`/DirectMessage/${item.other_user_id}`}
      className={styles.messageItem}
      key={item.message_id}
    >
      <div className={styles.imageContainer}>
        <img
          src={item.other_photo_url}
          alt=""
          className={styles.profilePhoto}
        />
        <p className={styles.timestamp}>
          {formatDistanceToNow(new Date(item.timestamp), {
            addSuffix: true,
          }).replace("about ", "").replace("over 54 years ago", "")}
        </p>
      </div>
      <div className={styles.messageContent}>
        <span className={styles.name}>@{item.other_username}</span>
        <p className={styles.lastMessage}>
          {item.message_status === "Sent" ? (
            <span className={styles.lastMessagePrefix}>You: </span>
          ) : null}
          {item.last_message}
        </p>
      </div>
      <div className={styles.statusIndicator}>
        {item.message_status === "Received" && item.read === 0 && (
          <div className={styles.unreadDot}></div>
        )}
        {item.message_status === "Received" && item.read === 1 && (
          <FontAwesomeIcon
            icon={faEye}
            size="32"
            color="purple"
            className={styles.eye}
          />
        )}
        {item.message_status === "Sent" && (
          <FontAwesomeIcon
            icon={item.read === 0 ? faEyeSlash : faEye}
            size="32"
            color="purple"
            className={styles.eye}
          />
        )}
      </div>
    </Link>
  );

  return (
    <div className={styles.container}>
      <div className={styles.searchHeader}>
        <input
          className={styles.searchBar}
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search Friends"
        />
      </div>

      {filteredMessages.length === 0 && (
        <div className={styles.noMessages}>
          <p>You need to add some friends before you can start messaging! </p>
        </div>
      )}

      <div style={{ overflowY: "auto" }}>
        {filteredMessages.map((message) => renderMessage({ item: message }))}
      </div>
    </div>
  );
};

export default Messages;
