import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import "./ProductPage.css";
import { Link, useParams } from "react-router-dom";
import { UserContext } from "../components/UserContext";
import { useNavigate } from "react-router-dom";
import styles from "./RapidRated.module.css";
import { FaArrowLeft, FaArrowRight, FaTh, FaThumbsUp } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faXmarkCircle,
  faBan,
  faCircleCheck,
  faArrowLeft,
  faArrowRight,
  faSquareArrowUpRight,
  faPause,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "../components/DropdownR";
import { motion } from "framer-motion";
import Tutorial from "../components/Tutorial";
import { useLocation } from "react-router-dom"; // Import useLocation

function RapidRate({ route }) {
  const location = useLocation(); // Use useLocation to get the current location object
  const [product, setProduct] = useState(null);
  const [nextProduct, setNextProduct] = useState(null);
  const [previousProduct, setPreviousProduct] = useState(null);
  const [previousRaters, setPreviousRaters] = useState([]);
  const [previousExternalRaters, setPreviousExternalRaters] = useState([]);
  const [previousPredictedRating, setPreviousPredictedRating] = useState(null);
  const [previousAverageRating, setPreviousAverageRating] = useState(null);
  const [productIds, setProductIds] = useState([]);
  const [productCount, setProductCount] = useState(-1);
  const [raters, setRaters] = useState([]);
  const [nextRaters, setNextRaters] = useState([]);
  const [externalRaters, setExternalRaters] = useState([]);
  const [nextExternalRaters, setNextExternalRaters] = useState([]);
  const [nextPredictedRating, setNextPredictedRating] = useState(null);
  const [predictedRating, setPredictedRating] = useState(null);
  const [averageRating, setAverageRating] = useState(null);
  const [nextAverageRating, setNextAverageRating] = useState(null);
  const [everyone, setEveryone] = useState(false);
  const [flashMessage, setFlashMessage] = useState(null);
  const [timerId, setTimerId] = useState(null);
  const [review, setReview] = useState(null);
  const [loginVisible, setLoginVisible] = useState(false);
  const [startup, setStartup] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [message, setMessage] = useState("");
  const [keyboardClosed, setKeyboardClosed] = useState(true);
  const navigate = useNavigate();
  const unknownImg =
    "https://s3.eu-west-2.amazonaws.com/rated.images/unknown.png";
  const navigation = useNavigate();

  const [categories, setCategories] = useState([]);
  // Initialize state with either context values or defaults
  const [selectedCategories, setSelectedCategories] = useState(() => {
    const savedCategories = localStorage.getItem("selectedCategories");
    return savedCategories ? JSON.parse(savedCategories) : [];
  });
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [productTitleText, setProductTitleText] = useState(null);
  const [activeTab, setActiveTab] = useState("Ratings");
  const [empty, setEmpty] = useState(false);
  const [marginTop, setMarginTop] = useState(40);
  const [pressable, setPressable] = useState(true);
  const apiUrl = process.env.REACT_APP_BACKEND_URL;
  const [userRating, setUserRating] = useState(null);
  const scrollViewRef = useRef(null);

  const startRating = null;
  const initialRating = startRating && startRating > 0 ? startRating : 0;
  const [selectedRating, setSelectedRating] = useState(initialRating);
  const {
    userId,
    ratingCount,
    setRatingCount,
    followingCount,
    setFollowingCount,
    userType,
    setUserType,
    username,
    setUsername,
    photoUrl,
    setPhotoUrl,
    setUserId,
    setShowLoginPopup,
  } = useContext(UserContext);
  const [sound, setSound] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio, setAudio] = useState(null);
  const [clipUrl, setClipUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [targetRating, setTargetRating] = useState(30);
  const [progress, setProgress] = useState(0);
  const [showTutorial, setShowTutorial] = useState(
    location.state?.tutorial || false
  );

  const handleExternal = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleTempAccount = async () => {
    try {
      const tempPayload = {
        user_id: "temp",
      };
      const response = await fetch(`${apiUrl}/temp_account`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(tempPayload),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setUserId(data.user_id);
      setPhotoUrl(data.photo_url);
      setUsername(data.username);
      setUserType(data.user_type);
      setShowTutorial(true);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      // Handle the error as needed
    }
  };

  useEffect(() => {
    const progress = (ratingCount / targetRating) * 100;
    setProgress(progress);

    if (
      ratingCount >= targetRating &&
      (userType === "New" || userType === "Temp")
    ) {
      const payload = {
        user_id: userId,
        user_type: "Beginner",
      };

      fetch(`${apiUrl}/update_user_type`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((data) => {
          setUserType("Beginner");
          navigate("/MyProfile", { state: { tutorial: true } });
        })
        .catch((error) => {
          console.error("Error updating user type:", error);
        });
    }
  }, [ratingCount, userId]);

  useEffect(() => {
    // Check if the window width is less than 768px on mount
    if (window.innerWidth < 1000) {
      navigate("/WelcomeM"); // Assuming '/mobile' is the route for LandingMobile
    }
  }, [navigate]);

  // Save preferences whenever selectedCategories or selectedTags change
  useEffect(() => {
    localStorage.setItem(
      "selectedCategories",
      JSON.stringify(selectedCategories)
    );
  }, [selectedCategories]);

  const [error, setError] = useState(null);
  const starContainerRef = useRef(null);
  const [hoverRating, setHoverRating] = useState(0);
  const ratingsDescriptions = [
    { num: -3, desc: "Blocked" },
    { num: -2, desc: "Skipped" },
    { num: -1, desc: "Wishlist" },
    { num: 0, desc: "Not Seen" },
    { num: 1, desc: "Awful" },
    { num: 2, desc: "Poor" },
    { num: 3, desc: "Underwhelming" },
    { num: 4, desc: "Mediocre" },
    { num: 5, desc: "Decent" },
    { num: 6, desc: "Good" },
    { num: 7, desc: "Very Good" },
    { num: 8, desc: "Great" },
    { num: 9, desc: "Incredible" },
    { num: 10, desc: "The Best" },
  ];



  const getRatingDescription = (rating) => {
    const item = ratingsDescriptions.find((desc) => desc.num === rating);
    return item ? item.desc : "Select a Rating"; // Default text
  };

  useEffect(() => {
    setSelectedRating(userRating);
  }, [userRating]);

  const updateRating = () => {
    // Use hoverRating if it's valid; otherwise, use selectedRating
    const newRating = hoverRating > 0 ? hoverRating : selectedRating;
    setSelectedRating(newRating);
  };

  const renderStars = () => {
    let stars = [];
    for (let i = 1; i <= 10; i++) {
      stars.push(
        <div
          key={i}
          className={`${styles.star} ${
            i <= (hoverRating || selectedRating) ? styles.highlightedStar : ""
          }`}
          onClick={updateRating}
          onMouseEnter={() => setHoverRating(i)}
          onMouseLeave={() => setHoverRating(0)}
          style={{
            color:
              i <= (hoverRating || selectedRating)
                ? "purple"
                : "rgb(230,230,230)",
          }}
        >
          <FontAwesomeIcon icon={faStar} size="3x" />
        </div>
      );
    }
    return stars;
  };

  // Function to handle setting and clearing the flash message
  const setFlashMessageWithTimer = (message) => {
    // Clear any existing timer
    if (timerId) {
      clearTimeout(timerId);
    }

    // Set the flash message immediately
    if (userId) {
      setFlashMessage(message); // Assumes `setFlashMessage` is defined to update some part of your component's state or context
    } else {
      setFlashMessage("Automatic account created!");
    }

    // Set a new timer to clear the message after 2 seconds
    const newTimerId = setTimeout(() => {
      setFlashMessage(null);
    }, 1000);

    // Update the timer ID state
    setTimerId(newTimerId);
  };

  useEffect(
    useCallback(() => {
      setError(null); // Reset error state
      const fetchData = async () => {
        try {
          if (!product) {
            if (productCount !== -1) {
              setRefresh(!refresh);
            }
          } else {
            const response = await fetch(
              `${apiUrl}/rapid_rating?user_id=${userId}&product_id=${product.product_id}`
            );
            const data = await response.json();

            if (data.rating) {
              handleNextProduct();
            }
          }
        } catch (error) {
          console.error("Failed to fetch data:", error);
        }
      };

      fetchData();
    }, []) // Add dependencies here
  );

  const fetchCategories = async () => {
    const response2 = await fetch(
      `${apiUrl}/get_user_categories?userId=${userId}&empty=true`
    );
    const data2 = await response2.json();
    setCategories(data2);
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        fetchCategories();
      } catch (error) {
        console.error("Initialization error:", error);
        // Handle initialization error (e.g., show a message to the user)
      }
    };

    initialize();
  }, []);

  useEffect(() => {
    if (productCount === -1 && startup) {
      setStartup(false);
    }
    setProductIds([]);
    setProduct(null);
    setNextProduct(null);
    setRaters([]);
    setNextRaters([]);
    setExternalRaters([]);
    setNextExternalRaters([]);
    setPredictedRating(null);
    setAverageRating(null);
    setNextPredictedRating(null);
    setNextAverageRating(null);
    setEmpty(false);
    if (productCount !== -1) {
      setProductCount(-1);
    } else {
      setRefresh(!refresh);
    }
  }, [selectedCategories, userId]);

  useEffect(() => {
    if (productCount == -1) {
      fetchProductIds();
    }
    if (productIds.length > 0 && product == null && productCount >= 0) {
      fetchProduct(productIds[productCount], setProduct, true);
    }
    // Pre-fetch next product
    if (productIds.length > 0 && productCount + 1 < productIds.length) {
      fetchProduct(productIds[productCount + 1], setNextProduct);
    }
    // If reaching the end, fetch more product IDs
    if (productCount === productIds.length - 2) {
      fetchProductIds();
    }
  }, [productCount, refresh]);

  useEffect(() => {
    const fetchData = async () => {
      if (window.innerWidth < 1000) {
        navigate("/WelcomeM"); // Assuming '/mobile' is the route for LandingMobile
        return;
      }
      if (!userId) {
        setShowLoginPopup(true);
        return;
      }
    };

    fetchData();
  }, [apiUrl, userId]);

  const fetchProductIds = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/rapid_product_ids?user_id=${userId}&everyone=${everyone}&category_ids=${selectedCategories.join(
          ","
        )}&excluded_product_ids=${productIds.join(
          ","
        )}&latitude=${latitude}&longitude=${longitude}`
      );
      const data = await response.json();
      if (data.length === 0) {
        setEmpty(true);
        return;
      }
      setProductIds((prevIds) => {
        // Combine previous IDs and new IDs
        const combinedIds = [...prevIds, ...data];
        // Convert combined array to a Set to remove duplicates, then convert it back to an array
        const uniqueIds = Array.from(new Set(combinedIds));
        return uniqueIds;
      });
      if (productCount === -1) {
        setProductCount(0);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setStartup(false);
  };

  const fetchProduct = async (productId, setState, current = false) => {
    if (!productId) {
      //Wait for one second
      setTimeout(() => {
        setProductCount((prevCount) => prevCount + 1);
      }, 200);
      return;
    } // Skip if product ID is not available
    try {
      const response = await fetch(
        `${apiUrl}/rapid_product_info?product_id=${productId}&user_id=${userId}&latitude=${latitude}&longitude=${longitude}`
      );
      const data = await response.json();
      setState(data);
      if (current) {
        setSelectedRating(null);
        setRaters(data.rating_details);
        setExternalRaters(data.external_ratings);
        setPredictedRating(calculatePredictedRating(data.rating_details));
        setAverageRating(
          calculateAverageRating(data.rating_details, data.external_ratings)
        );
      } else {
        setNextRaters(data.rating_details);
        setNextExternalRaters(data.external_ratings);
        setNextPredictedRating(calculatePredictedRating(data.rating_details));
        setNextAverageRating(
          calculateAverageRating(data.rating_details, data.external_ratings)
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const calculatePredictedRating = (raters) => {
    let numerator = 0;
    let denominator = 0;
    let ratersCount = 0;

    raters.forEach((rater) => {
      if (!rater.similarity) return; // Skip if similarity is not available
      let weight = (rater.similarity - 68) * 2; // use let instead of const
      if (weight < 0) weight = 0;
      if (weight > 0) ratersCount++;
      numerator += weight * rater.rating;
      denominator += weight;
    });

    //setPredictedRating(denominator !== 0 ? numerator / denominator : 0);
    return denominator !== 0 ? numerator / denominator : 0;
  };

  const calculateAverageRating = (raters, externalRaters) => {
    let total_rating = 0;
    let total_count = 0;

    raters.forEach((rater) => {
      if (rater.rating && rater.rating > 0) {
        total_rating += Number(rater.rating); // Ensure rating is treated as a number
        total_count++;
      }
    });

    externalRaters.forEach((rater) => {
      if (rater.rating && parseFloat(rater.rating) > 0) {
        total_rating += parseFloat(rater.rating); // Use parseFloat to convert string to number
        total_count++;
      }
    });

    // Ensure there are raters before dividing to avoid division by zero
    const avg = total_count > 0 ? total_rating / total_count : 0;

    return avg;
  };

  const handleNextProduct = () => {
    setError(null); // Reset error state
    setReview(null); // Reset review state
    setPressable(null);
    setIsPlaying(false);
    if (activeTab === "Ratings" && selectedRating === null) {
    scrollViewRef.current.scrollLeft = 0;
    }
    // Swap next product into view
    // Wait one second before swapping
    setTimeout(() => {
      setPreviousProduct(product);
      setPreviousRaters(raters);
      setPreviousExternalRaters(externalRaters);
      setPreviousPredictedRating(predictedRating);
      setPreviousAverageRating(averageRating);
      setProduct(nextProduct);
      setRaters(nextRaters);
      setExternalRaters(nextExternalRaters);
      setPredictedRating(nextPredictedRating);
      setAverageRating(nextAverageRating);
      setNextProduct(null); // Reset next product
      setNextRaters([]); // Reset next raters
      setNextExternalRaters([]); // Reset next external raters
      setNextPredictedRating(null); // Reset next predicted rating
      setSelectedRating(null); // Reset selected rating
      setProductCount((prevCount) => prevCount + 1);
      setPressable(true);
    }, 200);
  };

  const handleGoBack = () => {
    if (!previousProduct) {
      setFlashMessageWithTimer(`Can't Go Back!`);
      // If previous product is null, do nothing
      return;
    }
    setError(null); // Reset error state
    setReview(null); // Reset review state
    setPressable(null);
    setFlashMessageWithTimer(`Going Back`);
    // Swap previous product into view
    // Wait one second before swapping
    setTimeout(() => {
      setNextProduct(product);
      setNextRaters(raters);
      setNextExternalRaters(externalRaters);
      setNextPredictedRating(predictedRating);
      setNextAverageRating(averageRating);
      setProduct(previousProduct);
      setRaters(previousRaters);
      setExternalRaters(previousExternalRaters);
      setPredictedRating(previousPredictedRating);
      setAverageRating(previousAverageRating);
      setPreviousProduct(null); // Reset previous product
      setSelectedRating(null);
      setProductCount((prevCount) => prevCount - 1);
      setPressable(true);
    }, 200);
  };

  const handleSelectRating = (number, review = null) => {
    const date = new Date(); // Get the current date and time
    date.setHours(date.getHours() + 1); // Add one hour to the current time

    if (!userId) {
      handleTempAccount();
      return;
    }

    if (number > 0) {
      setRatingCount(ratingCount + 1);
    }

    const payload = {
      product_id: product.product_id,
      user_id: userId,
      rating: number,
      timestamp: date.toISOString(), // Use the adjusted date
      category_id: product.category_id,
      review: review,
      screen: "RapidRate",
      device: "web",
    };

    fetch(`${apiUrl}/input_rating`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    handleNextProduct();
  };

  useEffect(() => {
    if (product && product.category_id === 5 && product.clip_url) {
      setClipUrl(product.clip_url);
    } else {
      setClipUrl(null);
      return;
    }

    const audioInstance = new Audio(product.clip_url);

    audioInstance.onloadeddata = () => {
      setAudio(audioInstance);
    };

    audioInstance.onerror = (error) => {
      console.log("Failed to load the sound", error);
      setAudio(null);
    };

    // Cleanup
    return () => {
      if (audioInstance) {
        audioInstance.pause();
        audioInstance.src = ""; // Release the audio object
      }
    };
  }, [product]);

  const playPauseSound = () => {
    if (audio) {
      if (isPlaying) {
        audio.pause();
      } else {
        audio
          .play()
          .then(() => {
            console.log("Successfully started playing");
          })
          .catch((error) => {
            console.log("Playback failed due to audio decoding errors", error);
          });
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Helper function to sort by similarity, handling null values
  const sortBySimilarity = (a, b) => {
    if (a.similarity === null) return 1;
    if (b.similarity === null) return -1;
    return b.similarity - a.similarity;
  };

  // Separate raters with and without relationship
  const ratersWithRelationship = raters
    .filter((rater) => rater.relationship !== null)
    .sort(sortBySimilarity);
  const ratersWithoutRelationship = raters
    .filter((rater) => rater.relationship === null)
    .sort(sortBySimilarity);

  // Combine the arrays in the desired order
  const combinedRaters = [
    ...ratersWithRelationship,
    ...externalRaters,
    ...ratersWithoutRelationship,
  ];

  return (
    <div className={styles.container}>
      {/* <h1 className={styles.page_title}>Rapid Rate</h1> */}
      {showTutorial && <Tutorial onClose={() => setShowTutorial(false)} />}
      {flashMessage && <p className={styles.flashMessage}>{flashMessage}</p>}
      {ratingCount <= targetRating && (
        <div className={styles.progressBar}>
          <motion.div
            className={styles.progress}
            style={{ width: `${progress}%` }}
            transition={{ duration: 0.5, ease: "linear" }}
          />
          <div className={styles.labelContainer}>
            <span className={styles.label}>
              New User Rating Calibration: {ratingCount}/{targetRating}
            </span>
          </div>
        </div>
      )}
      {product && product.name && product.icon_url ? (
        <div>
          <img
            src={product.image_url ? product.image_url : unknownImg} // unknownImg should be a path to a local placeholder image
            className={styles.productPhoto}
            alt="Product"
          />

          <FontAwesomeIcon
            icon={faSquareArrowUpRight}
            className={styles.externalIcon}
            onClick={() => navigate(`/products/${product.product_id}`)}
            style={{
              top: ratingCount <= targetRating ? 35 : 10,
            }}
          />
          <div className={styles.productHeader}>
            <div
              className={styles.headerLink}
              onClick={() => navigate(`/products/${product.product_id}`)}
            >
              <div className={styles.productName}>
                {`${product.name}${
                  product.secondary_info ? ` (${product.secondary_info})` : ""
                }`}
              </div>
              {product.creator && (
                <div className={styles.creatorText}>By {product.creator}</div>
              )}
              {product.distance && (
                <div className={styles.distanceText}>
                  ${Math.round(product.distance)} $
                  {Math.round(product.distance) === 1 ? "Mile" : "Miles"} Away`
                </div>
              )}
            </div>
            <img
              src={product.icon_url}
              className={styles.categoryIcon}
              alt="Product Icon"
            />
            <div className={styles.categoryDropdown}>
              <Dropdown
                categories={categories}
                selectedCategories={selectedCategories}
                onCategoryChange={setSelectedCategories}
                icon={true}
                name="Categories"
              />
            </div>
          </div>

          {product.category_id === 5 && clipUrl && (
            <div onClick={playPauseSound} className={styles.playContainer}>
              <FontAwesomeIcon
                className={styles.playIcon}
                icon={isPlaying ? faPause : faPlay}
                size={64}
                color={"white"}
              />
              <p className={styles.playText}>
                {isPlaying ? "Pause" : "Preview"}
              </p>
            </div>
          )}

          <div className={styles.modalContent}>
            <FontAwesomeIcon
              icon={faArrowLeft}
              className={styles.arrowLeft}
              onClick={handleGoBack}
            />
            <FontAwesomeIcon
              icon={faArrowRight}
              className={styles.arrowRight}
              onClick={() => {
                handleSelectRating(selectedRating || -2, review);
                if (selectedRating) {
                  setFlashMessageWithTimer("Rating Sumbitted");
                } else {
                  setFlashMessageWithTimer("Rating Skipped");
                }
              }}
            />

            <h1 className={styles.selectedRating}>
              {hoverRating ||
                (selectedRating === -1
                  ? "!"
                  : selectedRating === -2
                  ? "X"
                  : selectedRating === -3
                  ? "-"
                  : selectedRating) ||
                "?"}
            </h1>
            <p className={styles.ratingDescription}>
              {getRatingDescription(hoverRating || selectedRating)}
            </p>

            <div className={styles.starsContainer} ref={starContainerRef}>
              {renderStars()}
            </div>

            <div className={styles.ratingOptions}>
              <div className={styles.wishlistContainer}>
                <FontAwesomeIcon
                  icon={faBan}
                  className={styles.skip}
                  onClick={() => {
                    handleSelectRating(-2);
                    setFlashMessageWithTimer("Skipped");
                  }}
                />
                <p className={styles.wishlistText}>Skip</p>
              </div>
              <button
                className={styles.submitButton}
                onClick={() => {
                  handleSelectRating(selectedRating, review);
                  if (selectedRating) {
                    setFlashMessageWithTimer("Rating Sumbitted");
                  } else {
                    setFlashMessageWithTimer("Rating Skipped");
                  }
                }}
              >
                <h3 className={styles.submitText}>Submit Rating</h3>
              </button>
              <div className={styles.wishlistContainer}>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className={styles.wishlist}
                  onClick={() => {
                    handleSelectRating(-1);
                    setFlashMessageWithTimer("Added to Wishlist");
                  }}
                />
                <p className={styles.wishlistText}>Wishlist</p>
              </div>
            </div>

            {selectedRating > 0 ? (
              <div className={styles.reviewContainer}>
                <textarea
                  className={styles.review}
                  placeholder="After giving a rating write a review!"
                  value={review}
                  onChange={(e) => setReview(e.target.value)}
                />
              </div>
            ) : (
              <div className={styles.productMiddle}>
                {/* <Link to={`/products/${product.product_id}`}>
                <div className={styles.productLeft}>
                  <div className={styles.imageText}>
                    Click for full product info
                  </div>
                </div>
              </Link> */}
                <div className={styles.tabsContainer}>
                  {["Ratings", "Description", "Tags"].map((tab) => (
                    <button
                      key={tab}
                      className={`${styles.tab} ${
                        activeTab === tab ? styles.activeTab : ""
                      }`}
                      onClick={() => setActiveTab(tab)}
                      style={{ flex: 1 }}
                    >
                      <span
                        className={
                          activeTab === tab
                            ? styles.activeTabText
                            : styles.tabText
                        }
                      >
                        {tab}
                      </span>
                      {activeTab === tab && (
                        <div className={styles.activeTabUnderline} />
                      )}
                    </button>
                  ))}
                </div>

                {activeTab === "Ratings" && (
                  <div className={styles.ratersGallery} ref={scrollViewRef}>
                    <div className={styles.productRatings}>
                      <div className={styles.predicted}>
                        <div className={styles.predictedRating}>
                          {predictedRating ? predictedRating.toFixed(1) : "?"}
                        </div>
                        <div className={styles.predictedText}>Predicted</div>
                      </div>
                      <div className={styles.predicted}>
                        <div className={styles.predictedRating}>
                          {averageRating ? averageRating.toFixed(1) : "?"}
                        </div>
                        <div className={styles.predictedText}>Average</div>
                      </div>
                    </div>
                    {combinedRaters.map((rater, index) => (
                      <div className={styles.raterInfo} key={index}>
                        {rater.similarity ? (
                          <div className={styles.ratersSimilarity}>
                            {rater.similarity}%
                          </div>
                        ) : (
                          <div className={styles.ratersSimilarity2}>.
                          </div>
                        )}
                        <Link
                          to={
                            rater.user_id < 500
                              ? `/ExternalProfile/${rater.user_id}`
                              : `/profiles/${rater.user_id}`
                          }
                        >
                          <img
                            src={rater.photo_url || rater.image_url}
                            className={styles.raterPhoto}
                            alt={rater.username}

                          />
                        </Link>
                        <div className={styles.ratersRating}>
                          {rater.rating}
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {activeTab === "Description" && (
                  <div className={styles.productInfoText}>
                    {product.description}
                  </div>
                )}

                {activeTab === "Tags" && (
                  <div
                    className={styles.keywordsContainer}
                    style={{ overflowY: "scroll" }}
                  >
                    <div className={styles.keywordsWrapContainer}>
                      {product.keywords.length > 0
                        ? product.keywords.map((item, index) => (
                            <div key={index} className={styles.keywordItem}>
                              <span className={styles.keywordText}>
                                #{item.keyword}
                              </span>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          {empty && (
            <p className={styles.emptyText}>
              You have seen all products in your selected categories. Try out
              some new ones to explore more content!
            </p>
          )}
          <div className={styles.categoryDropdown}>
            <Dropdown
              categories={categories}
              selectedCategories={selectedCategories}
              onCategoryChange={setSelectedCategories}
              icon={true}
              name="Categories"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default RapidRate;
