import React, { useState, useContext, useEffect, useRef  } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import styles from "./ImportRatings.module.css";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../components/UserContext";
import CategoryDropdown from "../components/CategoryDropdown";

function ImportRatings() {
  const [unaddedNames, setUnaddedNames] = useState([]);
  const apiUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const { userId } = useContext(UserContext);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const fileInputRef = useRef(null);

  const handleBackClick = () => {
    navigate(-1); // This takes the user back to the previous page
  };

  const handleButtonClick = () => {
    if (selectedCategory == null) {
        window.alert("Please select a category first.");
        return;
      }
    fileInputRef.current.click(); // This will open the file input dialog
  };

  useEffect(() => {
    async function fetchTags() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/get_all_categories`
        );
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    }

    fetchTags();
  }, []); // Empty dependency array, only run once on mount.

  const handleFileChange = (event) => {
    handleFileSubmit(event.target.files[0]);
  };

  const handleFileSubmit = async (file) => {
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("user_id", userId);
    formData.append("category_id", selectedCategory);

    try {
      const response = await fetch(`${apiUrl}/import_data`, {
        method: "POST",
        body: formData,
      });

      const result = await response.json();
      if (result.na_products) {
        setUnaddedNames(result.na_products);
      }
      window.alert(
        `Successfully imported ${
          result.number_ratings
        } ratings. The following products were not found in our database: ${result.na_products.join(
          ", "
        )}`
      );
    } catch (error) {
      window.alert(
        "Error uploading file. Please check it meets the guidelines below."
      );
      console.error("Error uploading file:", error);
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.page_title}>Import Ratings</h1>
      <div className={styles.backIconContainer} onClick={handleBackClick}>
        <FontAwesomeIcon icon={faArrowLeft} className={styles.backArrow} />
      </div>

      <p className={styles.introText}>
        Choose a category then upload a CSV to quickly import ratings. See below
        for requirements.
      </p>
      <div className={styles.dropdownContainer}>
      <CategoryDropdown
        categories={categories}
        selectedCategory={selectedCategory}
        onCategoryChange={setSelectedCategory}
        icon={true}
        name="Category"
      />

      <button className={styles.customFileButton} onClick={handleButtonClick}>
        Upload CSV
      </button>

      <input
        type="file"
        accept=".csv"
        onChange={handleFileChange}
        ref={fileInputRef}
        className={styles.fileInput}
      />
    </div>

      {unaddedNames.length > 0 && (
        <div className={styles.errorList}>
          <h2>Failed to Add These Names:</h2>
          <ul>
            {unaddedNames.map((name, index) => (
              <li key={index}>{name}</li>
            ))}
          </ul>
        </div>
      )}

      <div className={styles.csvStructure}>
        <p>
          Please ensure your CSV file meets the following criteria, an example
          is below. Letterboxd imports will work without any modification.
          <br /> <br />
          Name is Mandatory and must be the same official title from as from our
          approved sites.
          <br /> <br />
          Either 'Year' or 'City' must be supplied. This will ensure the correct
          product is added.
          <br /> <br />
          A single category must be selected via the above dropdown. Products
          can only be imported one category at a time.
          <br /> <br />
          A rating must be supplied for each entry. Ratings should be between 1 and 10. But
          checks are done to identify a scale of 1-5 and if detected it will be
          converted. Any set of ratings with a maximum value of 5 AND decimals will be identified as a 1-5 scale.
          <br /> <br />
          The review column is optional. If you have a review you can include it
          here.
          <br /> <br />
          Date is optional. If left blank or the column is not supplied, the
          upload time will be used. Bear in mind that past dates will not be
          prioritised in the home feed.
          <br /> <br />
          If there are any issues please contact us and we will provide support
          with 24 hours.
        </p>

        <img
          src="https://s3.eu-west-2.amazonaws.com/rated.images/csv_example.PNG"
          alt="CSV structure example"
          className={styles.csvImage}
        />
      </div>
    </div>
  );
}

export default ImportRatings;
