// src/components/BlogPost.js
import React from 'react';
import { useParams } from 'react-router-dom';
import blogPosts from '../data/blogData';
import styles from "./BlogPost.module.css";

const BlogPost = () => {
  const { id } = useParams();
  const post = blogPosts.find((post) => post.id === parseInt(id));

  if (!post) {
    return <div>Blog post not found</div>;
  }

  return (
    <div className={styles.container}>
    <div className={styles.blogPost}>
      <h1>{post.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: post.content }} />
    </div>
    </div>
  );
};

export default BlogPost;