import React, { useState, useEffect, useCallback, useContext } from "react";
import styles from "./Search.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmarkCircle,
  faSearch,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import GiveRating from "./GiveRating";
import { UserContext } from "../components/UserContext";
import CategoryDropdownSearch from "./CategoryDropdownSearch";

Search.defaultProps = {
  onChange: () => {},
  onProductClick: () => {},
};

export default function Search({
  onChange = () => {},
  onProductClick = () => {},
}) {
  const [query, setQuery] = useState("");
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const disallowedQueries = ["The", "the", "The ", "the "];
  const { userId } = useContext(UserContext);
  const UNKOWN_IMAGE_URL = process.env.UNKOWN_IMAGE_URL; // Note the correction of the spelling in the variable name

  useEffect(() => {
    const disallowedQueries = ["The", "the", "The ", "the "];
    if (query.length < 3 || disallowedQueries.includes(query)) {
      setProducts([]);
      return;
    }

    const timeoutId = setTimeout(() => {
      // Place your fetch logic here
      fetchProducts();
    }, 300); // 500 ms debounce time

    return () => clearTimeout(timeoutId);
  }, [query, selectedCategoryId]); // This effect runs every time `query` changes

  const fetchProducts = async () => {
    // Check for disallowed or short queries
    const disallowedQueries = ["The", "the", "The ", "the "];
    if (query.length < 3 || disallowedQueries.includes(query)) {
      setProducts([]);
      return;
    }

    // Construct the URL based on whether a category is selected
    const url = `${
      process.env.REACT_APP_BACKEND_URL
    }/get_all_products?q=${encodeURIComponent(
      query
    )}&category_id=${selectedCategoryId}&userId=${userId}`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setProducts(responseData.products);
    } catch (error) {
      console.error("Error fetching products:", error);
      // Optionally, handle the error state in the UI as well
    }
  };

  const handleAddNewProduct = () => {
    // Assuming you want to redirect using the browser's history API
    window.location.href = "/SubmitProduct"; // replace with the actual path
  };

  // Fetch all categories once when the component mounts
  useEffect(() => {
    async function fetchCategories() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/get_all_categories`
        );
        const responseData = await response.json();
        setCategories(responseData);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    }

    fetchCategories();
  }, []);

  const handleRating = (productId, rating) => {
    const updatedProducts = products.map((product) => {
      if (product.product_id === productId) {
        return {
          ...product,
          rating: rating, // Update the user_rating.
        };
      }
      return product; // Leave all other products unchanged.
    });

    // Update the state with the modified products array.
    setProducts(updatedProducts);
  };

  const handleItemClick = (product) => {
    setIsDropdownVisible(false);
    setQuery(""); // This resets the search bar contents

    if (typeof onChange === "function") {
      onChange({ target: { value: product.name } });
    }

    if (onProductClick) {
      onProductClick(product);
    }
  };

  const handleCategoryChange = (categoryId) => {
    setSelectedCategoryId(categoryId);
  };

  return (
    <div className={styles.container}>
      {products.length > 0 && (
        <div
          onClick={() => {
            setQuery("");
            setProducts([]);
          }}
          className={styles.dropdownOpen}
        ></div>
      )}
      <div className={styles.inputContainer}>
        <div className={styles.searchContainer}>
          {(query && query !== "") || products.length > 0 ? (
            <FontAwesomeIcon
              icon={faXmarkCircle}
              className={styles.searchIcon}
              onClick={() => {
                setQuery("");
                setProducts([]);
              }}
            />
          ) : (
            <FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />
          )}
          <input
            className={styles.searchInput}
            type="text"
            placeholder="Search Rated"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
              setIsDropdownVisible(true);
            }}
            onFocus={() => setIsDropdownVisible(true)}
            onBlur={() => {
              setTimeout(() => setIsDropdownVisible(false), 150);
            }}
          />
        </div>

        <CategoryDropdownSearch
          categories={categories}
          selectedCategory={selectedCategoryId}
          onCategoryChange={handleCategoryChange}
          icon={true}
          name={"Filter"}
        />
      </div>
      {products.length > 0 && (
        <div className={styles.searchDropdown}>
          {products.map((product) => (
            <div key={product.product_id} className={styles.searchItem}>
              <div
                className={styles.itemLeft}
                onClick={() => handleItemClick(product)}
              >
                {product.image_url &&
                product.image_url !== "null" &&
                product.image_url !== "" ? (
                  <img
                    src={product.image_url}
                    alt={product.name}
                    className={styles.searchImage}
                  />
                ) : (
                  <img
                    src={
                      "https://s3.eu-west-2.amazonaws.com/rated.images/unknown.png"
                    }
                    alt={product.name}
                    className={styles.searchImage}
                  />
                )}
              </div>
              <div
                className={styles.itemMiddle}
                onClick={() => handleItemClick(product)}
              >
                <div className={styles.title}>{product.name}</div>
                <div className={styles.iconCreator}>
                  <img
                    src={product.category_icon_url}
                    alt={product.category_name}
                    className={styles.itemIcon}
                  />
                  {product.creator &&
                  product.creator !== "0" &&
                  product.creator !== "NaN" ? (
                    <div className={styles.creator}>
                      By {product.creator}{" "}
                      <span className={styles.secondaryInfo}>
                        · {product.secondary_info}
                      </span>
                    </div>
                  ) : (
                    <div className={styles.creator}>
                      {product.secondary_info}
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.itemRight}>
                <GiveRating
                  productId={product.product_id}
                  productTitle={product.name}
                  userRating={product.rating}
                  userId={userId}
                  handleRating={handleRating}
                  categoryId={product.category_id}
                  screen={"Search"}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
